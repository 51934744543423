import useLcaGridHelper from 'components/v2/compiler/hooks/useLcaGridHelper';
import { useEpdLcaCustomResultGridData } from 'services/api/queries';

import { LcaGridNote } from '../../GridService';
import {
  LcaResultsGridColumns,
  LcaResultsGridColumnsPdf,
  createVariationColumnWithDisclaimer,
  createVariationDisclaimerNote,
} from '../LcaResultsGridColumns';
import LcaResultsGridPdf from '../LcaResultsGridPdf';
import LcaCustomFixedResultsGrid from './LcaCustomFixedResultsGrid';

type TProps = {
  epdVersionId: string;
  customResultId: string;
  isForPdf?: boolean;
  title?: React.ReactNode;
  disabled?: boolean;
  preferEnabledAppearanceEvenWhenDisabled?: boolean;
};

const LcaCustomBiogenicResultsGrid = ({
  epdVersionId,
  customResultId,
  isForPdf,
  title,
  disabled,
  preferEnabledAppearanceEvenWhenDisabled,
}: TProps) => {
  const rows = useEpdLcaCustomResultGridData(customResultId).data;
  const { generalDisclaimerNote, showVariationColumn } = useLcaGridHelper(epdVersionId);

  const notes: LcaGridNote[] = [
    {
      note: 'Acronyms' as React.ReactNode,
      description: 'GWP-biogenic = Global Warming Potential biogenic.' as React.ReactNode,
    },
  ];

  if (generalDisclaimerNote) {
    notes.push(generalDisclaimerNote);
  }

  let columns = isForPdf ? LcaResultsGridColumnsPdf : LcaResultsGridColumns;
  if (showVariationColumn && (!isForPdf || (isForPdf && rows?.some((r) => r.variation)))) {
    const disclaimerNumber = 1;
    columns = [...columns, createVariationColumnWithDisclaimer(disclaimerNumber)];
    notes.push(createVariationDisclaimerNote(disclaimerNumber));
  }

  if (isForPdf) {
    return <LcaResultsGridPdf rows={rows} columns={columns} notes={notes} />;
  }

  return (
    <LcaCustomFixedResultsGrid
      epdVersionId={epdVersionId}
      customResultId={customResultId}
      title={title}
      rows={rows}
      columns={columns}
      notes={notes}
      disabled={disabled}
      preferEnabledAppearanceEvenWhenDisabled={preferEnabledAppearanceEvenWhenDisabled}
    />
  );
};

export default LcaCustomBiogenicResultsGrid;
