import MandatoryIcon from 'components/icons/MandatoryIcon';
import { MAX_IMG_SIZE, ProcessStatus, REMOVE_FILE, Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import Tick from 'images/tick.svg';
import { WizardTabModel } from 'models/tabModels';
import WizardStep1 from 'pages/wizard/WizardStep1';
import WizardStep2 from 'pages/wizard/WizardStep2';
import WizardStep3 from 'pages/wizard/WizardStep3';
import WizardStep4 from 'pages/wizard/WizardStep4';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EpdLinks } from 'routes/EpdRoutes';
import {
  ActionState,
  EPDModel,
  EPDStateModel,
  EPDVerifierModel,
  MembershipModel,
  PCRModel,
  PreverifiedToolModel,
  PreverifiedToolVersionModel,
} from 'services/EpdClient';
import EpdService from 'services/EpdService';
import EpdStateService from 'services/EpdStateService';
import MembershipService from 'services/MembershipService';
import PreverifiedToolService from 'services/PreverifiedToolService';
import styled, { css } from 'styled-components';
import { Container, ErrorText, TextSmall } from 'styles/Styles.styled';
import Spinner from 'styles/spinner.styled';

import StatusBox from './StatusBox';
import ToolButtons from './ToolButtons';
import { step1ValidationSchema, step2ValidationSchema, step3ValidationSchema, step4ValidationSchema } from './Validation';
import { StepStatus } from './types';

const EpdWizard: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [epd, setEpd] = useState<EPDModel>();
  const [epdVersions, setEpdVersions] = useState<EPDModel[]>([]);
  const [updatingEpd, setUpdatingEpd] = useState<ProcessStatus>(ProcessStatus.None);
  const { id } = useParams<any>();
  const { companyId, changeCompany } = React.useContext(CompanyContext);
  const [agreementConsent, setAgreementConsent] = useState<boolean>(false);
  const history = useHistory();
  const [verifiers, setVerifiers] = useState<EPDVerifierModel[]>([]);
  const [preVerifiedTools, setPreverifiedTools] = useState<PreverifiedToolModel[]>([]);
  const [preverifiedToolVersions, setPreverifiedToolVersions] = useState<PreverifiedToolVersionModel[]>([]);
  const [epdDevelopers, setEpdDevelopers] = useState<MembershipModel[]>([]);

  const goToStep = (stepNumber: number) => setActiveStep(stepNumber);

  const onChangeServiceAgreementsStatus = (val: boolean) => {
    onChangeEpd('ServiceAgreementStatus', val);
    setAgreementConsent(val);
  };

  const onChangeEpd = async (propertyName: string, val: any, onlyFetch: boolean = false) => {
    if (!epd || !epd.id) {
      return;
    }
    if (onlyFetch) {
      await fetchEpd();
      return;
    }
    if (val instanceof File) {
      await addEpdFile(epd.id, propertyName, val);
    } else {
      await addEpdProperty(epd.id, propertyName, val, epd.versionId ?? '');
    }
  };

  const addEpdProperty = async (epdId: string, propertyName: string, val: any, versionId: string) => {
    try {
      setUpdatingEpd(ProcessStatus.Fetching);
      const result =
        propertyName === REMOVE_FILE
          ? await EpdService.removeEpdFile(val, versionId)
          : await EpdService.updateProperty(epdId, propertyName, val === undefined ? null : val);
      setEpd(result);
      setUpdatingEpd(ProcessStatus.Success);
    } catch {
      setUpdatingEpd(ProcessStatus.Error);
    }
  };

  const addEpdFile = async (epdId: string, propertyName: string, file: File) => {
    if (propertyName === 'ProductImage' && file.size > MAX_IMG_SIZE) {
      toast.error(
        t('epdWizard.messages.errorTooLargeImageFile', { size: Math.floor(MAX_IMG_SIZE / (1024 * 1024)) }) as string,
        {
          position: 'top-center',
        }
      );
      return;
    }

    const fileName = file.name;
    const fileType = file.type;
    const reader = new FileReader();
    let resolveUploading: (value?: undefined) => void;
    let rejectUploading: (reason?: any) => void;
    const uploadingPromise = new Promise((resolve, reject) => {
      resolveUploading = resolve;
      rejectUploading = reject;
    });
    reader.onload = async () => {
      const fileBlob = reader.result as ArrayBuffer;
      if (!fileBlob) {
        rejectUploading(new Error('No file blob after reading file.'));
        return;
      }

      const blob = new Blob([new Uint8Array(fileBlob)], { type: fileType });
      if (blob.size > 10000000) {
        toast.error(t('epdWizard.messages.errorTooLargeFile') as string, {
          position: 'top-center',
        });
        rejectUploading(new Error('File is too large.'));
        return;
      }
      try {
        setUpdatingEpd(ProcessStatus.Fetching);
        const result = await EpdService.addEpdFile(epdId, propertyName, fileName, blob);
        setEpd(result);
        setUpdatingEpd(ProcessStatus.Success);
      } catch (e) {
        setUpdatingEpd(ProcessStatus.Error);
        rejectUploading(e);
        return;
      }

      resolveUploading();
    };
    reader.readAsArrayBuffer(file);

    await uploadingPromise;
  };

  const fetchEpd = async () => {
    if (!id) {
      return;
    }

    try {
      setStatus(ProcessStatus.Fetching);
      const resultEpd = await EpdService.getEpd(id);
      const resultEpdVersion = await EpdStateService.getVersions(resultEpd?.id as any);
      setEpd(resultEpd);
      setAgreementConsent(resultEpd?.serviceAgreementStatus ?? false);
      setEpdVersions((resultEpdVersion as EPDModel[]) ?? []);
      setStatus(ProcessStatus.Success);
    } catch {
      setStatus(ProcessStatus.Error);
    }
  };

  const fetchVerifierUsers = async () => {
    if (!!companyId) {
      const res = await MembershipService.getVerifiers(companyId);
      setVerifiers(res);
    }
  };

  useEffect(() => {
    const fetchPreverifiedTools = async () => {
      const res = await PreverifiedToolService.getPreVerifiedTool();
      setPreverifiedTools(res);
    };

    const fetchPreverifiedToolVersions = async () => {
      const res = await PreverifiedToolService.getPreVerifiedToolVersions();
      setPreverifiedToolVersions(res);
    };

    fetchPreverifiedTools();
    fetchPreverifiedToolVersions();
  }, []);

  useEffect(() => {
    const fetchEpdUsers = async () => {
      const devels = await MembershipService.getMembershipsByCompanyAndRole(companyId!, Role.EPDDeveloper);
      const owners = await MembershipService.getMembershipsByCompanyAndRole(companyId!, Role.EPDOwner);
      setEpdDevelopers([...devels, ...owners]);
    };

    if (!!companyId) {
      fetchVerifierUsers();
      fetchEpdUsers();
    }
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!companyId) {
      fetchEpd();
    }
  }, [id, companyId, changeCompany]); // eslint-disable-line react-hooks/exhaustive-deps

  if (status === ProcessStatus.Fetching) {
    return (
      <Container>
        <div>{t('epdWizard.messages.loading')}</div>
      </Container>
    );
  }

  if (status === ProcessStatus.Error) {
    return (
      <Container>
        <div>{t('epdWizard.messages.errorOnLoading')}</div>
      </Container>
    );
  }

  if (!epd) {
    return null;
  }

  const updateEpd = (state: {} | null) => {
    if (!state) {
      history.push(EpdLinks.dashboard());
      return;
    }
    fetchEpd();
  };

  const changePcr = (pcr: PCRModel) => {
    setEpd({ ...epd, pcr: pcr });

    if (pcr?.id) {
      onChangeEpd('PCR', pcr?.id);
    }
  };

  const removePcr = () => {
    setEpd({ ...epd, pcr: undefined });
    onChangeEpd('PCR', null);
  };

  const wizardTabs: WizardTabModel<EPDModel>[] = [
    {
      name: (
        <>
          {t('epdWizard.wizardStep1.name')}
          <TextSmall>{t('epdWizard.wizardStep1.description')}</TextSmall>
        </>
      ),
      component: (
        <WizardStep1
          key={'step1'}
          validation={step1ValidationSchema(verifiers)}
          epdDevelopers={epdDevelopers}
          preVerifiedTools={preVerifiedTools}
          preVerifiedToolVersions={preverifiedToolVersions}
          verifiers={verifiers}
          refetchVerifiers={fetchVerifierUsers}
        />
      ),
      isValid: (e) => step1ValidationSchema(verifiers).isValidSync(e),
    },
    {
      name: (
        <>
          {t('epdWizard.wizardStep2.name')}
          <TextSmall>{t('epdWizard.wizardStep2.description')}</TextSmall>
        </>
      ),
      component: (
        <WizardStep2 key={'wiz2'} validation={step2ValidationSchema} isFetching={updatingEpd === ProcessStatus.Fetching} />
      ),
      isValid: (e) => step2ValidationSchema.isValidSync(e),
    },
    {
      name: (
        <>
          {t('epdWizard.wizardStep3.name')}
          <TextSmall>{t('epdWizard.wizardStep3.description')}</TextSmall>
        </>
      ),
      component: <WizardStep3 key={'wiz3'} validation={step3ValidationSchema} />,
      isValid: (e) => step3ValidationSchema.isValidSync(e),
    },
    {
      name: (
        <>
          {t('epdWizard.wizardStep4.name')}
          <TextSmall>{t('epdWizard.wizardStep4.description')}</TextSmall>
        </>
      ),
      component: (
        <WizardStep4
          key="wiz4"
          registerConsent={onChangeServiceAgreementsStatus}
          validation={step4ValidationSchema(epdVersions)}
        />
      ),
      isValid: (e) =>
        step4ValidationSchema(epdVersions).isValidSync(e) &&
        (agreementConsent ||
          (epd.epdState?.sendForPublication == ActionState.Hidden && epd.epdState?.sendForPublicationEditorial == ActionState.Hidden)),
    },
  ];

  if (!companyId) {
    return <Container>Receiving company identifier... or it is not set and you need to relogin</Container>;
  }

  return (
    <Container>
      {updatingEpd === ProcessStatus.Fetching && <Spinner />}
      {updatingEpd === ProcessStatus.Error && <ErrorUpdating>{t('epdWizard.messages.errorOnSaving')}</ErrorUpdating>}

      <ToolButtons
        epd={epd}
        companyId={companyId || ''}
        updateEpd={updateEpd}
        epdVersions={epdVersions}
        agreementConsent={agreementConsent}
      />

      <StatusBox epdState={epd.epdState} />

      <Wizard>
        <WizardSteps>
          {wizardTabs.map((tab, index) => (
            <WizardStep
              key={index}
              status={index === activeStep ? StepStatus.Active : StepStatus.Todo}
              onClick={() => setActiveStep(index)}
            >
              <div>{tab.name}</div>
              {tab.isValid(epd) && (
                <div>
                  <img src={Tick} width="16" height="16" alt="Step completed" />
                </div>
              )}
              {tab.isValid(epd) === false && <MandatoryIcon />}
            </WizardStep>
          ))}
        </WizardSteps>
        <WizardContent>
          {wizardTabs.map(
            (tab, index) =>
              index === activeStep &&
              React.cloneElement(tab.component, {
                epd: epd,
                onChangePcr: changePcr,
                onRemovePcr: removePcr,
                onChangeEpd: onChangeEpd,
                setUpdatedEpd: setEpd,
                goToStep: goToStep,
              })
          )}
        </WizardContent>
      </Wizard>
    </Container>
  );
};

export const AccordionContent = styled.div`
  border-left: solid 3px ${(props) => props.theme.colors.regionColorLight};
  padding: 1rem;
  display: flex;
`;

export const SpanLink = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Wizard = styled.section`
  display: flex;
  width: 100%;
`;

const WizardSteps = styled.div`
  flex: 0 0 calc(100% / 4);
`;

const WizardStep = styled.div<{ status?: StepStatus }>`
  ${(props) => props.theme.fonts.heading3}
  display: flex;
  padding: 1rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray};
  border-right: solid 3px ${(props) => props.theme.colors.gray};
  justify-content: space-between;

  ${(props) =>
    props.status === StepStatus.Active
      ? css`
          border-right-color: ${(props) => props.theme.colors.orange};
          background-color: ${(props) => props.theme.colors.lightGray};
        `
      : null};

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.lightGray};
  }
`;

const WizardContent = styled.div`
  flex: 0 0 calc(100% / 4 * 3);
  padding: 1rem 0 0 2rem;
  box-sizing: border-box;
`;

const ErrorUpdating = styled(ErrorText)`
  position: fixed;
  right: 0;
  margin: 1rem;
  padding: 1rem;
  border: solid 1px red;
  background: white;
  z-index: 1;
`;

export const Text = styled.div`
  margin-bottom: 1rem;
  margin-top: 2rem;
  text-align: left;
  ${(props) => props.theme.fonts.text}
  font-size: 0.96rem;
`;

export const ListBox = styled.div`
  border: solid 1px gray;
  margin-bottom: 0.5rem;
  height: 100px;
  overflow-y: scroll;
`;

export const ListBoxNoItems = styled.em`
  padding: 0.25rem 0.5rem;
  display: block;
`;

export default EpdWizard;
