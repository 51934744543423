import { useEffect } from 'react';

declare global {
  interface Window {
    fwSettings?: { widget_id: number };
    FreshworksWidget?: ((...args: any[]) => void) & { q?: any[] };
  }
}

const FreshworksWidget = () => {
  useEffect(() => {
    // Initialize Freshworks widget settings
    if (!window.FreshworksWidget) {
      window.fwSettings = { widget_id: 103000007430 };

      (function () {
        if (typeof window.FreshworksWidget !== 'function') {
          const n = function (...args: any[]) {
            if (!n.q) n.q = [];
            n.q.push(args);
          } as ((...args: any[]) => void) & { q?: any[] };
          window.FreshworksWidget = n as unknown as typeof window.FreshworksWidget;
        }
      })();

      // Create and append script element
      const script = document.createElement('script');
      script.src = 'https://euc-widget.freshworks.com/widgets/103000007430.js';
      script.async = true;
      script.defer = true;
      script.id = 'freshworks-widget-script';
      document.body.appendChild(script);
    } else {
      window.FreshworksWidget('show');
    }

    return () => {
      if (window.FreshworksWidget) {
        window.FreshworksWidget('hide');
      }
    };
  }, []);

  return null;
};

export default FreshworksWidget;
