import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { ErrorMessage, Label } from 'styles/Styles.styled';

const TextAreaInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      {label && <Label htmlFor={props.id || props.name}>{label}</Label>}
      <FixedTextArea {...field} {...props} />
      {meta.touched && meta.error && !props.supressErrors ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
    </>
  );
};

const FixedTextArea = styled.textarea`
  padding: 1rem;
  border: solid 1px gray;
  margin: 0.5rem 0 1rem 0;
  min-width: 100%;
`;

export default TextAreaInput;
