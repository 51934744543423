import TextInput from 'components/form/TextInput';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import { LoginModel } from 'services/EpdClient';
import { ButtonSmall, ErrorText, FormMode, StyledForm } from 'styles/Styles.styled';
import * as Yup from 'yup';

const Impersonate: React.FunctionComponent = () => {
  const { companyId, changeCompany, setDefaultOrFirstCompany } = useContext(CompanyContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [serverError, setServerError] = React.useState<string>('');
  const impoersonateSchema = Yup.object({
    userName: Yup.string().required('Fill in email'),
  });

  const initialValues: LoginModel = {
    userName: '',
    password: '',
  };

  const impersonateUser = async (values: LoginModel) => {
    try {
      let result = await AuthService.loginAs(values);
      if (result?.memberships && result?.memberships.length > 0) {
        if (!result?.memberships.some((x) => x.companyId == companyId && x.companyEnabled)) {
          changeCompany?.(result?.latestLoginCompanyId ?? result?.memberships[0].companyId);
          history.push(EpdLinks.dashboard());
        } else {
          history.go(0);
        }
      } else {
        setDefaultOrFirstCompany?.();
        history.go(0);
      }
    } catch {
      setServerError(t('footer.impersonate.errorMessage'));
    }
  };

  if (!AuthService.isAdmin()) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={impoersonateSchema}
      onSubmit={(values) => impersonateUser(values)}
    >
      {({ isSubmitting }) => (
        <StyledForm mode={FormMode.Inline} style={{ gap: '1rem' }}>
          <TextInput style={{ margin: 0 }} label="" name="userName" placeholder={t('footer.impersonate.placeHolder')} />
          <ButtonSmall disabled={isSubmitting}>{t('footer.impersonate.title')}</ButtonSmall>
          {serverError && <ErrorText>{serverError}</ErrorText>}
        </StyledForm>
      )}
    </Formik>
  );
};

export default Impersonate;
