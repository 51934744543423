import { toaster } from 'components/v2/toast';
import { MAX_IMG_SIZE, REMOVE_FILE } from 'constants/constants';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import {
  ConnectVerifierModel,
  FileModel,
  FileParameter,
  PCRModel,
  ProductClassificationModel,
  ProductMaterialProperyModel,
  SendForPublicationEditorialModel,
} from 'services/EpdClient';
import EpdService from 'services/EpdService';
import EpdStateService from 'services/EpdStateService';
import {
  AddEPDFileModel,
  AddEPDPropertyModel,
  CO2UptakeAssociationModel,
  CO2UptakeAssociationModelWithParams,
  ConnectDeveloperModel,
  ConversionFactorModel,
  CpcrPerformanceInformationModel,
  CpcrPerformanceTableDataModel,
  CreateEPDProductionSiteAddressModel,
  DataQualityAssessmentItemModel,
  DataQualityAssessmentReadModel,
  DataQualityAssessmentSummaryModel,
  DeclarationOfCO2AssumptionModelWithParams,
  DeclarationOfCO2Model,
  DictionaryCatalogItem,
  DictionaryValue,
  EPDAdditionalInformationModel,
  EPDContentDeclarationProductType,
  EPDCoverPageModel,
  EPDDataSourceModel,
  EPDDeclareContentProductModel,
  EPDDefinitionModel,
  EPDOrganizationInformationAddressModel,
  EPDOrganizationInformationModel,
  EPDProductInformationModel,
  ElectricityDataModel,
  FoundationAddressModel,
  IVersionedEntity,
  InfrastructureAndCapitalGoodsModel,
  LCASpecificationModel,
  LcaResultsViewModel,
  LcaSupportModel,
  PCRInformationModel,
  ProductComponent,
  ProductDangerMaterial,
  ProductPackagingMaterial,
  ProductPanelContent,
  ReferenceFlowModel,
  RequestCollaborationModel,
  ScenarioItemModel,
  ScenarioModuleDefaultDescriptionModel,
  ScenarioModuleItemModel,
  ScenarioModuleReadModel,
  ScrapInputModel,
  ShareOfTotalScrapInputModel,
  SystemBoundaryGridModel,
  SystemBoundaryModel,
  ThirdPartyVerificationModel,
  TransportationPerformanceModel,
  UpdateDeclaredModulesGeographyModel,
  UpdateDeclaredModulesModel,
  UpdateEPDContentDeclarationProductModel,
  UpdateEPDProductInformationPropertyModel,
  VerificationViewModel,
} from 'types/types';

import { MutationFunction, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

import { QUERY_KEYS } from './constants';
import {
  addEPDFile,
  addEPDProductInformationFile,
  approveVerification,
  cancelApproveVerification,
  cancelEpdDeregistration,
  cancelUpdateEpd,
  cancelVerification,
  connectCertificateIssuedByBody,
  connectDeveloper,
  connectVerifier,
  copyEpdScenario,
  copyProductInformation,
  createCpcrSpecificTableRow,
  createDictionary,
  createDictionaryValue,
  createEPDProductClassification,
  createEPDProductMaterialProperty,
  createEpdCO2UptakeAssociation,
  createEpdDataQualityAssessmentItem,
  createEpdDataQualityAssessmentSummary,
  createEpdDataSource,
  createEpdDeclarationOfCO2Assumption,
  createEpdDefaultScenarioModuleDescription,
  createEpdProductComponent,
  createEpdProductDangerMaterial,
  createEpdProductInformation,
  createEpdProductPackagingMaterial,
  createEpdProductPanelContent,
  createEpdScenario,
  createEpdScenarioModule,
  createEpdScrapInput,
  createEpdShareOfTotalScrapInput,
  createProductionSiteAddress,
  deleteAddress,
  deleteCpcrSpecificDataRow,
  deleteCpcrSpecificDataRows,
  deleteDictionaryValue,
  deleteEPDProductMaterialProperty,
  deleteEpdDataQualityAssessmentItems,
  deleteEpdDataSource,
  deleteEpdProductComponents,
  deleteEpdProductDangerMaterials,
  deleteEpdProductPackagingMaterials,
  deleteEpdProductPanelContent,
  deleteEpdScenario,
  deleteEpdScenarioModules,
  deleteEpdScrapInputs,
  deleteProductClassification,
  deleteProductInformation,
  depublishEpd,
  deregisterEpd,
  editorialUpdating,
  licenseeAdminVerificationApprove,
  publishEpd,
  sendEpdForPublicationEditorial,
  sendEpdForPublication,
  removeEPDFile,
  requestCollaboration,
  startVerification,
  unlockEpd,
  updateAddress,
  updateCpcrSpecificTableColumn,
  updateCpcrSpecificTableRow,
  updateDictionary,
  updateDictionaryValue,
  updateEPDProductMaterialProperty,
  updateElectricity,
  updateEpdCO2UptakeAssociation,
  updateEpdContentDeclarationProduct,
  updateEpdDataQualityAssessmentItem,
  updateEpdDataQualityAssessmentSummary,
  updateEpdDataSource,
  updateEpdDeclarationOfCO2Assumption,
  updateEpdDefaultScenarioModuleDescription,
  updateEpdProductComponent,
  updateEpdProductDangerMaterial,
  updateEpdProductInformation,
  updateEpdProductPackagingMaterial,
  updateEpdProductPanelContent,
  updateEpdPropertyWithMutation,
  updateEpdScenario,
  updateEpdScenarioModule,
  updateEpdScrapInput,
  updateEpdShareOfTotalScrapInput,
  updateEpdSystemBoundaryGridModuleDeclared,
  updateEpdSystemBoundaryGridModuleGeography,
  updateEpdTransportationPerformance,
  updateProductClassification,
  upsertConversionFactor,
  upsertInfrastructureAndCapitalGoods,
  upsertLCASpecification,
  upsertReferenceFlow,
} from './epdApi';
import { importLcaResultsFile } from './epdLcaResultsApi';
import useCompilerOptimisticMutation from './useCompilerOptimisticMutation';

export const useUpdateEpdGeneralInformation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<AddEPDPropertyModel, EPDDefinitionModel>({
    mutationFn: (data) => updateEpdPropertyWithMutation(data),
    queryKey: [QUERY_KEYS.EPD_DEFINITION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return { ...queryData, [args.propertyName]: args.newValue };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateContentDeclarationProduct = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<UpdateEPDContentDeclarationProductModel, EPDDeclareContentProductModel[]>({
    mutationFn: (data) => updateEpdContentDeclarationProduct(data),
    queryKey: [QUERY_KEYS.BEST_AND_WORST_PRODUCT, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return queryData.map((q) => (q.type === args.type ? { ...q, [args.propertyName]: args.newValue } : q));
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdOrganizationInformation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<AddEPDPropertyModel, EPDOrganizationInformationModel>({
    mutationFn: (data) => updateEpdPropertyOrRemoveFile(data),
    queryKey: [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return { ...queryData, [args.propertyName]: args.newValue };
    },
    serialScopeId: epdVersionId,
  });
};

export const useAddOrganizationInformationFile = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<FormData, EPDOrganizationInformationModel>({
    mutationFn: (data) => addEPDFile(data),
    queryKey: [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useRemoveOrganizationInformationFile = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<string, EPDOrganizationInformationModel>({
    mutationFn: (id) => removeEPDFile(epdVersionId, id),
    queryKey: [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION, epdVersionId],
    applyOptimisticChanges(queryData, id) {
      return { ...queryData, organizationImages: queryData.organizationImages?.filter((img) => img.id !== id) };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdOrganizationInformationAddress = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<AddEPDPropertyModel, EPDOrganizationInformationAddressModel>({
    mutationFn: (data) => updateEpdPropertyWithMutation(data),
    queryKey: [QUERY_KEYS.EPD_ORGANIZATION_INFORMATION_ADDRESS, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return { ...queryData, [args.propertyName]: args.newValue };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdProductInformation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<UpdateEPDProductInformationPropertyModel, EPDProductInformationModel[]>({
    mutationFn: (data) => updateEpdProductInformation(data),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return queryData.map((p) => (p.id === args.productId ? { ...p, [args.propertyName]: args.newValue } : p));
    },
    serialScopeId: epdVersionId,
  });
};

export const useAddProductInformationFileMutation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<FormData, EPDProductInformationModel[]>({
    mutationFn: (data) => addEPDProductInformationFile(epdVersionId, data),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useRemoveProductInformationFileMutation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<string, EPDProductInformationModel[]>({
    mutationFn: (id) => removeEPDFile(epdVersionId, id),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION, epdVersionId],
    applyOptimisticChanges(queryData, id) {
      return queryData.map((p) => ({ ...p, productDetailsImages: p.productDetailsImages?.filter((img) => img.id !== id) }));
    },
    serialScopeId: epdVersionId,
  });
};

export const useCreateEpdProductInformation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: EPDProductInformationModel) => createEpdProductInformation(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteProductInformation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteProductInformation(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCopyProductInformation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: IVersionedEntity) => copyProductInformation(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
      }
    },
  });
};

export const useDeleteProductClassification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteProductClassification(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCreateProductClassification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ProductClassificationModel) => createEPDProductClassification(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCreateEpdProductionSiteAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateEPDProductionSiteAddressModel) => createProductionSiteAddress(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateProductClassification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ProductClassificationModel) => updateProductClassification(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

//Product material properties

export const useCreateProductMaterialProperty = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (productId: string) => createEPDProductMaterialProperty(productId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteProductMaterialProperty = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEPDProductMaterialProperty(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateProductMaterialProperty = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ProductMaterialProperyModel) => updateEPDProductMaterialProperty(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

//Address

export const useUpdateAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: FoundationAddressModel) => updateAddress(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteAddress(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_PRODUCT_INFORMATION] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

// Product components
export const useCreateEpdProductComponent = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useCompilerOptimisticMutation<ProductComponent, ProductComponent[]>({
    mutationFn: (data) => createEpdProductComponent(data),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_COMPONENTS, epdVersionId, type],
    applyOptimisticChanges(queryData, args) {
      return [...queryData, args];
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdProductComponent = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useCompilerOptimisticMutation<ProductComponent, ProductComponent[]>({
    mutationFn: (data) => updateEpdProductComponent(data),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_COMPONENTS, epdVersionId, type],
    applyOptimisticChanges(queryData, args) {
      return queryData.map((d) => (d.id === args.id ? args : d));
    },
    serialScopeId: epdVersionId,
  });
};

export const useDeleteProductComponents = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useGenericMutation(
    (ids: string[]) => deleteEpdProductComponents(ids),
    [QUERY_KEYS.EPD_PRODUCT_COMPONENTS, epdVersionId, type]
  );
};

// Packaging materials
export const useCreateEpdProductPackagingMaterial = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useCompilerOptimisticMutation<ProductPackagingMaterial, ProductPackagingMaterial[]>({
    mutationFn: (data) => createEpdProductPackagingMaterial(data),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_PACKAGING_MATERIALS, epdVersionId, type],
    applyOptimisticChanges(queryData, args) {
      return [...queryData, args];
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdProductPackagingMaterial = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useCompilerOptimisticMutation<ProductPackagingMaterial, ProductPackagingMaterial[]>({
    mutationFn: (data) => updateEpdProductPackagingMaterial(data),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_PACKAGING_MATERIALS, epdVersionId, type],
    applyOptimisticChanges(queryData, args) {
      return queryData.map((d) => (d.id === args.id ? args : d));
    },
    serialScopeId: epdVersionId,
  });
};

export const useDeleteProductPackagingMaterials = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useCompilerOptimisticMutation<string[], ProductPackagingMaterial[]>({
    mutationFn: (ids) => deleteEpdProductPackagingMaterials(ids),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_PACKAGING_MATERIALS, epdVersionId, type],
    applyOptimisticChanges(queryData, ids) {
      return queryData.filter((d) => !d.id || !ids.includes(d.id));
    },
    serialScopeId: epdVersionId,
  });
};

// Panel content
export const useCreateEpdProductPanelContent = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useCompilerOptimisticMutation<ProductPanelContent, ProductPanelContent[]>({
    mutationFn: (data) => createEpdProductPanelContent(data),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_PANEL_CONTENT, epdVersionId, type],
    applyOptimisticChanges(queryData, args) {
      return [...queryData, args];
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdProductPanelContent = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useCompilerOptimisticMutation<ProductPanelContent, ProductPanelContent[]>({
    mutationFn: (data) => updateEpdProductPanelContent(data),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_PANEL_CONTENT, epdVersionId, type],
    applyOptimisticChanges(queryData, args) {
      return queryData.map((d) => (d.id === args.id ? args : d));
    },
    serialScopeId: epdVersionId,
  });
};

export const useDeleteProductPanelContent = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useCompilerOptimisticMutation<string[], ProductPackagingMaterial[]>({
    mutationFn: (ids) => deleteEpdProductPanelContent(ids),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_PANEL_CONTENT, epdVersionId, type],
    applyOptimisticChanges(queryData, ids) {
      return queryData.filter((d) => !d.id || !ids.includes(d.id));
    },
    serialScopeId: epdVersionId,
  });
};

// Danger materials
export const useCreateEpdProductDangerMaterial = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useCompilerOptimisticMutation<ProductDangerMaterial, ProductDangerMaterial[]>({
    mutationFn: (data) => createEpdProductDangerMaterial(data),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_DANGER_MATERIALS, epdVersionId, type],
    applyOptimisticChanges(queryData, args) {
      return [...queryData, args];
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdProductDangerMaterial = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useCompilerOptimisticMutation<ProductDangerMaterial, ProductDangerMaterial[]>({
    mutationFn: (data) => updateEpdProductDangerMaterial(data),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_DANGER_MATERIALS, epdVersionId, type],
    applyOptimisticChanges(queryData, args) {
      return queryData.map((d) => (d.id === args.id ? args : d));
    },
    serialScopeId: epdVersionId,
  });
};

export const useDeleteProductDangerMaterials = (epdVersionId: string, type: EPDContentDeclarationProductType) => {
  return useCompilerOptimisticMutation<string[], ProductDangerMaterial[]>({
    mutationFn: (ids) => deleteEpdProductDangerMaterials(ids),
    queryKey: [QUERY_KEYS.EPD_PRODUCT_DANGER_MATERIALS, epdVersionId, type],
    applyOptimisticChanges(queryData, ids) {
      return queryData.filter((d) => !d.id || !ids.includes(d.id));
    },
    serialScopeId: epdVersionId,
  });
};

// Electricity
export const useUpdateEpdElectricity = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<ElectricityDataModel, ElectricityDataModel>({
    mutationFn: (data) => updateElectricity(data),
    queryKey: [QUERY_KEYS.EPD_ELECTRICITY, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return args;
    },
    serialScopeId: epdVersionId,
  });
};

// Scenarios
export const useCreateEpdScenario = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ScenarioItemModel) => createEpdScenario(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SCENARIOS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateEpdScenario = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ScenarioItemModel) => updateEpdScenario(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SCENARIOS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteEpdScenario = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdScenario(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SCENARIOS] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useCopyEpdScenario = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: IVersionedEntity) => copyEpdScenario(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_SCENARIOS] });
      }
    },
  });
};

// Scenario modules
export const useCreateEpdScenarioModule = (epdVersionId: string, scenarioId: string, module: string) => {
  return useCompilerOptimisticMutation<ScenarioModuleItemModel, ScenarioModuleReadModel>({
    mutationFn: (data) => createEpdScenarioModule(epdVersionId, data),
    queryKey: [QUERY_KEYS.EPD_MODULE, epdVersionId, scenarioId, module],
    applyOptimisticChanges(queryData, args) {
      return {
        ...queryData,
        scenarioModuleItems: [...queryData.scenarioModuleItems, args],
      };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdScenarioModule = (epdVersionId: string, scenarioId: string, module: string) => {
  return useCompilerOptimisticMutation<ScenarioModuleItemModel, ScenarioModuleReadModel>({
    mutationFn: (data) => updateEpdScenarioModule(epdVersionId, data),
    queryKey: [QUERY_KEYS.EPD_MODULE, epdVersionId, scenarioId, module],
    applyOptimisticChanges(queryData, args) {
      return {
        ...queryData,
        scenarioModuleItems: queryData.scenarioModuleItems.map((s) => (s.ord === args.ord ? args : s)),
      };
    },
    serialScopeId: epdVersionId,
  });
};

export const useDeleteEpdScenarioModules = (epdVersionId: string, scenarioId: string, module: string) => {
  return useCompilerOptimisticMutation<string[], ScenarioModuleReadModel>({
    mutationFn: (ids) => deleteEpdScenarioModules(epdVersionId, ids),
    queryKey: [QUERY_KEYS.EPD_MODULE, epdVersionId, scenarioId, module],
    applyOptimisticChanges(queryData, ids) {
      return {
        ...queryData,
        scenarioModuleItems: queryData.scenarioModuleItems.filter((s) => !s.id || !ids.includes(s.id)),
      };
    },
    serialScopeId: epdVersionId,
  });
};

export const useCreateEpdScenarioModuleDefaultDescription = (epdVersionId: string, scenarioId: string, module: string) => {
  return useCompilerOptimisticMutation<ScenarioModuleDefaultDescriptionModel, ScenarioModuleReadModel>({
    mutationFn: (data) => createEpdDefaultScenarioModuleDescription(data),
    queryKey: [QUERY_KEYS.EPD_MODULE, epdVersionId, scenarioId, module],
    applyOptimisticChanges(queryData, args) {
      return {
        ...queryData,
        defaultScenarioModuleDescription: args.defaultDescription,
      };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdScenarioModuleDefaultDescription = (epdVersionId: string, scenarioId: string, module: string) => {
  return useCompilerOptimisticMutation<ScenarioModuleDefaultDescriptionModel, ScenarioModuleReadModel>({
    mutationFn: (data) => updateEpdDefaultScenarioModuleDescription(data),
    queryKey: [QUERY_KEYS.EPD_MODULE, epdVersionId, scenarioId, module],
    applyOptimisticChanges(queryData, args) {
      return {
        ...queryData,
        defaultScenarioModuleDescription: args.defaultDescription,
      };
    },
    serialScopeId: epdVersionId,
  });
};

export const useChangeEPDDefinition = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: AddEPDPropertyModel) => updateEpdPropertyWithMutation(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DEFINITION] }),
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] }),
        ]);
      }
    },
  });
};

export const useChangeEpdTags = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<AddEPDPropertyModel, EPDDefinitionModel>({
    mutationFn: (data) => updateEpdPropertyWithMutation(data),
    queryKey: [QUERY_KEYS.EPD_DEFINITION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return {
        ...queryData,
        [args.propertyName]: args.newValue,
      };
    },
    onAllSettled({ queryClient }) {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TAG_SOURCE], exact: true });
    },
    serialScopeId: epdVersionId,
  });
};

export const useChangeEPDVerificationReport = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: AddEPDPropertyModel) => updateEpdPropertyWithMutation(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_VERIFICATION_REPORT_INFO] }),
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] }),
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DEFINITION] }),
        ]);
      }
    },
  });
};

export const useImportLcaResultsFile = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: FormData) => importLcaResultsFile(epdVersionId, data),
    onSettled: async () => {
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_LCA_RESULTS] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] }),
      ]);
    },
  });
};

export const useAddEpdVerificationReportFile = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<FormData, VerificationViewModel>({
    mutationFn: (data) => addEPDFile(data),
    queryKey: [QUERY_KEYS.EPD_VERIFICATION_REPORT_INFO, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useRemoveEpdVerificationReportFile = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<string, VerificationViewModel>({
    mutationFn: (id) => removeEPDFile(epdVersionId, id),
    queryKey: [QUERY_KEYS.EPD_VERIFICATION_REPORT_INFO, epdVersionId],
    applyOptimisticChanges(queryData, id) {
      return { ...queryData, verificationReports: queryData.verificationReports.filter((r) => r.id !== id) };
    },
    serialScopeId: epdVersionId,
  });
};

// Declaration of CO2
export const useCreateEpdDeclarationOfCO2Assumption = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<DeclarationOfCO2AssumptionModelWithParams, DeclarationOfCO2Model[]>({
    mutationFn: (data) =>
      createEpdDeclarationOfCO2Assumption(data.epdVersionId, data.nameId, {
        assumptions: data.assumptions,
        isSelected: data.isSelected,
      }),
    queryKey: [QUERY_KEYS.EPD_DECLARATIONS_OF_CO2, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return queryData.map((d) => (d.nameId === args.nameId ? { ...d, ...args } : d));
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdDeclarationOfCO2Assumption = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<DeclarationOfCO2AssumptionModelWithParams, DeclarationOfCO2Model[]>({
    mutationFn: (data) =>
      updateEpdDeclarationOfCO2Assumption(data.epdVersionId, data.nameId, {
        assumptions: data.assumptions,
        isSelected: data.isSelected,
      }),
    queryKey: [QUERY_KEYS.EPD_DECLARATIONS_OF_CO2, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return queryData.map((d) => (d.nameId === args.nameId ? { ...d, ...args } : d));
    },
    serialScopeId: epdVersionId,
  });
};

export const useCreateEpdCO2UptakeAssociation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<CO2UptakeAssociationModelWithParams, CO2UptakeAssociationModel>({
    mutationFn: (data) =>
      createEpdCO2UptakeAssociation(data.epdVersionId, {
        association: data.association,
      }),
    queryKey: [QUERY_KEYS.EPD_CO2_UPTAKE_ASSOCIATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return { ...queryData, ...args };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdCO2UptakeAssociation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<CO2UptakeAssociationModelWithParams, CO2UptakeAssociationModel>({
    mutationFn: (data) => updateEpdCO2UptakeAssociation(data.epdVersionId, { id: data.id, association: data.association }),
    queryKey: [QUERY_KEYS.EPD_CO2_UPTAKE_ASSOCIATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return { ...queryData, ...args };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpsertLCASpecification = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<LCASpecificationModel, LCASpecificationModel>({
    mutationFn: (data) => upsertLCASpecification(epdVersionId, data),
    queryKey: [QUERY_KEYS.EPD_LCA_SPECIFICATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return args;
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpsertInfrastructureAndCapitalGoods = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<InfrastructureAndCapitalGoodsModel, InfrastructureAndCapitalGoodsModel>({
    mutationFn: (data) => upsertInfrastructureAndCapitalGoods(epdVersionId, data),
    queryKey: [QUERY_KEYS.EPD_INFRASTRUCTURE_AND_CAPITAL_GOODS, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return args;
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpsertEpdReferenceFlow = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ReferenceFlowModel) => upsertReferenceFlow(epdVersionId, data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_REFERENCE_FLOW] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_CONVERSION_FACTOR] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpsertEpdConversionFactor = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<ConversionFactorModel, ConversionFactorModel>({
    mutationFn: (data) => upsertConversionFactor(epdVersionId, data),
    queryKey: [QUERY_KEYS.EPD_CONVERSION_FACTOR, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return args;
    },
    serialScopeId: epdVersionId,
  });
};

export const useCreateEpdDataSource = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: EPDDataSourceModel) => createEpdDataSource(epdVersionId, data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DATA_SOURCES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useUpdateEpdDataSource = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: EPDDataSourceModel) => updateEpdDataSource(epdVersionId, data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DATA_SOURCES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

export const useDeleteEpdDataSource = (epdVersionId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteEpdDataSource(id, epdVersionId),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DATA_SOURCES] });
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] });
      }
    },
  });
};

// Contribution of scrap inputs
export const useCreateEpdScrapInput = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<ScrapInputModel, ScrapInputModel[]>({
    mutationFn: (data) => createEpdScrapInput(data),
    queryKey: [QUERY_KEYS.EPD_SCRAP_INPUTS, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return [...queryData, args];
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdScrapInput = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<ScrapInputModel, ScrapInputModel[]>({
    mutationFn: (data) => updateEpdScrapInput(data),
    queryKey: [QUERY_KEYS.EPD_SCRAP_INPUTS, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return queryData.map((d) => (d.ord === args.ord ? args : d));
    },
    serialScopeId: epdVersionId,
  });
};

export const useDeleteEpdScrapInputs = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<string[], ScrapInputModel[]>({
    mutationFn: (ids) => deleteEpdScrapInputs(ids),
    queryKey: [QUERY_KEYS.EPD_SCRAP_INPUTS, epdVersionId],
    applyOptimisticChanges(queryData, ids) {
      return queryData.filter((d) => !d.id || !ids.includes(d.id));
    },
    serialScopeId: epdVersionId,
  });
};

export const useCreateEpdShareOfTotalScrapInput = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<ShareOfTotalScrapInputModel, ShareOfTotalScrapInputModel>({
    mutationFn: (data) => createEpdShareOfTotalScrapInput(data),
    queryKey: [QUERY_KEYS.EPD_SHARE_OF_TOTAL_SCRAP_INPUT, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdShareOfTotalScrapInput = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<ShareOfTotalScrapInputModel, ShareOfTotalScrapInputModel>({
    mutationFn: (data) => updateEpdShareOfTotalScrapInput(data),
    queryKey: [QUERY_KEYS.EPD_SHARE_OF_TOTAL_SCRAP_INPUT, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return args;
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdSystemBoundaryModuleDeclared = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<UpdateDeclaredModulesModel, SystemBoundaryGridModel>({
    mutationFn: (data) => updateEpdSystemBoundaryGridModuleDeclared(data),
    queryKey: [QUERY_KEYS.EPD_LCA_DECLARED_MODULES, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return {
        ...queryData,
        declaredModules: queryData.declaredModules
          ? {
              ...queryData.declaredModules,
              [args.fieldName]: args.value,
            }
          : queryData.declaredModules,
      };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdSystemBoundaryModuleGeography = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<UpdateDeclaredModulesGeographyModel, SystemBoundaryGridModel>({
    mutationFn: (data) => updateEpdSystemBoundaryGridModuleGeography(data),
    queryKey: [QUERY_KEYS.EPD_LCA_DECLARED_MODULES, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return {
        ...queryData,
        declaredModulesGeography: queryData.declaredModulesGeography
          ? {
              ...queryData.declaredModulesGeography,
              [args.fieldName]: args.value,
            }
          : queryData.declaredModulesGeography,
      };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdTransportationPerformance = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<TransportationPerformanceModel, TransportationPerformanceModel>({
    mutationFn: (data) => updateEpdTransportationPerformance(data),
    queryKey: [QUERY_KEYS.EPD_LCA_TRANSPORTATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return args;
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateSystemBoundaryMutation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<AddEPDPropertyModel, SystemBoundaryModel>({
    mutationFn: (data) => updateEpdPropertyOrRemoveFile(data),
    queryKey: [QUERY_KEYS.EPD_SYSTEM_BOUNDARY, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      switch (args.propertyName) {
        case 'systemBoundaryDescription':
          return { ...queryData, description: args.newValue };
        default:
          return { ...queryData, [args.propertyName]: args.newValue };
      }
    },
    serialScopeId: epdVersionId,
  });
};

export const useAddSystemBoundaryFileMutation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<FormData, SystemBoundaryModel>({
    mutationFn: (data) => addEPDFile(data),
    queryKey: [QUERY_KEYS.EPD_SYSTEM_BOUNDARY, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useRemoveSystemBoundaryFileMutation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<string, SystemBoundaryModel>({
    mutationFn: (id) => removeEPDFile(epdVersionId, id),
    queryKey: [QUERY_KEYS.EPD_SYSTEM_BOUNDARY, epdVersionId],
    applyOptimisticChanges(queryData, id) {
      return { ...queryData, processFlowDiagramsImages: queryData.processFlowDiagramsImages.filter((img) => img.id !== id) };
    },
    serialScopeId: epdVersionId,
  });
};

export const useAddCoverPageFile = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<FormData, EPDCoverPageModel>({
    mutationFn: (data) => addEPDFile(data),
    queryKey: [QUERY_KEYS.EPD_COVER_PAGE_INFORMATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useRemoveCoverPageFile = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<string, EPDCoverPageModel>({
    mutationFn: (id) => removeEPDFile(epdVersionId, id),
    queryKey: [QUERY_KEYS.EPD_COVER_PAGE_INFORMATION, epdVersionId],
    applyOptimisticChanges(queryData, id) {
      return {
        ...queryData,
        productImages: queryData.productImages?.filter((p) => p.id !== id),
        companyLogoImages: queryData.companyLogoImages?.filter((p) => p.id !== id),
      };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdPCRInformation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<
    { requestData: AddEPDPropertyModel; newOptimisticValue?: PCRModel },
    PCRInformationModel
  >({
    mutationFn: ({ requestData }) => updateEpdPropertyWithMutation(requestData),
    queryKey: [QUERY_KEYS.EPD_PCR_INFORMATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      switch (args.requestData.propertyName) {
        case 'PCR':
          return { ...queryData, pcr: args.newOptimisticValue };
        case 'CPCR':
          return { ...queryData, cpcr: args.newOptimisticValue };
        case 'cpcrProductCategory': {
          const newCategoryId = args.requestData.newValue;
          const possibleCategories = queryData.cpcr?.cpcrProductCategories;
          if (!possibleCategories) {
            return undefined;
          }
          return {
            ...queryData,
            cpcrProductCategory: newCategoryId ? possibleCategories.find((c) => c.id === newCategoryId) : undefined,
          };
        }
        default:
          throw new Error('Not implemented');
      }
    },
    onAllSettled({ queryClient }) {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_CPCR_SPECIFIC_DATA, epdVersionId], exact: true });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_LCA_SPECIFICATION, epdVersionId], exact: true });
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdLcaSupport = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<AddEPDPropertyModel, LcaSupportModel>({
    mutationFn: (data) => updateEpdPropertyWithMutation(data),
    queryKey: [QUERY_KEYS.EPD_LCA_SUPPORT, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdThirdPartyVerification = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<AddEPDPropertyModel, ThirdPartyVerificationModel>({
    mutationFn: (data) => updateEpdPropertyOrRemoveFile(data),
    queryKey: [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      const dontOptimisticallyUpdateProperties = ['epdVerifier', 'preverifiedToolVersion', 'processCertificateIssuedBy'];
      if (dontOptimisticallyUpdateProperties.includes(args.propertyName)) {
        return undefined;
      }
      if (args.propertyName === 'preverifiedTool') {
        const selectedTool = args.newValue ? queryData.preVerifiedToolDataSource?.find((t) => t.id === args.newValue) : null;
        if (selectedTool === undefined) {
          return undefined;
        }
        return { ...queryData, preverifiedTool: selectedTool };
      }
      return { ...queryData, [args.propertyName]: args.newValue };
    },
    onAllSettled({ queryClient }) {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_COMPANY_VERIFIERS] });
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdAdditionalInformation = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<AddEPDPropertyModel, EPDAdditionalInformationModel>({
    mutationFn: (data) => updateEpdPropertyWithMutation(data),
    queryKey: [QUERY_KEYS.EPD_ADDITIONAL_INFORMATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return { ...queryData, [args.propertyName]: args.newValue };
    },
    serialScopeId: epdVersionId,
  });
};

export const useConnectEpdLcaPractitioner = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<ConnectDeveloperModel, LcaSupportModel>({
    mutationFn: (data) => connectDeveloper(data),
    queryKey: [QUERY_KEYS.EPD_LCA_SUPPORT, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useConnectEpdIndividualVerifier = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<ConnectVerifierModel, ThirdPartyVerificationModel>({
    mutationFn: (data) => connectVerifier(data),
    queryKey: [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useConnectEpdCertificationBody = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<any, ThirdPartyVerificationModel>({
    mutationFn: (data) => connectCertificateIssuedByBody(data),
    queryKey: [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useRequestCollaboration = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<RequestCollaborationModel, ThirdPartyVerificationModel>({
    mutationFn: (data) => requestCollaboration(data),
    queryKey: [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

const updateEpdPropertyOrRemoveFile = async (data: AddEPDPropertyModel) => {
  if (data.propertyName === REMOVE_FILE) {
    await EpdService.removeEpdFile(data.newValue, data.versionId!);
    return;
  }

  await updateEpdPropertyWithMutation(data);
};

export const useAddEPDProcessCertificate = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<FormData, ThirdPartyVerificationModel>({
    mutationFn: (data) => addEPDFile(data),
    queryKey: [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useRemoveEPDProcessCertificate = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<string, ThirdPartyVerificationModel>({
    mutationFn: (id) => removeEPDFile(epdVersionId, id),
    queryKey: [QUERY_KEYS.EPD_THIRD_PARTY_VERIFICATION, epdVersionId],
    applyOptimisticChanges(queryData, id) {
      return { ...queryData, processCertificates: queryData.processCertificates.filter((c) => c.id !== id) };
    },
    serialScopeId: epdVersionId,
  });
};

export const useAddEpdAdditionalDocument = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<FormData, FileModel[]>({
    mutationFn: (data) => addEPDFile(data),
    queryKey: [QUERY_KEYS.EPD_ADDITIONAL_DOCUMENTS, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
  });
};

export const useRemoveEpdAdditionalDocument = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<string, FileModel[]>({
    mutationFn: (id) => removeEPDFile(epdVersionId, id),
    queryKey: [QUERY_KEYS.EPD_ADDITIONAL_DOCUMENTS, epdVersionId],
    applyOptimisticChanges(queryData, id) {
      return queryData.filter((f) => f.id !== id);
    },
  });
};

export const addEpdFile = async (data: AddEPDFileModel, mutation: UseMutationResult<any, Error, any, any>) => {
  if (!data) {
    throw new Error('Required data parameter must not be null.');
  }

  const { epdId, propertyName, file, epdProductInformationId } = data;

  if (!epdId || !propertyName || !file) {
    throw new Error('Parameters epdId, propertyName, file must be specified.');
  }

  const fileName = file.name;
  const fileType = file.type;

  if (fileType.startsWith('image/') && file.size > MAX_IMG_SIZE) {
    toaster({
      severity: 'error',
      summary: 'Error',
      details: t('epdWizard.messages.errorTooLargeImageFile', {
        size: Math.floor(MAX_IMG_SIZE / (1024 * 1024)),
      }) as string,
    });

    return;
  }

  const reader = new FileReader();
  let resolveUploading: (value?: undefined) => void;
  let rejectUploading: (reason?: any) => void;
  const uploadingPromise = new Promise((resolve, reject) => {
    resolveUploading = resolve;
    rejectUploading = reject;
  });
  reader.onload = async () => {
    const fileBlob = reader.result as ArrayBuffer;
    if (fileBlob) {
      const blob = new Blob([new Uint8Array(fileBlob)], { type: fileType });
      if (blob.size > 10000000) {
        toast.error(t('epdWizard.messages.errorTooLargeFile') as string, {
          position: 'top-center',
        });
        rejectUploading(new Error('File is too large.'));
        return;
      }

      try {
        const addFileModel: FileParameter = { fileName, data: blob };
        const addFileFormData = new FormData();

        addFileFormData.append('EPDId', epdId.toString());
        addFileFormData.append('PropertyName', propertyName.toString());
        addFileFormData.append('NewValue', addFileModel.data, addFileModel.fileName ? addFileModel.fileName : 'NewValue');

        if (!!epdProductInformationId) {
          addFileFormData.append('EPDProductInformationId', epdProductInformationId.toString());
        }

        await mutation.mutateAsync(addFileFormData);

        resolveUploading();
      } catch (e) {
        toaster({
          severity: 'error',
          summary: 'Error',
          details: t('epdWizard.messages.error') as string,
        });
        rejectUploading(e);
      }
    }
  };
  reader.readAsArrayBuffer(file);
  await uploadingPromise;
};

export const useCreateDictionary = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DictionaryCatalogItem) => createDictionary(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DICTIONARY_CATALOG] });
      }
    },
  });
};

export const useUpdateDictionary = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DictionaryCatalogItem) => updateDictionary(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DICTIONARY_CATALOG] });
      }
    },
  });
};

export const useCreateDictionaryValue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DictionaryValue) => createDictionaryValue(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DICTIONARY_VALUES] });
      }
    },
  });
};

export const useUpdateDictionaryValue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DictionaryValue) => updateDictionaryValue(data),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DICTIONARY_VALUES] });
      }
    },
  });
};

export const useDeleteDictionaryValue = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteDictionaryValue(id),
    onMutate: () => {},
    onError: () => {
      console.error('error');
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DICTIONARY_VALUES] });
      }
    },
  });
};

function useActionButtonMutation<TArgs>(epdId: string, epdVersionId: string, mutationFn: (args: TArgs) => Promise<any>) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSettled: async () => {
      // Note: must await the following queries, so the spinner keeps spinning until all data is refreshed
      await Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_LATEST_VERSION, epdId], exact: true }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES, epdId], exact: true }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_DEFINITION, epdVersionId], exact: true }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_VERIFICATION_REPORT_INFO, epdVersionId], exact: true }),
      ]);
    },
  });
}

export const useCancelUpdateEpd = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<void>(epdId, epdVersionId, () => cancelUpdateEpd(epdId));
};

export const useStartVerification = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<void>(epdId, epdVersionId, () => startVerification(epdId));
};

export const useCancelVerification = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<void>(epdId, epdVersionId, () => cancelVerification(epdId));
};

export const useSendEpdForPublication = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<void>(epdId, epdVersionId, () => sendEpdForPublication(epdId));
};

export const useCancelRegistrationEpd = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<void>(epdId, epdVersionId, () => EpdStateService.cancelRegistration(epdId));
};

export const useDeregisterEpd = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<void>(epdId, epdVersionId, () => deregisterEpd(epdId));
};

export const useCancelEpdDeregistration = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<void>(epdId, epdVersionId, () => cancelEpdDeregistration(epdId));
};

export const useUnlockEpd = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<void>(epdId, epdVersionId, () => unlockEpd(epdId));
};

export const useLicenseeAdminVerificationApprove = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<void>(epdId, epdVersionId, () => licenseeAdminVerificationApprove(epdId));
};

export const useApproveVerification = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<FormData>(epdId, epdVersionId, (approveModel) => approveVerification(epdId, approveModel));
};

export const useDeclineVerification = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<{ reason: string }>(epdId, epdVersionId, (model) =>
    EpdStateService.declineVerification(epdId, model.reason)
  );
};

export const useCancelApproveVerificationMutation = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<void>(epdId, epdVersionId, () => cancelApproveVerification(epdId));
};

export const usePublishEpd = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<string>(epdId, epdVersionId, (versionId) => publishEpd(epdId, versionId));
};

export const useDeclinePublicationEpd = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<{ declineReason: string }>(epdId, epdVersionId, ({ declineReason }) =>
    EpdStateService.declinePublication(epdId, declineReason)
  );
};

export const useDepublishEpd = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<{ depublicationReason: string | null }>(epdId, epdVersionId, ({ depublicationReason }) =>
    depublishEpd(epdId, depublicationReason)
  );
};

export const useLicenseeAdminVerificationDecline = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<{ declineReason: string }>(epdId, epdVersionId, ({ declineReason }) =>
    EpdStateService.licenseeAdminVerificationDecline(epdId, declineReason)
  );
};

export const useSendEpdForPublicationEditorial = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<SendForPublicationEditorialModel>(epdId, epdVersionId, (data) =>
    sendEpdForPublicationEditorial(data)
  );
};

export const useEditorialUpdating = (epdId: string, epdVersionId: string) => {
  return useActionButtonMutation<void>(epdId, epdVersionId, () => editorialUpdating(epdId));
};

/***********************************************************************/
export const useGenericMutation = <TData = unknown, TVariables = void>(
  mutationFn: MutationFunction<TData, TVariables>,
  invalidateQueriesKeys: (string | unknown[])[]
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onMutate: () => {},
    onError: (error: any) => {
      console.error('Error occurred:', error);
    },
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (!error) {
        await Promise.all([
          ...invalidateQueriesKeys.map((key) =>
            queryClient.invalidateQueries({ queryKey: Array.isArray(key) ? key : [key] })
          ),
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.EPD_ACTIONS_STATES] }),
        ]);
      }
    },
  });
};

export const useCreateCpcrSpecificTableRow = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<CpcrPerformanceTableDataModel, CpcrPerformanceInformationModel>({
    mutationFn: (data) => createCpcrSpecificTableRow(data, epdVersionId),
    queryKey: [QUERY_KEYS.EPD_CPCR_SPECIFIC_DATA, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return { ...queryData, data: [...queryData.data, args] };
    },
    serialScopeId: epdVersionId,
  });
};

export const useDeleteCpcrSpecificTableRow = () => {
  return useGenericMutation((id: string) => deleteCpcrSpecificDataRow(id), [[QUERY_KEYS.EPD_CPCR_SPECIFIC_DATA]]);
};

export const useDeleteCpcrSpecificTableRows = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<string[], CpcrPerformanceInformationModel>({
    mutationFn: (ids) => deleteCpcrSpecificDataRows(ids),
    queryKey: [QUERY_KEYS.EPD_CPCR_SPECIFIC_DATA, epdVersionId],
    applyOptimisticChanges(queryData, ids) {
      return { ...queryData, data: queryData.data.filter((d) => !d.id || !ids.includes(d.id)) };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateCpcrSpecificTableRow = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<CpcrPerformanceTableDataModel, CpcrPerformanceInformationModel>({
    mutationFn: (data) => updateCpcrSpecificTableRow(data, epdVersionId),
    queryKey: [QUERY_KEYS.EPD_CPCR_SPECIFIC_DATA, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return {
        ...queryData,
        data: queryData.data.map((d) => (d.tableCode === args.tableCode && d.ord === args.ord ? args : d)),
      };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateCpcrSpecificTableColumn = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<any, CpcrPerformanceInformationModel>({
    mutationFn: (data) => updateCpcrSpecificTableColumn(data),
    queryKey: [QUERY_KEYS.EPD_CPCR_SPECIFIC_DATA, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return undefined;
    },
    serialScopeId: epdVersionId,
  });
};

export const useCreateEpdDataQualityAssessmentItem = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<DataQualityAssessmentItemModel, DataQualityAssessmentReadModel>({
    mutationFn: (data) => createEpdDataQualityAssessmentItem(data),
    queryKey: [QUERY_KEYS.EPD_DATA_QUALITY_ASSESSMENT_AND_REFERENCE_YEARS, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return { ...queryData, dataQualityAssessmentItems: [...queryData.dataQualityAssessmentItems, args] };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdDataQualityAssessmentItem = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<DataQualityAssessmentItemModel, DataQualityAssessmentReadModel>({
    mutationFn: (data) => updateEpdDataQualityAssessmentItem(data),
    queryKey: [QUERY_KEYS.EPD_DATA_QUALITY_ASSESSMENT_AND_REFERENCE_YEARS, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return {
        ...queryData,
        dataQualityAssessmentItems: queryData.dataQualityAssessmentItems.map((it) => (it.ord === args.ord ? args : it)),
      };
    },
    serialScopeId: epdVersionId,
  });
};

export const useDeleteEpdDataQualityAssessmentItems = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<string[], DataQualityAssessmentReadModel>({
    mutationFn: (ids) => deleteEpdDataQualityAssessmentItems(epdVersionId, ids),
    queryKey: [QUERY_KEYS.EPD_DATA_QUALITY_ASSESSMENT_AND_REFERENCE_YEARS, epdVersionId],
    applyOptimisticChanges(queryData, ids) {
      return {
        ...queryData,
        dataQualityAssessmentItems: queryData.dataQualityAssessmentItems.filter((it) => !it.id || !ids.includes(it.id)),
      };
    },
    serialScopeId: epdVersionId,
  });
};

export const useCreateEpdDataQualityAssessmentSummary = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<DataQualityAssessmentSummaryModel, DataQualityAssessmentReadModel>({
    mutationFn: (data) => createEpdDataQualityAssessmentSummary(data),
    queryKey: [QUERY_KEYS.EPD_DATA_QUALITY_ASSESSMENT_AND_REFERENCE_YEARS, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return { ...queryData, dataQualityAssessmentSummary: args.dataQualityAssessmentSummary };
    },
    serialScopeId: epdVersionId,
  });
};

export const useUpdateEpdDataQualityAssessmentSummary = (epdVersionId: string) => {
  return useCompilerOptimisticMutation<DataQualityAssessmentSummaryModel, DataQualityAssessmentReadModel>({
    mutationFn: (data) => updateEpdDataQualityAssessmentSummary(data),
    queryKey: [QUERY_KEYS.EPD_DATA_QUALITY_ASSESSMENT_AND_REFERENCE_YEARS, epdVersionId],
    applyOptimisticChanges(queryData, args) {
      return { ...queryData, dataQualityAssessmentSummary: args.dataQualityAssessmentSummary };
    },
    serialScopeId: epdVersionId,
  });
};
