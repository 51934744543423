import ToggleButton from 'components/v2/compiler/panels/toggleable/ToggleButton';
import {
  StatusApprovedIcon,
  StatusAttentionIcon,
  StatusDeclinedIcon,
  StatusInformationIcon,
  StatusPendingIcon,
} from 'components/v2/icons';
import { useEffect, useState } from 'react';
import { EPDModel, FileLoadingModel, FileModel, VerificationType, VersionStateType } from 'services/EpdClient';
import styled from 'styled-components';

import HeaderActionPanelVerificationProcess from './HeaderActionPanelVerificationProcess';

const EpdPanelHeaderVerificationStepTemplate = (
  options: any,
  epd: EPDModel,
  onChangeEpd: any,
  refreshEPD: any,
  canUpload: boolean,
  isReadOnly: boolean,
  fileLoading?: FileLoadingModel[]
) => {
  const className = `${options.className} justify-content-start w-full`;
  const [versionState, setVersionState] = useState<string>(epd?.versionState ?? VersionStateType.Draft);

  useEffect(() => {
    if (epd === undefined) {
      return;
    }

    setVersionState(epd.versionState ?? VersionStateType.Draft);
  }, [epd, epd?.versionState]);

  const showStatusIconInEPDVerification = (status?: string) => {
    const iconSizeStyle = { width: '12px', height: '12px' };

    switch (status) {
      case VersionStateType.Approved:
      case VersionStateType.SentForPublicationLicenseeAdmin:
      case VersionStateType.SentForPublicationEditorial:
        return <StatusApprovedIcon />;

      case VersionStateType.DeclinedVerification:
      case VersionStateType.SentForPublication:
        return <StatusDeclinedIcon />;

      case VersionStateType.Draft:
        return <StatusInformationIcon style={iconSizeStyle} />;

      case VersionStateType.UnderReview:
        return <StatusPendingIcon />;

      default:
        return <div style={{ ...iconSizeStyle, marginRight: '0.5rem' }} />;
    }
  };
  const showStatusIconInEPDProcessCertification = (verificationReports?: FileModel[]) => {
    if (!verificationReports || verificationReports.length == 0) {
      return <StatusAttentionIcon />;
    }
    return <StatusApprovedIcon />;
  };

  return (
    <div className={className} style={{ backgroundColor: '#FFFFFF', marginTop: '1rem', minHeight: '70px' }}>
      {epd?.verificationType == VerificationType.EPDVerification
        ? showStatusIconInEPDVerification(versionState)
        : showStatusIconInEPDProcessCertification(epd?.verificationReports)}
      <ToggleButton options={options} withMargins />
      <Container style={{ display: 'grid', width: '100%', gridTemplateColumns: 'auto 1fr auto' }}>
        <EpdTitle>{epd.shortIdentificationNumber}&nbsp;</EpdTitle>
        <EpdTitle>{epd.title}</EpdTitle>
        <HeaderActionPanelVerificationProcess
          epd={epd}
          onChangeEpd={onChangeEpd}
          refreshEPD={refreshEPD}
          fileLoading={fileLoading}
          canUpload={canUpload}
          isReadOnly={isReadOnly}
        />
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 38px;
`;

const EpdTitle = styled.div`
  ${(props) => props.theme.typography.bodyBody14Semibold}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.75rem 0rem;
  margin: 0;
`;

export default EpdPanelHeaderVerificationStepTemplate;
