import { toaster } from 'components/v2/toast';
import { MAX_IMG_SIZE, REMOVE_FILE } from 'constants/constants';
import { Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EpdLinks } from 'routes/EpdRoutes';
import CopyEpdService from 'services/CopyEpdService';
import {
  CopyEpdFormikModel,
  CopyEpdV2Model,
  EPDModel,
  EpdCopyGroupWizardModel,
  FileLoadingModel,
  FileModel,
  PCRModel,
  UserRoleNames,
  UserRolesModel,
} from 'services/EpdClient';
import EpdService from 'services/EpdService';
import MembershipService from 'services/MembershipService';

import EpdListSkeleton from '../EpdListSkeleton';
import NoCopies from '../step-placeholder/NoCopies';
import WizardStep1 from './WizardStep1';
import WizardStep2 from './WizardStep2';
import WizardStep3 from './WizardStep3';
import WizardStep4 from './WizardStep4';
import { AccessMatrix, DefaultMatrixValue } from './accessMatrix';

const useCopyWizardLogic = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { epdGroupId } = useParams<{ epdGroupId: string }>();
  const { companyAccountId, company } = React.useContext(CompanyContext);
  const [copyGroupId, setCopyGroupId] = useState<string>(epdGroupId);
  const [groupData, setGroupData] = useState<EpdCopyGroupWizardModel>({} as EpdCopyGroupWizardModel);
  const [isExplanationVisible, setIsExplanationVisible] = useState<boolean>(true);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(1);
  const helpButtonRef = useRef<HTMLButtonElement | undefined>(null);

  const [copyGroupValues, setCopyGroupValues] = useState<CopyEpdFormikModel | undefined>();
  const [pcr, setPcr] = useState<PCRModel | undefined>(groupData?.pcr);
  const prevCountRef = useRef<number>(1);
  const [isUiBlocked, setIsUiBlocked] = useState<boolean>(false);
  const [isUpdatingEpd, setIsUpdatingEpd] = useState<boolean>(false);
  const [declinePublicationText, setDeclinePublicationText] = useState<string>('');
  const [declinePublicationConfirmed, setDeclinePublicationConfirmed] = useState<boolean>(false);
  const [declinePublicationByLicenseeAdminConfirmed, setDeclinePublicationByLicenseeAdminConfirmed] =
    useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState<FileLoadingModel[]>([]);

  useEffect(() => {
    prevCountRef.current = activeStepIndex;
  }, [activeStepIndex]);

  useEffect(() => {
    if (declinePublicationConfirmed) {
      onConfirmDeclinePublication();
      setDeclinePublicationConfirmed(false);
    }
    prevCountRef.current = activeStepIndex;
  }, [declinePublicationConfirmed]);

  useEffect(() => {
    if (declinePublicationByLicenseeAdminConfirmed) {
      onDeclineCopyByLicenseeAdmin();
      setDeclinePublicationByLicenseeAdminConfirmed(false);
    }
    prevCountRef.current = activeStepIndex;
  }, [declinePublicationByLicenseeAdminConfirmed]);

  useEffect(() => {
    const fetchVerifierUsers = async () => {
      const verifiers = await MembershipService.getVerifiers(company?.id || '');
      setGroupData((previous) => ({ ...previous, verifiersSource: verifiers }));
    };

    const fetchEpdUsers = async () => {
      const developers = await MembershipService.getMembershipsByCompanyAndRole(company?.id || '', Role.EPDDeveloper);
      const owners = await MembershipService.getMembershipsByCompanyAndRole(company?.id || '', Role.EPDOwner);
      setGroupData((previous) => ({ ...previous, developersSource: [...developers, ...owners] }));
    };

    const fetchDataSources = async (account: string, company: string) => {
      try {
        const dataSources = await CopyEpdService.getEpdToCopyDataSources(account, company);
        setGroupData((previous) => ({ ...previous, epdCopyGroupDataSources: dataSources }));
        if (!copyGroupId && (!dataSources?.epdsToCopy || dataSources?.epdsToCopy?.length === 0)) {
          toaster({
            severity: 'error',
            summary: 'Error',
            details: 'You do not have any published EPDs to copy',
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (companyAccountId && company?.id) {
      fetchDataSources(companyAccountId, company?.id);
      fetchVerifierUsers();
      fetchEpdUsers();
    }
  }, [companyAccountId, company?.id]);

  const refetchGroupData = async () => {
    try {
      refetchGroupState();
      setIsUiBlocked(true);
      const result = await CopyEpdService.getEpdGroup(copyGroupId);
      setGroupData((previous) => ({ ...previous, ...result }));
    } finally {
      setIsUiBlocked(false);
    }
  };

  useEffect(() => {
    async function fetchGroupData() {
      try {
        setIsUiBlocked(true);
        const result = await CopyEpdService.getEpdGroup(copyGroupId);
        setGroupData((previous) => ({ ...previous, ...result }));
        setPcr(result?.pcr);
      } catch (e) {
        toast.error('You don`t have access to this group', {
          position: 'top-center',
          autoClose: 4000,
        });
        history.push(EpdLinks.dashboard());
      } finally {
        setIsUiBlocked(false);
      }
    }

    fetchGroupData();
  }, [copyGroupId]);

  const onChangeEpd = async (epdId: string, versionId: string, propertyName: string, val: any) => {
    if (!epdId) {
      return;
    }

    if (val instanceof File) {
      await addEpdFile(epdId, propertyName, val);
    } else {
      await addEpdProperty(epdId, versionId, propertyName, val);
    }
  };

  const onChangeEpdGroupProperty = async (groupId: string, propertyName: string, value: any) => {
    if (!groupId) {
      return;
    }
    try {
      const result = await CopyEpdService.updateEpdGroupProperty(
        groupId,
        propertyName,
        value === undefined ? null : value
      ).finally(() => {
        refetchGroupState();
      });
      setGroupData((previous) => ({ ...previous, ...result }));
    } catch {}
  };

  const addEpdProperty = async (epdId: string, versionId: string, propertyName: string, val: any) => {
    try {
      setIsUpdatingEpd(true);
      const result =
        propertyName === REMOVE_FILE
          ? await EpdService.removeEpdFile(val, versionId)
          : await EpdService.updatePropertyWithVersion(epdId, versionId, propertyName, val === undefined ? null : val);

      await updateEpdInState(result);
    } catch {
    } finally {
      setIsUpdatingEpd(false);
    }
  };

  const updateEpdInState = async (updatedEpd: EPDModel) => {
    setGroupData((previous: EpdCopyGroupWizardModel) => ({
      ...previous,
      epds: previous.epds?.map((x) => (x.id === updatedEpd.id ? updatedEpd : x)),
    }));
    await refetchGroupState();
  };

  const onConfirmUpdate = async (epdId: string, versionId: string) => {
    try {
      setIsUpdatingEpd(true);
      const result = await EpdService.confirmUpdate(epdId, versionId);
      updateEpdInState(result);
    } catch {
    } finally {
      setIsUpdatingEpd(false);
    }
  };

  const refetchGroupState = async () => {
    const result = await CopyEpdService.getEPDGroupState(copyGroupId);
    if (groupData && result) {
      setGroupData((previous) => ({ ...previous, groupState: result }));
    }
  };

  const updateGroupCertificateFilesArray = (files: FileModel[]) => {
    setGroupData((previous) => ({ ...previous, processCertificatesFiles: files }));
  };

  const refreshEPD = async (epdId: string) => {
    try {
      const result = await EpdService.getEpd(epdId);
      updateEpdInState(result);
    } catch {}
  };

  const changeOrAddFileLoading = (type: string, newLoad: boolean, prev: FileLoadingModel[]) => {
    if (prev.find((model) => model.fileType === type)) {
      return prev.map((model) =>
        model.fileType === type
          ? { ...model, loadingsCount: newLoad ? model.loadingsCount + 1 : model.loadingsCount - 1 }
          : model
      );
    } else {
      return [...prev, { fileType: type, loadingsCount: 1 }];
    }
  };

  const addEpdFile = async (epdId: string, propertyName: string, file: File) => {
    if (propertyName === 'ProductImage' && file.size > MAX_IMG_SIZE) {
      toaster({
        severity: 'error',
        summary: 'Error',
        details: t('epdWizard.messages.errorTooLargeImageFile', {
          size: Math.floor(MAX_IMG_SIZE / (1024 * 1024)),
        }) as string,
      });
      return;
    }
    const fileName = file.name;
    const fileType = file.type;

    setFileLoading((prev) => changeOrAddFileLoading(propertyName, true, prev));

    const reader = new FileReader();
    reader.onload = async () => {
      const fileBlob = reader.result as ArrayBuffer;
      if (fileBlob) {
        const blob = new Blob([new Uint8Array(fileBlob)], { type: fileType });
        if (blob.size > 10000000) {
          toast.error(t('epdWizard.messages.errorTooLargeFile') as string, {
            position: 'top-center',
          });
          return;
        }

        try {
          const result = await EpdService.addEpdFile(epdId, propertyName, fileName, blob).then();
          updateEpdInState(result);
        } catch {
          toaster({
            severity: 'error',
            summary: 'Error',
            details: t('epdWizard.messages.error') as string,
          });
        } finally {
          setFileLoading((prev) => changeOrAddFileLoading(propertyName, false, prev));
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleEPDCopyGroupCreate = async (values: any) => {
    try {
      setIsUiBlocked(true);
      const model = {
        companyId: company?.id,
        singleCopy: values.singleCopy,
        sourceEpdVersionId: values.sourceEpdVersionId,
        copyAllData: values.copyAllDataset,
        edpCopyGroupName: values.edpCopyGroupName,
        collectionId: values.collectionId,
        accountId: companyAccountId,
        practitionersIds: values.practitionersIds,
        verifierId: values.verifierId,
        pcrId: values.pcrId,
        count: values.count,
        verificationType: values.verificationType,
        preverifiedToolId: values.preverifiedToolId,
        preverifiedToolVersionId: values.preverifiedToolVersionId,
        keywordsList: values.keywordsList,
        definitionSectionsList: values.definitionSectionsList,
        productInformationSectionsList: values.productInformationSectionsList,
        machineReadableSectionsList: values.machineReadableSectionsList,
        isSectorEPD: values.isSectorEPD,
        declaredStandards: values.declaredStandards
      } as CopyEpdV2Model;

      if (values.singleCopy) {
        const resultEpdId = await CopyEpdService.copySingleEpd(model);
        if (resultEpdId) {
          history.push(EpdLinks.epd(resultEpdId));
        }
      } else {
        const resultCopyGroupId = await CopyEpdService.copyEpd(model);
        if (resultCopyGroupId && !copyGroupId) {
          setCopyGroupId(resultCopyGroupId);
          history.push(EpdLinks.editCopyEpd(resultCopyGroupId));
          setActiveStepIndex(2);
        }
      }
    } catch (e) {
      toast.error(t('copyEpd.onFetchEpds.error') as string, {
        position: 'top-center',
      });
    } finally {
      setIsUiBlocked(false);
    }
  };

  const addNewEpdCopy = async () => {
    if ((groupData?.epds?.length ?? 0) >= 50) {
      toaster({
        severity: 'warning',
        summary: 'warning',
        details: 'The max number of copies is reached. Can not add one more item.',
      });
    } else {
      try {
        setIsUiBlocked(true);
        await CopyEpdService.addNewEpdToEpdCopyGroup(groupData?.id || '');
        toaster({ severity: 'success', summary: 'Success', details: 'New EPD item has been added to the group' });
        refetchGroupData();
      } catch (error) {
        toaster({
          severity: 'error',
          summary: 'Error',
          details: 'The new EPD item was not added to the group due to unexpected error',
        });
        console.error(error);
      } finally {
        setIsUiBlocked(false);
      }
    }
  };

  const getUserRole = (userRoles: UserRolesModel) => {
    if (userRoles.isAdmin) return 'Admin' as UserRoleNames;
    if (userRoles.isLicenseeAdmin) return 'LicenseeAdmin' as UserRoleNames;
    if (userRoles.isOwner) return 'Owner' as UserRoleNames;
    if (userRoles.isPractitioner) return 'Practitioner' as UserRoleNames;
    if (userRoles.isVerifier) return 'Verifier' as UserRoleNames;
    return 'Unknown';
  };

  const steps = [
    {
      label: 'Step 1 - Select source EPD',
      id: 1,
      render: () => {
        return (
          <WizardStep1
            groupData={groupData}
            copyGroupValues={copyGroupValues}
            activeStep={activeStepIndex}
            companyName={company?.name}
            previousStep={prevCountRef.current}
            pcr={pcr}
            setPcr={setPcr}
            setCopyGroupValues={setCopyGroupValues}
            handleSubmit={handleEPDCopyGroupCreate}
          />
        );
      },
    },
    {
      label: 'Step 2 - Product information',
      id: 2,
      render: () => {
        if (isUiBlocked) {
          return <EpdListSkeleton />;
        }
        if (!groupData?.id) {
          return <NoCopies />;
        }

        let matrixValue = DefaultMatrixValue;
        try {
          matrixValue =
            AccessMatrix[groupData.verificationType!][getUserRole(groupData.userRoles)]![groupData.status!][2] ||
            DefaultMatrixValue;
        } catch (e) {
          console.error(e);
        }

        if (matrixValue?.component !== 'default') {
          return matrixValue?.component;
        }

        return (
          <WizardStep2
            groupData={groupData}
            onChangeEpd={onChangeEpd}
            onAddNewEpdCopy={addNewEpdCopy}
            refetchGroupData={refetchGroupData}
            onConfirmUpdate={onConfirmUpdate}
            isReadOnly={matrixValue?.isReadOnly}
            canUpload={matrixValue?.canUpload}
            fileLoading={fileLoading}
          />
        );
      },
    },
    {
      label: 'Step 3 - EPD verification',
      id: 3,
      render: () => {
        if (!groupData?.id) {
          return <NoCopies />;
        }

        let matrixValue = DefaultMatrixValue;
        try {
          matrixValue =
            AccessMatrix[groupData.verificationType!][getUserRole(groupData.userRoles)]![groupData.status!][3] ||
            DefaultMatrixValue;
        } catch (e) {
          console.error(e);
        }

        if (matrixValue?.component !== 'default') {
          return matrixValue?.component;
        }

        return (
          <WizardStep3
            groupData={groupData}
            onChangeEpd={onChangeEpd}
            refreshEPD={refreshEPD}
            isReadOnly={matrixValue?.isReadOnly}
            canUpload={matrixValue?.canUpload}
            fileLoading={fileLoading}
            updateGroupCertificateFilesArray={updateGroupCertificateFilesArray}
          />
        );
      },
    },
    {
      label: 'Step 4 - EPD publication',
      id: 4,
      render: () => {
        return <WizardStep4 groupData={groupData} onChangeEpdGroup={onChangeEpdGroupProperty} />;
      },
    },
  ];

  const onConfirmGroupDelete = async () => {
    try {
      await CopyEpdService.deleteEpdCopyGroup(copyGroupId);
      toaster({ severity: 'success', summary: 'Success', details: 'The group has been deleted' });
      history.push(EpdLinks.dashboard());
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD Copy group deletion error' });
      console.error(error);
    }
  };

  const onSendToVerification = async () => {
    try {
      await CopyEpdService.startGroupVerification(copyGroupId);
      toaster({ severity: 'success', summary: 'Success', details: 'The group has been sent to verification step' });
      refetchGroupData();
      setActiveStepIndex(3);
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD Copy group send for verification error' });
      console.error(error);
    }
  };

  const onConfirmCancelVerification = async () => {
    try {
      await CopyEpdService.cancelGroupVerification(copyGroupId);
      toaster({ severity: 'success', summary: 'Success', details: 'The group verification has been cancelled' });
      refetchGroupData();
      setActiveStepIndex(2);
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD Copy group cancel verification error' });
      console.error(error);
    }
  };

  const onConfirmVerification = async () => {
    try {
      await CopyEpdService.confirmGroupVerification(copyGroupId);
      toaster({ severity: 'success', summary: 'Success', details: 'The group has been verified' });
      refetchGroupData();
      ////setActiveStepIndex(4);
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD Copy group verification error' });
      console.error(error);
    }
  };

  const onRetractVerification = async () => {
    try {
      await CopyEpdService.retractGroupVerification(copyGroupId);
      toaster({ severity: 'success', summary: 'Success', details: 'The group verification has been retracted.' });
      refetchGroupData();
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD Copy group retract verification error.' });
      console.error(error);
    }
  };

  const onSendToPublish = async () => {
    try {
      await CopyEpdService.sendGroupToPublish(copyGroupId);
      toaster({ severity: 'success', summary: 'Success', details: 'The group has been sent to publish' });
      refetchGroupData();
      setActiveStepIndex(4);
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD Copy group send to publish error' });
      console.error(error);
    }
  };

  const onApproveCopyByLicenseeAdmin = async () => {
    try {
      await CopyEpdService.approveByLicenseeAdmin(copyGroupId);
      toaster({ severity: 'success', summary: 'Success', details: 'The group has been approved by Licensee' });
      refetchGroupData();
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD Copy group approve error' });
      console.error(error);
    }
  };

  const onDeclineCopyByLicenseeAdmin = async () => {
    try {
      await CopyEpdService.declineGroupByLicenseeAdmin(copyGroupId, declinePublicationText);
      toaster({ severity: 'success', summary: 'Success', details: 'The group has been declined' });
      refetchGroupData();
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD Copy group decline error' });
      console.error(error);
    }
  };

  const onConfirmDeclinePublication = async () => {
    try {
      await CopyEpdService.declineGroup(copyGroupId, declinePublicationText);
      toaster({ severity: 'success', summary: 'Success', details: 'The group has been declined' });
      refetchGroupData();
      ////setActiveStepIndex(4);
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD Copy group decline error' });
      console.error(error);
    }
  };

  const onPublish = async () => {
    try {
      await CopyEpdService.publishGroup(copyGroupId);
      toaster({ severity: 'success', summary: 'Success', details: 'The group has been published' });
      history.push(EpdLinks.dashboard());
    } catch (error) {
      toaster({ severity: 'error', summary: 'Error', details: 'EPD Copy group publish error' });
      console.error(error);
    }
  };

  return {
    companyAccountId,
    company,
    t,
    history,
    copyGroupId,
    setCopyGroupId,
    groupData,
    setGroupData,
    steps,
    activeStepIndex,
    setActiveStepIndex,
    helpButtonRef,
    onSendToVerification,
    onConfirmCancelVerification,
    onSendToPublish,
    onConfirmVerification,
    onRetractVerification,
    onPublish,
    onConfirmGroupDelete,
    isExplanationVisible,
    setIsExplanationVisible,
    declinePublicationText,
    setDeclinePublicationText,
    setDeclinePublicationConfirmed,
    isUiBlocked,
    setIsUiBlocked,
    isUpdatingEpd,
    onApproveCopyByLicenseeAdmin,
    setDeclinePublicationByLicenseeAdminConfirmed,
  };
};

export default useCopyWizardLogic;
