import DropDown from 'components/company/DropDown';
import CreateEpd from 'components/dashboard/CreateEpd';
import InviteConfirmationDialog from 'components/dialogs/InviteConfirmationDialog';
import FreshworksWidget from 'components/widgets/FreshworksWidget';
import { ProcessStatus, defaultLicensee } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import controlIcon from 'images/icons/svg/controls.svg';
import filterIcon from 'images/icons/svg/funnel.svg';
import helpIcon from 'images/icons/svg/warning.svg';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from 'services/AuthService';
import CompanyService from 'services/CompanyService';
import {
  AccountModel,
  CompanyModel,
  EPDModel,
  EPDStatusDisplayName,
  EPDStatusDisplayNameToEPDCopyGroupStatusMapping,
  InvitationModel,
  InvoiceSummaryModel,
} from 'services/EpdClient';
import FileService from 'services/FileService';
import InvoiceService from 'services/InvoiceService';
import MembershipService from 'services/MembershipService';
import { useEpdsGroupsListDashboard, useEpdsListDashboard } from 'services/api/queries';
import { Container, InputSmallIcon, MainView } from 'styles/Styles.styled';

import { UseQueryResult } from '@tanstack/react-query';

import { AccountSwitcher } from './AccountSwitcher';
import EpdCard from './EpdCard';
import EpdGroupCard from './EpdGroupCard';
import NoCards from './NoCards';
import Sidebox from './Sidebox';
import Welcome from './Welcome';
import { CardContainer, CardsEmpty, FilterContainer, HeadingArea, InfoBox, InputLabel } from './styles';

const allStatusFilters = Object.values(EPDStatusDisplayName);

const Dashboard: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [account, setAccount] = useState<AccountModel | undefined>({});
  const [companies, setCompanies] = useState<CompanyModel[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<EPDStatusDisplayName[]>([]);
  const [companyStatus, setCompanyStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [isAdminLicenseeCompanies, setIsAdminLicenseeCompaies] = useState<boolean>(false);
  const { companyId, company, companyAccountId, licensee } = useContext(CompanyContext);
  const [invitations, setInvitations] = useState<InvitationModel[]>();
  const epdsQuery = useEpdsListDashboard(companyId, companyAccountId);
  const epdsGroupsQuery = useEpdsGroupsListDashboard(companyId, companyAccountId);
  const status =
    companyId === undefined ? ProcessStatus.Fetching : reduceProcessingStatusFromQueries([epdsQuery, epdsGroupsQuery]);

  const handleFilter = (e: React.FormEvent<HTMLInputElement>) => {
    setFilter(e.currentTarget.value);
  };

  const [invoiceSummary, setInvoiceSummary] = useState<InvoiceSummaryModel>();
  const newInvoices = invoiceSummary?.drafts?.length ?? 0;

  const fetchInvitations = async () => {
    const result = await MembershipService.getInvitationsByUser();
    setInvitations(result);
  };

  useEffect(() => {
    fetchInvitations();
  }, []);

  useEffect(() => {
    const getInvoiceSummary = async () => {
      if (companyAccountId) {
        const summary = await InvoiceService.getInvoiceSummary(companyAccountId);
        setInvoiceSummary(summary);
      }
    };

    getInvoiceSummary();
  }, [companyAccountId]);

  useEffect(() => {
    const feachIsAdminLicenseeInCompany = async () => {
      var result = await AuthService.isAdminLicenseeInCompany(company?.id || '');
      setIsAdminLicenseeCompaies(result);
    };

    feachIsAdminLicenseeInCompany();
  }, [company]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchCompanies = async () => {
      setCompanyStatus(ProcessStatus.Fetching);
      const companiesResult = await CompanyService.getCompanies();
      setCompanies(companiesResult);
    };

    fetchCompanies();
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const epds = (() => {
    if (!epdsQuery.data) {
      return [];
    }
    let filteredEpds = epdsQuery.data.filter((x) => x.name?.toLowerCase().includes(filter.toLocaleLowerCase()));
    if (typeFilter?.length) {
      filteredEpds = filteredEpds.filter((x) => x.epdState && typeFilter.includes(x.epdState.statusDisplayName));
    }
    return filteredEpds;
  })();

  const epdsGroups = (() => {
    if (!epdsGroupsQuery.data) {
      return [];
    }
    let filteredEpdGroups = epdsGroupsQuery.data.filter((x) => x.name?.toLowerCase().includes(filter.toLocaleLowerCase()));
    if (typeFilter?.length) {
      const copyGroupFilter = typeFilter.map((filter) => EPDStatusDisplayNameToEPDCopyGroupStatusMapping[filter]);
      filteredEpdGroups = filteredEpdGroups.filter((x) => x.status && copyGroupFilter.includes(x.status));
    }
    return filteredEpdGroups;
  })();

  const toggleTypeFilter = (value: EPDStatusDisplayName) => {
    if (typeFilter.includes(value)) {
      setTypeFilter(typeFilter.filter((x) => x !== value));
    } else {
      setTypeFilter(typeFilter.concat(value));
    }
  };

  const hasCompanies = companies && companies.length > 0 && companyStatus !== ProcessStatus.Error;
  const hasAccounts = hasCompanies && company?.accounts && company?.accounts.length > 1;
  const canCreateEpd =
    AuthService.isAdmin() ||
    AuthService.isCompanyOwner(company?.id || '') ||
    (AuthService.isLicenseeAdmin() && isAdminLicenseeCompanies);
  const getCoverImage = (epd: EPDModel) => {
    const coverId = epd?.covers && epd?.covers?.length > 0 && epd.covers[0].id;
    return coverId ? FileService.getImageUrl(coverId) : undefined;
  };

  return (
    <Container style={{ flexWrap: 'nowrap', position: 'relative' }}>
      <MainView>
        {invitations?.map((invitation) => (
          <InviteConfirmationDialog closable={false} visible={true} invitation={invitation} />
        ))}
        <HeadingArea>
          <FilterContainer>
            {epdsQuery.data && epdsQuery.data.length > 0 && (
              <>
                <div>
                  <InputSmallIcon
                    style={{ backgroundImage: `url(${filterIcon})` }}
                    type="text"
                    placeholder={t('epdDashboard.filter.title')}
                    value={filter}
                    onChange={handleFilter}
                  />
                </div>
                <div style={{ position: 'relative' }}>
                  <DropDown
                    icon={controlIcon}
                    heading={t('epdDashboard.status.title')}
                    content={t('epdDashboard.tooltips.EPDStatus')}
                  >
                    <div style={{ padding: '1rem 0' }}>
                      {allStatusFilters.map((status) => (
                        <InputLabel key={status}>
                          {t(`epdWizard.epdState.status.${status}`)}
                          <input
                            type="checkbox"
                            value={status}
                            checked={typeFilter.includes(status)}
                            onChange={() => toggleTypeFilter(status)}
                          />
                        </InputLabel>
                      ))}
                    </div>
                  </DropDown>
                </div>
              </>
            )}
            {hasAccounts && <AccountSwitcher company={company} account={account} />}
          </FilterContainer>
          {hasCompanies && canCreateEpd && <CreateEpd anyEpd={epds?.length > 0} />}
        </HeadingArea>

        {newInvoices > 0 ? (
          <InfoBox>
            <img src={helpIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
            <div>
              {t('epdDashboard.hasNewInvoices.info_1')}
              <br />
              {t('epdDashboard.hasNewInvoices.info_2')}
            </div>
          </InfoBox>
        ) : null}
        <CardContainer>
          {!hasCompanies && !company?.id && status !== ProcessStatus.Fetching && <Welcome />}
          {status === ProcessStatus.Fetching && (
            <>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3 mr-3"></Skeleton>
              <Skeleton width="23%" height="150px" className="mb-3"></Skeleton>
            </>
          )}
          {status === ProcessStatus.Error && (
            <CardsEmpty>
              <h3>{t('epdDashboard.onFetchEpds.error')}</h3>
            </CardsEmpty>
          )}
          {status === ProcessStatus.Success &&
            company?.id &&
            (epds?.length > 0 || epdsGroups?.length > 0 ? (
              <>
                {epdsGroups?.map((epdGroup) => (
                  <EpdGroupCard key={epdGroup.id} epdGroup={epdGroup} background={null} />
                ))}
                {epds?.map((epd) => (
                  <EpdCard key={epd.id} epd={epd} background={getCoverImage(epd)} />
                ))}
              </>
            ) : (
              <NoCards anyExist={!!epdsQuery.data && epdsQuery.data.length > 0} />
            ))}
        </CardContainer>
      </MainView>
      <Sidebox
        allEpds={epdsQuery?.data ?? []}
        allCopyGroups={epdsGroupsQuery?.data ?? []}
        accountId={companyAccountId}
        companyId={company?.id || ''}
        licenseeSupportEmail={licensee?.supportEmail ?? defaultLicensee.supportEmail}
      />
      <FreshworksWidget />
    </Container>
  );
};

export default Dashboard;

function reduceProcessingStatusFromQueries(queries: UseQueryResult[]): ProcessStatus {
  const isFailed = queries.some((q) => q.isError);
  if (isFailed) {
    return ProcessStatus.Error;
  }
  const isLoading = queries.some((q) => q.isLoading);
  if (isLoading) {
    return ProcessStatus.Fetching;
  }
  return ProcessStatus.Success;
}
