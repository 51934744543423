export const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:44380';

export const QUERY_KEYS = {
  EPD_LATEST_VERSION: 'epdLatestVersion',
  EPDS_LIST_DASHBOARD: 'epdsListDashboard',
  EPDS_GROUPS_LIST_DASHBOARD: 'epdsGroupsListDashboard',
  EPD_FORMAT: 'epdFormat',
  EPD_DEFINITION: 'epdDefinition',
  EPD_ACTIONS_STATES: 'epdActionStates',
  EPD_ADDITIONAL_INFORMATION: 'epdAdditionalInformation',
  EPD_ADDITIONAL_DOCUMENTS: 'epdAdditionalDocuments',
  EPD_ORGANIZATION_INFORMATION: 'epdOrganizationInformation',
  EPD_ORGANIZATION_INFORMATION_ADDRESS: 'epdOrganizationInformationAddress',
  EPD_PRODUCT_INFORMATION: 'epdProductInformation',
  UN_CPC_CODES: 'cpcCodes',
  EPD_COVER_PAGE_INFORMATION: 'epdCoverPageInformation',
  EPD_PCR_INFORMATION: 'epdPCRInformation',
  EPD_LCA_SUPPORT: 'epdLcaSupport',
  EPD_THIRD_PARTY_VERIFICATION: 'epdThirdPartyVerification',
  EPD_SYSTEM_BOUNDARY: 'epdSystemBoundary',
  EPD_DICTIONARY_CATALOG: 'epdDictionaryCatalog',
  EPD_DICTIONARY_VALUES: 'epdDictionaryValues',
  EPD_COLLECTIONS_LOOKUP: 'epdCollectionsLookup',
  EPD_COMPANY_USERS_LOOKUP: 'epdCompanyUsersLookup',
  EPD_COMPANY_VERIFIERS: 'epdCompanyVerifiers',
  EPD_LCA_RESULTS: 'epdLcaResults',
  EPD_CPCR_SPECIFIC_DATA: 'epdCpcrSpecificData',
  EPD_LCA_RESULTS_ADDITIONAL: 'epdLcaResultsAdditional',
  EPD_LCA_RESULTS_ADDITIONAL_ACRONYMS: 'epdLcaResultsAdditionalAcronyms',
  EPD_LCA_CUSTOM_RESULTS: 'epdLcaCustomResults',
  EPD_LCA_CUSTOM_RESULTS_DATA: 'epdLcaCustomResultsData',
  EPD_LCA_DECLARED_MODULES: 'epdLcaDeclaredModules',
  EPD_LCA_TRANSPORTATION: 'epdLcaTransportation',
  EPD_PRODUCT_COMPONENTS: 'epdProductComponents',
  EPD_PRODUCT_PACKAGING_MATERIALS: 'epdProductPackagingMaterials',
  EPD_PRODUCT_PANEL_CONTENT: 'epdProductPanelContent',
  EPD_PRODUCT_DANGER_MATERIALS: 'epdProductDangerMaterials',
  DANGER_MATERIALS_LOOKUP: 'dangerMaterialsLookup',
  EPD_CO2_UPTAKE_ASSOCIATION: 'epdCO2UptakeAssociation',
  EPD_DECLARATIONS_OF_CO2: 'epdDeclarationsOfCO2',
  EPD_LCA_SPECIFICATION: 'epdLCASpecification',
  EPD_INFRASTRUCTURE_AND_CAPITAL_GOODS: 'epdInfrastructureAndCapitalGoods',
  EPD_REFERENCE_FLOW: 'epdReferenceFlow',
  EPD_CONVERSION_FACTOR: 'epdConversionFactor',
  REFERENCE_PACKAGE_VERSIONS_15804: 'referencePackageVersions15804',
  EPD_DATA_SOURCES: 'epdDataSources',
  EPD_DATA_SOURCE_OPTIONS: 'epdDataSourceOptions',
  EPD_LCA_SOFTWARE_OPTIONS: 'lcaSoftwareOptions',
  EPD_VERIFICATION_REPORT_INFO: 'epdVerificationReportInfo',
  EPD_SCRAP_INPUTS: 'epdScrapInputs',
  EPD_SHARE_OF_TOTAL_SCRAP_INPUT: 'epdShareOfTotalScrapInput',
  EPD_ELECTRICITY: 'epdElectricity',
  EPD_SCENARIOS: 'epdScenarios',
  EPD_SCENARIO_UNITS: 'epdScenarioUnits',
  GEOGRAPHICAL_SCOPE_LOOKUP: 'geographicalScopeLookup',
  MATERIAL_PROPERTIES_LOOKUP: 'materialPropertiesLookup',
  EPD_MODULE: 'EpdModule',
  TAG_SOURCE: 'tagSource',
  FOUNDATION_COUNTRIES: 'countries',
  LANGUAGES: 'languages',
  DOCUMENT_TYPES: 'documentTypes',
  BEST_AND_WORST_PRODUCT: 'bestAndWorstProducts',
  EPD_DATA_QUALITY_ASSESSMENT_AND_REFERENCE_YEARS: 'EpdDataQualityAssessmentAndReferenceYears',
  EPD_QR_CODE: 'epdQRCode',
  EPD_QR_CODE_FOR_PDF: 'epdQRCodeForPDF',
};

export const INDICATOR_GROUP_CODES = {
  CORE15804: 'CORE15804',
  ADDITIONALMANDATORY: 'ADDITIONALMANDATORY',
  ADDITIONALVOLUNTARY15804: 'ADDITIONALVOLUNTARY15804',
  ADDITIONALVOLUNTARY: 'ADDITIONALVOLUNTARY',
  RESOURCE15804: 'RESOURCE15804',
  WASTE15804: 'WASTE15804',
  OUTPUTFLOW15804: 'OUTPUTFLOW15804',
};

export const EPD_DICTIONARY_CODES = {
  EPD_CLASSIFICATION: 'EPDClassification',
  PRODUCT_TYPE: 'ProductType',
  SYSTEM_BOUNDARY_DESCRIPTION: 'SystemBoundaryDescription',
  CONFORMITY_STANDARDS: 'ConformityStandards',
  UNIT_TYPE: 'UnitType',
  CLASSIFICATION_CODE: 'ProductClassificationCode',
  EPD_BASED_ON_UNIT: 'EPDBasedOnUnit',
  REFERENCE_FLOW_UNIT: 'ReferenceFlowUnit',
  SCRAP_INPUT_CONTRIBUTION_LEVEL: 'ScrapInputsContributionLevel',
  ADDITIONAL_LCA_RESULTS_TYPE: 'AdditionalLcaResultsType',
  TYPE_OF_ELECTRICITY_MIX: 'TypeOfElectricityMix',
  PRE_VERIFIED_TOOL_TYPE: 'ToolType',
  BIOGENIC_CARBON_CONTENT_UNITS: 'BiogenicCarbonContentUnits',
};
