import { useState } from 'react';
import { EPDModel } from 'services/EpdClient';
import { Option } from 'types/types';
import EditDefinitionDialog from '../../dialogs/EditDefinitionDialog';
import EpdDetailsGrid from '../EpdDetailsGrid';
import useConformityStandards from '../../wizard/useConformityStandards';

type TProps = {
  epd: EPDModel;
  onChangeEpd: any;
  isReadOnly: boolean;
  title: string | undefined;
  setTitle: any;
};

const EpdDefinition = ({ epd, onChangeEpd, isReadOnly, title, setTitle }: TProps) => {
  const [isDefinitionEditOpened, setIsDefinitionEditOpened] = useState<boolean>(false);
  const conformityStandardsOptions = useConformityStandards(epd?.pcr);

  const { declaredStandards } = epd || {};

  function getSelectedStandards(declaredStandards: string | undefined, conformityStandardsOptions: Option[] | undefined) {
    if (!declaredStandards) return '';

    const standardsArray = declaredStandards?.split(',');

    return standardsArray
      ?.map((x) => conformityStandardsOptions?.find((y) => y.value === x))
      .map((y) => y?.label)
      .join('; ');
  }

  return (
    <>
      <EpdDetailsGrid
        headerLabel={'EPD definition'}
        isReadOnly={isReadOnly}
        data={[
          { key: 'UUID of data set', value: epd.id }, // TODO: headId
          { key: 'LCA Practitioner(s)', value: epd.epdDevelopers?.map((x) => x.contactName).join('; ') },
          { key: 'EPD Verifier', value: epd.epdVerifier?.displayName },
          { key: 'PCR', value: epd.pcr?.fullName },
          {
            key: 'Statement of conformity with ISO',
            value: getSelectedStandards(declaredStandards, conformityStandardsOptions),
          },
          { key: 'Sector EPD', value: epd.isSectorEPD ? 'Yes' : 'No' },
        ]}
        onEdit={() => setIsDefinitionEditOpened(true)}
      />
      <EditDefinitionDialog
        isOpened={isDefinitionEditOpened}
        epd={epd}
        onHide={() => setIsDefinitionEditOpened(false)}
        onChangeEpd={onChangeEpd}
        title={title}
        setTitle={setTitle}
        conformityStandardsOptions={conformityStandardsOptions}
      />
    </>
  );
};

export default EpdDefinition;
