import WizardCheckbox from 'components/v2/epd-wizard-inputs/WizardCheckbox';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import WizardTextInputControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextInputControlled';
import InputFormField from 'components/v2/form/InputFormField';
import SelectDataField from 'components/v2/form/SelectDataField';
import { HelpBox } from 'components/v2/help-boxes';
import { EPDModel } from 'services/EpdClient';
import { CheckBoxPanel, FieldContainer, FieldPanelInput } from 'styles/v2/Styles.styled';
import { Option } from 'types/types';
import EditDialog from './EditDialog';

type TProps = {
  isOpened: boolean;
  onHide: any;
  epd?: EPDModel;
  onChangeEpd: any;
  title: string | undefined;
  setTitle: any;
  conformityStandardsOptions?: Option[];
};

const EditDefinitionDialog = ({
  isOpened,
  onHide,
  epd,
  onChangeEpd,
  title,
  setTitle,
  conformityStandardsOptions,
}: TProps) => {
  const { declaredStandards } = epd || {};

  function getSelectedStandards(declaredStandards: string | undefined, conformityStandardsOptions: Option[] | undefined) {
    if (!declaredStandards) return [];

    const standardsArray = declaredStandards?.split(',');

    return standardsArray?.map((x) => conformityStandardsOptions?.find((y) => y.value === x));
  }

  return (
    <EditDialog header="EPD Definition" isOpened={isOpened} onHide={onHide}>
      <HelpBox>
        Please fill-in the required information below. The filled data will be auto saved and available in the table.
      </HelpBox>
      <FieldPanelInput>
        <FieldContainer>
          <WizardTextInputComponent
            label="UUID of data set"
            name="uuid"
            tooltip="Automatically generated universally unique identifier of this data set."
            disabled
            required
            value={epd?.id}
            onChanged={onChangeEpd}
          />
        </FieldContainer>
        <FieldContainer>
          <WizardTextInputControlledComponent
            label="EPD name"
            tooltip="General descriptive and specifying name of the product/system."
            tooltipPosition={'right'}
            required
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
            onChanged={onChangeEpd}
            name="title"
            maxLength={150}
          />
        </FieldContainer>
        <FieldContainer>
          <SelectDataField
            label="LCA practitioner"
            isMulti
            tooltip="The person accountable for the underlying Life Cycle Assessment."
            name="practitionersIds"
            value={epd?.epdDevelopers?.map((x) => {
              return { value: x.contactUserId, label: x.contactName };
            })}
            options={epd?.epdDevelopers?.map((x) => {
              return { value: x.contactUserId, label: x.contactName };
            })}
            disabled
            components={{ DropdownIndicator: null }}
          />
        </FieldContainer>
        <FieldContainer>
          <SelectDataField
            label="Epd verifier"
            tooltip="Individual responsible for validating or confirming various aspects of the product lifecycle."
            tooltipPosition="right | bottom"
            name="verifierId"
            value={{ value: epd?.epdVerifier?.id, label: epd?.epdVerifier?.displayName }}
            options={[{ value: epd?.epdVerifier?.id, label: epd?.epdVerifier?.displayName }]}
            disabled
            components={{ DropdownIndicator: null }}
          />
        </FieldContainer>
        <FieldContainer>
          <InputFormField
            label="PCR"
            required
            name="pcr"
            value={epd?.pcr?.fullName}
            tooltip="Product Category Rules"
            tooltipPosition="right"
            disabled
          />
        </FieldContainer>
        <FieldContainer>
          <SelectDataField
            label="Statement of conformity with ISO"
            isMulti
            tooltip="Select the standards with which the EPD is compliant."
            name="declaredStandards"
            value={getSelectedStandards(declaredStandards, conformityStandardsOptions)}
            options={getSelectedStandards(declaredStandards, conformityStandardsOptions)}
            disabled
            components={{ DropdownIndicator: null }}
          />
        </FieldContainer>
        <FieldContainer>
          <CheckBoxPanel>
            <WizardCheckbox
              label={'Sector EPD'}
              name={'isSectorEpd'}
              value={epd?.isSectorEPD as boolean}
              onChanged={onChangeEpd}
              tooltip="The International EPD® System allows for an industry association to develop an EPD in the form of a sector EPD. A sector EPD declares the average product of multiple companies in a clearly defined sector and/or geographical area. Any communication of the results from a sector EPD should contain the information that the results are based on averages obtained from the sector as defined in the EPD. The communication shall not claim that the sector EPD results are representative for a certain manufacturer or its product."
              tooltipPosition="right | top"
            />
          </CheckBoxPanel>
        </FieldContainer>
      </FieldPanelInput>
    </EditDialog>
  );
};
export default EditDefinitionDialog;
