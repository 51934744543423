import InformationalDialog from 'components/dialogs/InformationalDialog';
import SelectInput from 'components/form/SelectInput';
import TextInput from 'components/form/TextInput';
import TextAreaInput from 'components/form/TextAreaInput';
import { EUCountries, REG_URL, Role } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Field, Formik } from 'formik';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import CompanyService from 'services/CompanyService';
import CountriesService from 'services/CountriesService';
import { CompanySize, CountryModel, CreateCompanyModel, LoginModel } from 'services/EpdClient';
import styled from 'styled-components';
import {
  A,
  Button,
  Container,
  ErrorText,
  FormMode,
  StyledForm,
  StyledLink,
  SuccessText,
  Text,
} from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';
import { FieldPanel } from "../../styles/v2/Styles.styled";
import { YES_NO, YesNoDataSource } from "../../components/v2/compiler/constants";

const RegisterCompany: FunctionComponent = () => {
  const { company, licensee, changeCompany } = useContext(CompanyContext);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [showCreatedLicenseeCompanyDialog, setShowCreatedLicenseeCompanyDialog] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>();
  const [countries, setCountries] = useState<CountryModel[]>([]);
  // Below would be used for marketplace selection:
  // const [isGlobalMarketplaceSelected, setIsGlobalMarketplaceSelected] = useState(false);
  // const [countriesDs, setCountriesDs] = useState<Option[]>([]);
  const companyTypes = [
      { id: Role.EPDOwner, value: 'EPD Owner' },
      { id: Role.EPDDeveloper, value: 'LCA Practitioner' }
  ];
  const { t } = useTranslation();
  
  const createInitialValues: CreateCompanyModel = {
    name: '',
    roleId: Role.EPDOwner,
    website: '',
    countryId: '',
    vat: '',
    companySize: undefined,
    email: '',
    isOnlyEmail: false,
    addressLine: '',
    addressLineSecond: '',
    addressCity: '',
    addressZipCode: '',
    isCompanyAddressUsedForInvoice: false,
    invoiceCountryId: '',
    invoiceAddressLine: '',
    invoiceAddressLineSecond: '',
    invoiceAddressCity: '',
    invoiceAddressZipCode: '',
    invoiceEmail: '',
    invoiceCcEmail: '',
    ordersEmail: '',
    remindersEmail: '',
    isInvoiceUniquePurchaseOrderNumber: YES_NO.NO,
    isSpecialInvoiceNeeds: YES_NO.NO,
    specialInvoiceNeeds: '',
    invoiceReference: ''
  };

  const createSchema = Yup.object({
    name: Yup.string().required(t('messages.required')),
    roleId: Yup.string(),
    website: Yup.string().matches(REG_URL, t('messages.invalidUrl')),
    countryId: (Yup.string() as any).when('roleId', {
      is: (roleId: string) => roleId === Role.EPDOwner,
      then: Yup.string().required(t('messages.required')),
    }),
    vat: (Yup.string() as any).when(['countryId', 'roleId'], {
      is: (countryId: string, roleId: string) =>
        roleId === Role.EPDOwner &&
        countryId &&
        EUCountries.includes(countries.find((x) => x.id === countryId)?.alpha2Code || ''),
      then: Yup.string().required(t('messages.requiredVatInEU')),
    }),
    companySize: (Yup.string() as any).when('roleId', {
      is: (roleId: string | undefined) => roleId === Role.EPDOwner,
      then: Yup.string().required(t('messages.required')),
    }),
    
    email: Yup.string().email(t('messages.invalidEmail')).required(t('messages.required')),
    addressLine: Yup.string().required(t('messages.required')),
    addressCity: Yup.string().required(t('messages.required')),
    addressZipCode: Yup.string().required(t('messages.required')),
    
    invoiceAddressLine: (Yup.string() as any).when('roleId', {
      is: (roleId: string | undefined) => roleId === Role.EPDOwner,
      then: Yup.string().required(t('messages.required')),
    }),
    invoiceAddressCity: (Yup.string() as any).when('roleId', {
      is: (roleId: string | undefined) => roleId === Role.EPDOwner,
      then: Yup.string().required(t('messages.required')),
    }),
    invoiceAddressZipCode: (Yup.string() as any).when('roleId', {
      is: (roleId: string | undefined) => roleId === Role.EPDOwner,
      then: Yup.string().required(t('messages.required')),
    }),
    invoiceCountryId: (Yup.string() as any).when('roleId', {
      is: (roleId: string | undefined) => roleId === Role.EPDOwner,
      then: Yup.string().required(t('messages.required')),
    }),
    ordersEmail: (Yup.string() as any).when('roleId', {
      is: (roleId: string | undefined) => roleId === Role.EPDOwner,
      then: Yup.string().required(t('messages.required')),
    }),
    remindersEmail: (Yup.string() as any).when('roleId', {
      is: (roleId: string | undefined) => roleId === Role.EPDOwner,
      then: Yup.string().required(t('messages.required')),
    }),
    invoiceEmail: (Yup.string() as any).when('roleId', {
      is: (roleId: string | undefined) => roleId === Role.EPDOwner,
      then: Yup.string().required(t('messages.required')),
    }),
    specialInvoiceNeeds: (Yup.string() as any).when(['roleId', 'isSpecialInvoiceNeeds'], {
      is: (roleId: string | undefined, isSpecialInvoiceNeeds: string | undefined) =>
          roleId === Role.EPDOwner && isSpecialInvoiceNeeds === YES_NO.YES,
      then: Yup.string().required(t('messages.required')),
    }),
  });
  
  const createCompany = async (values: CreateCompanyModel) => {
    setServerError('');
    if (values.roleId === Role.EPDDeveloper) {
      values.companySize = undefined;
      values.countryId = undefined;
      values.vat = undefined;
      values.invoiceAddressCity = undefined;
      values.invoiceCountryId = undefined;
      values.invoiceAddressLine = undefined;
      values.invoiceAddressLineSecond = undefined;
      values.invoiceAddressZipCode = undefined;
      values.invoiceCcEmail = undefined;
      values.invoiceEmail = undefined;
      values.invoiceReference = undefined;
      values.isInvoiceUniquePurchaseOrderNumber = undefined;
      values.isSpecialInvoiceNeeds = undefined;
      values.ordersEmail = undefined;
      values.remindersEmail = undefined;
      values.specialInvoiceNeeds = undefined;
    }
    try {
      const result = await CompanyService.createCompany(values);
      setSuccess(true);

      const currentUser = AuthService.getUser().email;
      if (currentUser) {
        changeCompany?.(result.id || '');
        await AuthService.relogin({ userName: currentUser, password: '' } as LoginModel);
      }
    } catch (e) {
      const error = PoorMansError(e);
      setServerError(error ? error : t('errors.wrong'));
    }
  };

  useEffect(() => {
    async function fetch() {
      const result = await CountriesService.getCountries();
      const emptyCountry: CountryModel = { id: undefined, name: '', alpha2Code: '' };
      setCountries([emptyCountry].concat(result));
    }
    fetch();
  }, []);

  useEffect(() => {
    if (success && licensee) {
      setShowCreatedLicenseeCompanyDialog(true);
    }
  }, [licensee]);

  return (
    <>
      <Container>
        <Text>{t('organization.register.description')}</Text>
          <Formik
            initialValues={createInitialValues}
            validationSchema={createSchema}
            onSubmit={(values) => createCompany(values)}
          >
            
            {({ isSubmitting, values }) => (
              <StyledForm mode={FormMode.Inline}>
                <Text style={{ fontWeight: 'bold', marginTop: 30 }}> {t('organization.register.general')}</Text>
                <FieldPanel>
                  <Grid>
                    <SelectInput required label={t('organization.type')} name="roleId">
                      {companyTypes.map((c) => (
                          <option value={c.id} key={c.id}>
                            {c.value}
                          </option>
                      ))}
                    </SelectInput>
                  </Grid>

                  <Grid>
                    <TextInput required label={t('organization.name')} name="name" placeholder={t('organization.name')}/>
                  </Grid>

                  {values.roleId === Role.EPDOwner && (
                      <>
                        <Grid>
                          <SelectInput required label={t('organizationSize')} name="companySize">
                            {[''].concat(Object.values(CompanySize)).map((x) => (
                                <option value={x} key={x}>
                                  {x}
                                </option>
                            ))}
                          </SelectInput>
                        </Grid>
                        <Grid>
                          <TextInput required label={t('website')} name="website" placeholder="http://www.environdec.com"/>
                        </Grid>
                        <Grid>
                          <TextInput name="email" label={t('email')} placeholder={t('placeholder.email')}/>
                          <CheckBoxGroup>
                            <Field type="checkbox" name="isOnlyEmail" id="isOnlyEmailId"/>
                            <label htmlFor="isOnlyEmailId">{t(`organization.register.isOnlyEmail`)}</label>
                          </CheckBoxGroup>
                        </Grid>
                      </>
                  )}

                  {values.roleId === Role.EPDDeveloper && (
                      <>
                        <Grid>
                          <TextInput label={t('website')} name="website" placeholder="http://www.environdec.com" />
                        </Grid>
                        <Grid>
                          {/*Handle Marketplace field*/}
                          {/*<LabelBox>*/}
                          {/*  <FixedLabel>Marketplace</FixedLabel>{' '}*/}
                          {/*</LabelBox>*/}
                          {/*<ValueBox>*/}
                          {/*  <StyledReactSelect*/}
                          {/*      defaultValue={countries}*/}
                          {/*      name="countries"*/}
                          {/*      isMulti*/}
                          {/*      styles={editAdminSelectStyles}*/}
                          {/*      options={countriesDs}*/}
                          {/*      onChange={handleChangeMarketplace}*/}
                          {/*      isClearable*/}
                          {/*      shouldMenuOpen={!isGlobalMarketplaceSelected}*/}
                          {/*  />*/}
                          {/*</ValueBox>*/}
                          <SelectInput required label={t('organizationSize')} name="companySize">
                            {[''].concat(Object.values(CompanySize)).map((x) => (
                                <option value={x} key={x}>
                                  {x}
                                </option>
                            ))}
                          </SelectInput>
                        </Grid>
                      </>
                  )}
                  
                </FieldPanel>
                {/* Organization address */}
                <Text style={{ fontWeight: 'bold', marginTop: 30 }}>Organization address</Text>
                <FieldPanel>
                  <Grid>
                    <SelectInput required label={t('country')} name="countryId">
                      {countries.map((c) => (
                          <option value={c.id} key={c.name}>
                            {c.name}
                          </option>
                      ))}
                    </SelectInput>
                  </Grid>
                  <Grid>
                    <TextInput required label={t('organizationAddressZipCode')} name="addressZipCode"/>
                  </Grid>
                  <Grid>
                    <TextInput required label={t('organizationAddressCity')} name="addressCity" />
                  </Grid>
                  <Grid>
                    <TextInput required label={t('placeholder.address.line1')} name="addressLine"/>
                    <CheckBoxGroup>
                      <Field type="checkbox" name="isCompanyAddressUsedForInvoice" id="isCompanyAddressUsedForInvoiceId"/>
                      <label htmlFor="isCompanyAddressUsedForInvoiceId">{t(`organization.register.useCompanyAddressForInvoicing`)}</label>
                    </CheckBoxGroup>
                  </Grid>
                  <Grid>
                    <TextInput label={t('placeholder.address.line2')} name="addressLineSecond"/>
                  </Grid>
                </FieldPanel>

                {values.roleId === Role.EPDOwner && (
                    <>
                      <Text style={{ fontWeight: 'bold', marginTop: 30 }}>{t('invoiceInfo.title')}</Text>
                      <FieldPanel>
                        <Grid>
                          <SelectInput required label={t('invoiceAddressCountry')} name="invoiceCountryId">
                            {countries.map((c) => (
                                <option value={c.id} key={c.name}>
                                  {c.name}
                                </option>
                            ))}
                          </SelectInput>
                        </Grid>
                        <Grid>
                          <TextInput required label={t('invoiceAddressZipCode')} name="invoiceAddressZipCode"/>
                        </Grid>
                        <Grid>
                          <TextInput required label={t('invoiceAddressCity')} name="invoiceAddressCity"/>
                        </Grid>
                        <Grid>
                          <TextInput required label={t('invoiceAddressLine')} name="invoiceAddressLine"/>
                        </Grid>
                        <Grid>
                          <TextInput label={t('invoiceAddressLineSecond')} name="invoiceAddressLineSecond"/>
                        </Grid>
                        <Grid>
                          <TextInput required label={t('invoicingEmail')} name="invoiceEmail"/>
                        </Grid>
                        <Grid>
                          <TextInput label={t('organization.register.invoiceCcEmail')} name="invoiceCcEmail"/>
                        </Grid>
                        <Grid>
                          <TextInput required label={t('invoiceOrderingEmail')} name="ordersEmail"/>
                        </Grid>
                        <Grid>
                          <TextInput required label={t('invoiceRemindingEmail')} name="remindersEmail"/>
                        </Grid>
                        <Grid>
                          <TextInput required label={t('vat')} name="vat"/>
                        </Grid>

                        <Grid>
                          <SelectInput required label={t('organization.register.isSpecialInvoice')} name="isSpecialInvoiceNeeds">
                            {YesNoDataSource.map((c) => (
                                <option value={c.value} key={c.value}>
                                  {c.label}
                                </option>
                            ))}
                          </SelectInput>
                        </Grid>
                        <Grid>
                          <SelectInput required label={t('organization.register.isUniqueInvoicePurchaseOrderNumberRequired')} name="isInvoiceUniquePurchaseOrderNumber">
                            {YesNoDataSource.map((c) => (
                                <option value={c.value} key={c.value}>
                                  {c.label}
                                </option>
                            ))}
                          </SelectInput>
                        </Grid>
                        {values.isSpecialInvoiceNeeds === YES_NO.YES && (
                          <>
                            <Grid>
                                  <TextInput required label={t('organization.register.isSpecialInvoice')} name="specialInvoiceNeeds"/>
                                </Grid>
                          </>
                        )}
                        
                        <Grid style={{ gridColumn: "span 2" }}>
                          <TextAreaInput label={t('organization.register.invoiceReference')} name="invoiceReference"/>
                        </Grid>
                      </FieldPanel>
                    </>
                )}
                
                <Button style={{ marginTop: 30 }} disabled={isSubmitting || success}>
                  {isSubmitting ? t('organization.register.creating') : t('organization.register.create')}
                </Button>
              </StyledForm>
            )}
          </Formik>
          <br />
          {success && (
            <SuccessText>
              {t('organization.register.success')}
              <br />
              <br />
              <StyledLink to={EpdLinks.dashboard()}>{t('organization.register.goToDashboard')}</StyledLink>
            </SuccessText>
          )}
          {serverError && <ErrorText>{serverError}</ErrorText>}
        <InformationalDialog
          isOpened={showCreatedLicenseeCompanyDialog && !!licensee?.localHomepage}
          header="Regional Licensee country is detected"
          onHide={() => setShowCreatedLicenseeCompanyDialog(false)}
        >
          Thank you for registering with EPD International. We have various Licensees around the world. You have selected a
          country located in the regional license holder {company?.address?.country}. The regional administrators will help
          you with all support regarding your EPD. To find the local support, please visit{' '}
          <LicenseeSiteLink href={licensee?.localHomepage}>{licensee?.localHomepage}</LicenseeSiteLink>
        </InformationalDialog>
      </Container>
    </>
  );
};

const LicenseeSiteLink = styled(A)`
  color: ${(props) => props.theme.colors.primaryOrange};
`;

const Grid = styled.div`
  display: grid;
`;

const CheckBoxGroup = styled.div`
  display: flex;
  align-items: center;
  & .p-checkbox {
    width: 14px;
    height: 14px;
  }
  & .p-checkbox .p-checkbox-box {
    width: 14px;
    height: 14px;
    border: 1px solid ${(props) => props.theme.colors.elementsButtonDisabled};
    border-radius: 0;
  }
  & .p-checkbox .p-checkbox-box.p-highlight {
    background: ${(props) => props.theme.colors.primaryOrange};
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  & .p-checkbox .p-checkbox-box.p-highlight:hover {
    background: ${(props) => props.theme.colors.primaryOrange};
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }
  && .p-checkbox-box.p-focus {
    border-color: ${(props) => props.theme.colors.primaryOrange};
  }

  label {
    display: flex;
    margin-left: 0.5rem;
    ${(props) => props.theme.fonts.textSmall}
    color: ${(props) => props.theme.colors.primaryBlack};
  }
`;

export default RegisterCompany;
