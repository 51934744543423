import TermsAndConditionsDialog from 'components/dialogs/TermsAndConditionsDialog';
import UpdateCompanyInvoiceAddressDialog from 'components/dialogs/UpdateCompanyInvoiceAddressDialog';
import UpdateCompanyVerifierOrganizationAddressDialog from 'components/dialogs/UpdateCompanyVerifierOrganizationAddressDialog';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { useContext, useState } from 'react';
import AuthService from 'services/AuthService';

const StartupDialogsComponent = () => {
  const { companyId, company } = useContext(CompanyContext);
  const [userAcceptedTerms, setUserAcceptedTerms] = useState<boolean>(false);
  const [confirmAddressDialogOpened, setConfirmAddressDialogOpened] = useState<boolean>(false);
  const [updateOrganizationAddressDialogOpened, setUpdateOrganizationAddressDialogOpened] = useState<boolean>(true);

  const onTermsAccept = (termsAccepted: boolean) => {
    AuthService.acceptNewTermsAndConditions(termsAccepted);
    setUserAcceptedTerms(true);
  };

  if (!AuthService.isLoggedin()) return null;

  return (
    <>
      <TermsAndConditionsDialog
        isOpened={!userAcceptedTerms && !AuthService.getUser()?.acceptsNewTermsOfConditionsDate}
        onTermsAccept={onTermsAccept}
      />

      {companyId && company && AuthService.isCompanyOwner(companyId) && (
        <>
          {company.needDataUpdating && (
            <UpdateCompanyInvoiceAddressDialog
              isOpened={confirmAddressDialogOpened}
              onHide={() => setConfirmAddressDialogOpened(false)}
              companyId={companyId}
              companyName={company.name}
            />
          )}

          {company.isVerifierCertificationBody &&
            [company.organizationAddressLine, company.organizationAddressCity, company.organizationAddressZipCode].some(
              (value: string | null | undefined) => !value || value.trim() === ''
            ) && (
              <UpdateCompanyVerifierOrganizationAddressDialog
                isOpened={updateOrganizationAddressDialogOpened}
                onHide={() => setUpdateOrganizationAddressDialogOpened(false)}
                company={company}
              />
            )}
        </>
      )}
    </>
  );
};

export default StartupDialogsComponent;
