import { TextButton } from 'components/v2/buttons';
import { confirmDelete } from 'components/v2/confirm-dialog/ConfirmDelete';
import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardAddButton from 'components/v2/epd-wizard-inputs/WizardAddButton';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import { HelpBox } from 'components/v2/help-boxes';
import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import React, { useState } from 'react';
import { CPCModel, ProductClassificationModel } from 'services/EpdClient';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import {
  useCreateProductClassification,
  useDeleteProductClassification,
  useUpdateProductClassification,
} from 'services/api/mutations';
import { useDictionaryValues, useUnCpcCodes } from 'services/api/queries';
import {
  ExpandablePanelHeaderName,
  ExpandablePanelModule,
  FieldPanelFullWidth,
  HeaderCaptionSemiBold,
  HorizontalDivider,
} from 'styles/v2/Styles.styled';
import { EPDProductInformationModel, Option } from 'types/types';
import { getOptionsFromDictionary } from 'util/utils';

import ToggleButton from '../../panels/toggleable/ToggleButton';

const ProductClassificationSection: React.FunctionComponent<{
  product?: EPDProductInformationModel;
  onChange: any;
  errors: any;
}> = ({ product, onChange, errors }) => {
  const [rowValues, setRowValues] = useState<any[]>([]);

  const createMutation = useCreateProductClassification();
  const deleteMutation = useDeleteProductClassification();
  const updateMutation = useUpdateProductClassification();

  const cpcCodes = useUnCpcCodes({ smartFilter: undefined, cpcLevel: undefined, codes: undefined }).data;
  const productDetailsErrors = errors?.productClassificationSystemBlock;

  function noErrorsAtProductClassificationSystemBlock(errors: any): boolean | undefined {
    const fieldNames: string[] = ['productClassificationSystemBlock'];

    return !errors || !fieldNames.some((field) => field in errors);
  }

  const selectedCpc = () => {
    if (product?.uncpcId) {
      return {
        value: product?.uncpcId,
        label: cpcCodes?.find((cpc: any) => cpc?.id === product?.uncpcId)?.description,
      } as Option;
    }
    return null;
  };

  const confirmDeleteClassification = (item: any) => {
    confirmDelete({
      caption: `Are you sure you want to delete product classification?`,
      onConfirm: () => deleteMutation.mutate(item.id),
    });
  };

  const dictionariesQuery = useDictionaryValues();

  const productClassificationOptions = getOptionsFromDictionary(
    dictionariesQuery.data,
    EPD_DICTIONARY_CODES.CLASSIFICATION_CODE
  );

  const createRow = () => {
    createMutation.mutate({ productId: product?.id! });
    const newRowValues = [...rowValues, { id: '', name: '', value: '' }];
    setRowValues(newRowValues);
  };

  const headerModuleTemplate = (
    options: any,
    header: string,
    tooltip?: string,
    tooltipPosition?: any | undefined,
    required?: boolean,
    isFilledIn?: boolean
  ) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HeaderCaptionSemiBold>{header}</HeaderCaptionSemiBold>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
          {required && !isFilledIn && <TooltipErrorIcon />}
          <ToggleButton options={options} />
        </ExpandablePanelHeaderName>
      </div>
    );
  };

  const onChangeInternal = (item: ProductClassificationModel, name: keyof ProductClassificationModel, value: any) => {
    item[name] = value;
    updateMutation.mutate(item);
  };

  return (
    <ExpandablePanelModule
      headerTemplate={(options) =>
        headerModuleTemplate(
          options,
          'Product classification system',
          'Other relevant codes for product classification may also be included, e.g.\n- Common Procurement Vocabulary (CPV).\n- United Nations Standard Products and Services Code® (UNSPSC).\n- Classification of Products by Activity (NACE/CPA).\n- Australian and New Zealand Standard Industrial Classification (ANZSIC).',
          'top | right',
          true,
          noErrorsAtProductClassificationSystemBlock(errors)
        )
      }
      toggleable
    >
      <FieldPanelFullWidth style={{ marginTop: -20 }}>
        <CompilerSelectControlledComponent
          label="UN CPC Code"
          name="UNCPCCode"
          tooltip="United Nations Central Product Classification code (based on v.2.1)"
          tooltipPosition={'right'}
          options={cpcCodes?.map((item: CPCModel) => {
            return {
              value: item?.id,
              label: item?.description,
            } as Option;
          })}
          required
          value={selectedCpc()}
          onChanged={onChange}
          placeholder="Select"
          error={productDetailsErrors?.code}
        />
        <WizardTextInputComponent
          label="GTIN"
          tooltip="Global trade item number."
          tooltipPosition={'right'}
          placeholder="Type here"
          value={product?.gtin}
          onChanged={onChange}
          name="GTIN"
          error={productDetailsErrors?.gtin}
        />
      </FieldPanelFullWidth>
      <FieldPanelFullWidth style={{ marginTop: -60, gridRowGap: '5px' }}>
        <HelpBox>
          <span>Declaration of other product classification systems</span>
        </HelpBox>
        <WizardAddButton
          label="+ Add product classification system"
          onClick={createRow}
          disabled={(product?.productClassifications?.length as number) >= 5}
        />
      </FieldPanelFullWidth>
      {product?.productClassifications?.map((item, index) => (
        <FieldPanelFullWidth key={index} style={{ paddingTop: '0', marginTop: -35, columnGap: '16px' }}>
          <>
            <HorizontalDivider className="w-full" style={{ margin: '0px' }}></HorizontalDivider>
            <CompilerSelectControlledComponent
              label="Product classification system"
              name="productClassificationType"
              options={productClassificationOptions}
              value={{
                value:
                  productClassificationOptions
                    .find((x: any) => x.value == item?.productClassificationType)
                    ?.value.toString() ?? null,
                label:
                  productClassificationOptions.find((x) => x.value === item?.productClassificationType?.toString())?.label ??
                  null,
              }}
              onChanged={(name: any, value: any) => {
                onChangeInternal(item, name, value);
              }}
              placeholder="Select"
            />
            <TextButton
              style={{ padding: '0 0 0.75rem 0', alignSelf: 'flex-end', margin: '0', color: '#000000', width: 'auto' }}
              type="button"
              text
              icon="pi pi-trash"
              onClick={() => confirmDeleteClassification(item)}
            />
            <div style={{ display: 'grid', rowGap: '30px' }}>
              {productClassificationOptions.find((x) => x.value == item?.productClassificationType?.toString())?.label ===
                'Other' && (
                <WizardTextInputComponent
                  label="Product classification system name"
                  onChanged={(name: any, value: any) => {
                    onChangeInternal(item, name, value);
                  }}
                  placeholder="Type here"
                  name="description"
                  value={item?.description}
                />
              )}
              <WizardTextInputComponent
                label="Code"
                placeholder="Type here"
                value={item.code}
                onChanged={(name: any, value: any) => {
                  onChangeInternal(item, name, value);
                }}
                name="code"
                type="number"
              />
            </div>
          </>
        </FieldPanelFullWidth>
      ))}
    </ExpandablePanelModule>
  );
};

export default ProductClassificationSection;
