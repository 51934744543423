import { ActionDeclineButton, ActionPrimaryButton } from 'components/v2/buttons';
import EditDialog from 'components/v2/copy/dialogs/EditDialog';
import WizardSelectControlledComponent from 'components/v2/epd-wizard-inputs/WizardSelectControlled';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import { HelpBox } from 'components/v2/help-boxes';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyService from 'services/CompanyService';
import CountriesService from 'services/CountriesService';
import { ActionState } from 'services/EpdClient';
import { CompanyModel, CountryModel } from 'services/EpdClient';
import styled from 'styled-components';
import { FieldPanelInput, HorizontalDivider } from 'styles/v2/Styles.styled';
import { Option } from 'types/types';
import WizardTextInputWithErrorMessage from 'components/v2/epd-wizard-inputs/WizardTextInputWithErrorMessage';
import { useGetRegexVATPattern } from 'components/company/useGetRegexVATPattern';

type TProps = {
  isOpened: boolean;
  onHide: any;
  companyId: string;
  companyName?: string;
};
const UpdateCompanyInvoiceAddressDialog = ({ isOpened, onHide, companyId, companyName }: TProps) => {
  const { t } = useTranslation();
  const allCountriesRef = useRef<CountryModel[]>([]);
  const [name, setName] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [countryName, setCountryName] = useState<string>(t('placeholder.defaultSelect'));
  const [zipCode, setZipCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [addressLine, setAddressLine] = useState<string>('');
  const [addressLineSecond, setAddressLineSecond] = useState<string>('');
  const [reference, setReference] = useState<string>('');
  const [vat, setVat] = useState<string>('');
  const [gln, setGln] = useState<string>('');
  const [invoicingEmail, setInvoicingEmail] = useState<string>('');
  const [remindingEmail, setRemindingEmail] = useState<string>('');
  const [orderingEmail, setOrderingEmail] = useState<string>('');
  const [countries, setCountries] = useState<CountryModel[]>([]);
  useEffect(() => {
    async function fetchCountries() {
      if (!allCountriesRef.current || allCountriesRef.current.length == 0) {
        var countries = await CountriesService.getCountries();
        allCountriesRef.current = countries;
      }
      setCountries(allCountriesRef.current);
    }
    fetchCompany();
    fetchCountries();
  }, []);

  const fetchCompany = async () => {
    try {
      const result = await CompanyService.getCompany(companyId);
      if (!result.needDataUpdating) {
        onHide(true);
        return;
      }
      setValues(result);
    } catch (error) {
      onHide(true);
    }
  };

  const updateCountry = (value: string) => {
    setCountry(value);
    var countryName = countries.find((c) => c.id == value)?.name;
    if (countryName) {
      setCountryName(countryName);
    }
  };

  const setValues = (value: CompanyModel) => {
    if (!value) {
      return;
    }
    setName(value.invoiceOrganizationName ?? '');
    setCountry(value.invoiceCountryId ?? '');
    setCountryName(value.invoiceCountryName ?? t('placeholder.defaultSelect'));
    setZipCode(value.invoiceAddressZipCode ?? '');
    setCity(value.invoiceAddressCity ?? '');
    setAddressLine(value.invoiceAddressLine ?? '');
    setAddressLineSecond(value.invoiceAddressLineSecond ?? '');
    setReference(value.reference ?? '');
    setVat(value.vat ?? '');
    setGln(value.gln ?? '');
    setInvoicingEmail(value.invoicingEmail ?? '');
    setRemindingEmail(value.invoiceRemindingEmail ?? '');
    setOrderingEmail(value.invoiceRemindingEmail ?? '');
  };

  const updateCompany = async () => {
    try {
      const result = await CompanyService.getCompany(companyId);
      result.needDataUpdating = false;
      result.invoiceOrganizationName = name;
      result.invoiceCountryId = country;
      result.invoiceAddressZipCode = zipCode;
      result.invoiceAddressCity = city;
      result.invoiceAddressLine = addressLine;
      result.invoiceAddressLineSecond = addressLineSecond;
      result.reference = reference;
      result.vat = vat;
      result.gln = gln;
      result.invoicingEmail = invoicingEmail;
      result.invoiceRemindingEmail = remindingEmail;
      result.invoiceOrderingEmail = orderingEmail;
      await CompanyService.updateCompany(result);
      onHide(true);
    } catch (error) {
      onHide(true);
    }
  };

  const isEmailValid = (value: string) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
  };

  let isVATValid = useGetRegexVATPattern(countryName as string)?.test(vat) ?? true;

  let isGLNValid = gln ? /^\d{13}$/.test(gln) : true;

  const isSwedenSelected = countryName === 'Sweden';

  const isValideToSend = () => {
    return (
      name &&
      country &&
      zipCode &&
      city &&
      addressLine &&
      isEmailValid(invoicingEmail) &&
      isEmailValid(remindingEmail) &&
      isEmailValid(orderingEmail) &&
      isVATValid &&
      isGLNValid
    );
  };

  return (
    <EditDialog header="Please confirm your invoicing information!" isOpened={isOpened} onHide={onHide} maximizable={false}>
      <div
        style={{
          textAlign: 'center',
          marginLeft: '16px',
          height: '3rem',
          fontWeight: '500',
          fontSize: '1.1rem',
        }}
      >
        {companyName}
      </div>
      <HelpBox
        style={{
          marginBottom: '20px',
        }}
      >
        We are updating our financing system and need to confirm that your invoicing information is up to date in our system.
        Please add the information in the fields below before you proceed.
        <br /> <br />
        You can update this information anytime in your settings under the Invoice Information tab.
      </HelpBox>
      <FieldPanelInput>
        <WizardTextInputComponent
          value={name}
          type="text"
          label="Company name (on invoice)"
          placeholder={'Insert company name'}
          name={'name'}
          required
          onChanged={(propName: string, value: any) => setName(value)}
          style={{
            color: '#000000',
          }}
        />
        <HorizontalDivider />
        <br />
        <WizardTextInputComponent
          value={addressLine}
          type="text"
          label={t('invoiceAddressLine')}
          placeholder={t('placeholder.typeHere')}
          name={'addressLine'}
          required
          onChanged={(propName: string, value: any) => setAddressLine(value)}
          style={{
            marginBottom: '20px',
            color: '#000000',
          }}
        />
        <WizardTextInputComponent
          value={addressLineSecond}
          type="text"
          label={t('invoiceAddressLineSecond')}
          placeholder={t('placeholder.typeHere')}
          name={'addressLineSecond'}
          onChanged={(propName: string, value: any) => setAddressLineSecond(value)}
          style={{
            marginBottom: '20px',
            color: '#000000',
          }}
        />
        <WizardTextInputComponent
          value={reference}
          type="text"
          label={t('reference')}
          placeholder={t('placeholder.typeHere')}
          name={'reference'}
          onChanged={(propName: string, value: any) => setReference(value)}
          style={{
            marginBottom: '20px',
            color: '#000000',
          }}
        />
        <WizardTextInputComponent
          value={city}
          type="text"
          label={t('invoiceAddressCity')}
          placeholder={t('placeholder.typeHere')}
          name={'city'}
          required
          onChanged={(propName: string, value: any) => setCity(value)}
          style={{
            marginBottom: '20px',
            color: '#000000',
          }}
        />
        <WizardTextInputComponent
          value={zipCode}
          type="text"
          label={t('invoiceAddressZipCode')}
          placeholder={t('placeholder.typeHere')}
          name={'zipCode'}
          required
          onChanged={(propName: string, value: any) => setZipCode(value)}
          style={{
            marginBottom: '20px',
            color: '#000000',
          }}
        />
        <WizardSelectControlledComponent
          options={countries.map((x: CountryModel) => {
            return { value: x.id, label: x.name } as Option;
          })}
          value={{ value: country, label: countryName }}
          isClearable={false}
          label={t('invoiceAddressCountry')}
          placeholder={t('placeholder.defaultSelect')}
          name={'country'}
          required
          onChanged={(propName: string, value: any) => updateCountry(value)}
          style={{
            color: '#000000',
          }}
        />
        <HorizontalDivider />
        <br />
        <WizardTextInputWithErrorMessage
          value={vat}
          type="text"
          label={t('vat')}
          placeholder={t('placeholder.typeHere')}
          name={'vat'}
          onChanged={(propName: string, value: any) => setVat(value)}
          required={!isVATValid}
          inputStyle={{
            color: '#000000',
          }}
          errorMessage="Invalid VAT number"
          isValid={isVATValid}
        />
        {isSwedenSelected && (
          <WizardTextInputWithErrorMessage
            value={gln}
            type="text"
            label={t('gln')}
            placeholder={t('placeholder.typeHere')}
            name={'gln'}
            onChanged={(propName: string, value: any) => setGln(value)}
            inputStyle={{
              color: '#000000',
            }}
            componentStyle={{
              marginTop: '20px',
            }}
            errorMessage="Invalid GLN number"
            isValid={isGLNValid}
          />
        )}
        <HorizontalDivider />
        <br />
        <WizardTextInputComponent
          value={orderingEmail}
          type="text"
          label={t('invoiceOrderingEmail')}
          placeholder={t('placeholder.email')}
          name={'orderingEmail'}
          required={true}
          error={!isEmailValid(orderingEmail)}
          onChanged={(propName: string, value: any) => setOrderingEmail(value)}
          style={{
            marginBottom: '20px',
            color: '#000000',
          }}
        />
        <WizardTextInputComponent
          value={remindingEmail}
          type="text"
          label={t('invoiceRemindingEmail')}
          placeholder={t('placeholder.email')}
          name={'remindingEmail'}
          required={true}
          error={!isEmailValid(remindingEmail)}
          onChanged={(propName: string, value: any) => setRemindingEmail(value)}
          style={{
            marginBottom: '20px',
            color: '#000000',
          }}
        />
        <WizardTextInputComponent
          value={invoicingEmail}
          type="text"
          label={t('invoicingEmail')}
          placeholder={t('placeholder.email')}
          name={'invoicingEmail'}
          required={true}
          error={!isEmailValid(invoicingEmail)}
          onChanged={(propName: string, value: any) => setInvoicingEmail(value)}
          style={{
            marginBottom: '20px',
            color: '#000000',
          }}
        />
        <br />
        <div style={{ textAlign: 'center' }}>
          <ButtonContainer>
            <ActionDeclineButton
              style={{
                marginLeft: '16px',
                border: '1px solid #E75113',
                minWidth: '170px',
                height: '3rem',
                borderRadius: '4px',
                fontWeight: '500',
                fontSize: '1.1rem',
              }}
              label="Cancel"
              onClick={() => onHide(true)}
              actionState={ActionState.Enabled}
            />
            <ActionPrimaryButton
              style={{ marginLeft: '16px' }}
              label="Save"
              onClick={() => updateCompany()}
              actionState={isValideToSend() ? ActionState.Enabled : ActionState.Disabled}
            />
          </ButtonContainer>
        </div>
      </FieldPanelInput>
    </EditDialog>
  );
};

const ButtonContainer = styled.div`
  margin: 30px 10px 10px 0px;
`;

export default UpdateCompanyInvoiceAddressDialog;
