import React from 'react';
import VersionHistorySummaryGrid, { SummaryGridRow } from '../VersionHistorySummaryGrid';
import { useEpdVersionHistoryData } from '../utils/EpdVersionHistoryDataContext';
import { EPDProductInformationModel } from 'types/types';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { ProductMaterialProperyModel } from 'services/EpdClient';

const NOT_ASSIGNED = 'N/A';

const VersionHistoryProductInformationGrid: React.FC<{ product: EPDProductInformationModel }> = ({ product }) => {
  const { pcrInformation, countries, dictionaries, cpcCodes, materialPropertiesLookup } = useEpdVersionHistoryData();

  const possibleRows: (SummaryGridRow | false)[] = [
    {
      name: 'Product name',
      value: product.productName ?? NOT_ASSIGNED,
    },
    !!pcrInformation.pcr?.isCPCR22OrCPCR23Selected && {
      name: 'Is the project in the planning/design phase?',
      value: formatOptionalBooleanAsYesNoOrNotAssigned(product.isTheProjectInThePlanningOrDesignPhase),
    },
    {
      name: 'Product identification',
      value: product.productIdentification ?? NOT_ASSIGNED,
    },
    {
      name: 'Product description',
      value: product.productDescription ?? NOT_ASSIGNED,
    },
    !!product.productInformationExternal && {
      name: 'Product information from external sources',
      value: product.productInformationExternal,
    },
    {
      name: 'Technical purpose of product',
      value: product.technicalPurposeOfProduct ?? NOT_ASSIGNED,
    },
    {
      name: 'Manufacturing or service provision description',
      value: product.manufacturingOrServiceProvisionDescription ?? NOT_ASSIGNED,
    },
    ...(product.productMaterialProperties ?? [null]).map((mp: ProductMaterialProperyModel | null, index) => {
      const name = `Material properties ${index > 0 ? index + 1 : ''}`;
      if (mp === null) {
        return { name, value: NOT_ASSIGNED };
      }

      const property = materialPropertiesLookup.find((m) => m.id === mp.materialPropertyId);
      return {
        name,
        value: property ? `Unit: ${property.name}\nValue: ${mp.value}` : NOT_ASSIGNED,
      };
    }),
    {
      name: 'Production sites',
      value:
        product.productionSites
          ?.map((s) => {
            const details = [
              countries.find((c) => c.id === s.countryId)?.name,
              s.city,
              s.zipCode,
              s.addressLine,
              s.addressLineSecond,
            ]
              .filter((x) => x)
              .join(', ');
            return details || NOT_ASSIGNED;
          })
          .join('\n') ?? NOT_ASSIGNED,
    },
    {
      name: 'UN CPC code',
      value: cpcCodes?.find((cpc: any) => cpc?.id === product.uncpcId)?.description ?? NOT_ASSIGNED,
    },
    {
      name: 'GTIN',
      value: product.gtin ?? NOT_ASSIGNED,
    },
    !!product.productClassifications &&
      product.productClassifications.length > 0 && {
        name: 'Product classification system',
        value: product.productClassifications
          .map((pc) => {
            const classification =
              dictionaries.find(
                (d) =>
                  d.dictionaryCode === EPD_DICTIONARY_CODES.CLASSIFICATION_CODE && d.key === pc.productClassificationType
              )?.value ?? NOT_ASSIGNED;
            return [classification, pc.description, pc.code].filter(Boolean).join('\n');
          })
          .join('\n\n'),
      },
    {
      name: 'Geographical scope',
      value: product.productGeographicalScopes?.map((g) => g.name)?.join(', ') ?? NOT_ASSIGNED,
    },
    {
      name: 'Geographical scope description',
      value: product.geographicalScopeDescription ?? NOT_ASSIGNED,
    },
    {
      name: 'Actual or Technical lifespan',
      value: (() => {
        const value = product.unitValue;
        const unit = dictionaries.find(
          (d) => d.dictionaryCode === EPD_DICTIONARY_CODES.UNIT_TYPE && d.key === product.unitType
        )?.value;
        if (!value || !unit) {
          return NOT_ASSIGNED;
        }
        return `${value} ${unit.toLowerCase()}`;
      })(),
    },
  ];

  const rows = possibleRows.filter((r) => r) as SummaryGridRow[];

  return <VersionHistorySummaryGrid rows={rows} />;
};

export default VersionHistoryProductInformationGrid;

function formatOptionalBooleanAsYesNoOrNotAssigned(flag: boolean | null | undefined): string {
  if (typeof flag !== 'boolean') {
    return NOT_ASSIGNED;
  }
  return flag ? 'Yes' : 'No';
}
