import { ActionDeclineButton, ActionPrimaryButton } from 'components/v2/buttons';
import EditDialog from 'components/v2/copy/dialogs/EditDialog';
import WizardSelectControlledComponent from 'components/v2/epd-wizard-inputs/WizardSelectControlled';
import WizardTextInputComponent from 'components/v2/epd-wizard-inputs/WizardTextInput';
import { HelpBox } from 'components/v2/help-boxes';
import { ProcessStatus } from 'constants/constants';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CompanyService from 'services/CompanyService';
import CountriesService from 'services/CountriesService';
import { ActionState } from 'services/EpdClient';
import { CompanyModel, CountryModel } from 'services/EpdClient';
import styled from 'styled-components';
import { FieldPanelInput } from 'styles/v2/Styles.styled';
import { Option } from 'types/types';

type TProps = {
  isOpened: boolean;
  onHide: any;
  company: CompanyModel;
};
const UpdateCompanyVerifierOrganizationAddressDialog = ({ isOpened, onHide, company }: TProps) => {
  const { t } = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState<CompanyModel>(company);
  const [countries, setCountries] = useState<Option[]>([]);
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);

  useEffect(() => {
    const fetchCountries = async () => {
      setStatus(ProcessStatus.Fetching);
      try {
        if (!countries || countries.length == 0) {
          var countriesResult = await CountriesService.getCountries();

          setCountries(
            countriesResult.map((x: CountryModel) => {
              return { value: x.id, label: x.name } as Option;
            })
          );

          setStatus(ProcessStatus.Success);
        }
      } catch (error) {
        console.error(error);
        setStatus(ProcessStatus.Error);
      }
    };

    fetchCountries();
  }, []);

  const saveCompany = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      if (isValidToSend()) {
        // There is a bug that Portal has different places which stored addresses. Need to remove address operation after fix
        if (!selectedCompany.address) {
          selectedCompany.address = {};
        }

        selectedCompany.address.countryId = selectedCompany.organizationCountryId;

        await CompanyService.updateCompany(selectedCompany!);
      }
      setStatus(ProcessStatus.Success);
      onHide(true);
    } catch (error) {
      setStatus(ProcessStatus.Success);
      onHide(true);
    }
  };

  const updateCompany = (propName: string, value: any) => {
    let updatedCompany = {
      ...selectedCompany,
      [propName]: value,
    };

    setSelectedCompany(updatedCompany);
  };

  const isValidToSend = () => {
    return (
      selectedCompany &&
      selectedCompany.organizationCountryId &&
      selectedCompany.organizationAddressCity &&
      selectedCompany.organizationAddressZipCode &&
      selectedCompany.organizationAddressLine
    );
  };

  return (
    <EditDialog header="Organisation address update is required" isOpened={isOpened} onHide={onHide} maximizable={false}>
      <TitleContainer>{selectedCompany.name}</TitleContainer>
      <HelpBox
        style={{
          marginBottom: '20px',
        }}
      >
        We kindly inform you that, due to the latest updates in the EPD Portal, it is now required to fill in the mandatory
        address fields in the organisation settings.
        <br /> <br />
        Please update the address, as the organisation's address now needs to be included as part of the verification
        information in the EPD document, which will be generated in EPD Compiler format.
      </HelpBox>
      {status === ProcessStatus.Fetching && (
        <ProgressSpinner
          aria-label="Processing"
          style={{ width: '80px', height: '80px', position: 'absolute', top: '50%', left: '50%' }}
          strokeWidth="4"
        />
      )}
      <FieldContainer>
        <FieldPanelInput>
          <WizardTextInputComponent
            value={selectedCompany?.organizationAddressLine}
            type="text"
            label={t('address')}
            placeholder={t('placeholder.typeHere')}
            name="organizationAddressLine"
            required
            onChanged={updateCompany}
          />
        </FieldPanelInput>
        <FieldPanelInput>
          <WizardTextInputComponent
            value={selectedCompany?.organizationAddressLineSecond}
            type="text"
            label={t('addressSecondLine')}
            placeholder={t('placeholder.typeHere')}
            name="organizationAddressLineSecond"
            onChanged={updateCompany}
          />
        </FieldPanelInput>
        <FieldPanelInput>
          <WizardTextInputComponent
            value={selectedCompany?.organizationAddressCity}
            type="text"
            label={t('organizationAddressCity')}
            placeholder={t('placeholder.typeHere')}
            name="organizationAddressCity"
            required
            onChanged={updateCompany}
          />
        </FieldPanelInput>
        <FieldPanelInput>
          <WizardTextInputComponent
            value={selectedCompany?.organizationAddressZipCode}
            type="text"
            label={t('organizationAddressZipCode')}
            placeholder={t('placeholder.typeHere')}
            name="organizationAddressZipCode"
            required
            onChanged={updateCompany}
          />
        </FieldPanelInput>
        <FieldPanelInput>
          <WizardSelectControlledComponent
            options={countries}
            value={countries.find((c: Option) => c.value === selectedCompany?.organizationCountryId)}
            isClearable={false}
            label={t('country')}
            placeholder={t('placeholder.defaultSelect')}
            name="organizationCountryId"
            required
            onChanged={updateCompany}
          />
        </FieldPanelInput>
      </FieldContainer>
      <ButtonContainer>
        <ActionDeclineButton
          style={{
            border: '1px solid #E75113',
            minWidth: '170px',
            height: '3rem',
            borderRadius: '4px',
            fontWeight: '500',
            fontSize: '1.1rem',
          }}
          label="Cancel"
          onClick={() => onHide(true)}
          actionState={ActionState.Enabled}
        />
        <ActionPrimaryButton
          style={{ marginLeft: '16px' }}
          label="Save"
          onClick={() => saveCompany()}
          actionState={isValidToSend() ? ActionState.Enabled : ActionState.Disabled}
        />
      </ButtonContainer>
    </EditDialog>
  );
};

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 3rem;
  font-weight: 500;
  font-size: 1.1rem;
`;

const FieldContainer = styled.div`
  ${FieldPanelInput} {
    margin-bottom: 1.5rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

export default UpdateCompanyVerifierOrganizationAddressDialog;
