import SelectInput from 'components/form/SelectInput';
import { Formik } from 'formik';
import editIcon from 'images/icons/svg/edit-1.svg';
import photoIcon from 'images/icons/svg/photo-camera.svg';
import noImageIcon from 'images/icons/svg/picture.svg';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import CompanyService from 'services/CompanyService';
import CountriesService from 'services/CountriesService';
import { CompanyModel, CompanySize, CountryModel, LinkedCompanyModel } from 'services/EpdClient';
import styled from 'styled-components';
import {
  Button,
  ButtonSmall,
  ErrorText,
  FormMode,
  InfoText,
  Label,
  Select,
  StyledForm,
  SuccessText,
} from 'styles/Styles.styled';
import Spinner from 'styles/spinner-left.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

import {
  AuLicenseesCountry,
  MAX_COMPANY_LOGO_SIZE,
  ProcessStatus,
  REG_PHONE,
  REG_URL,
  RoleName,
} from '../../constants/constants';
import TextInput from '../form/TextInput';
import InformationalDialog from 'components/dialogs/InformationalDialog';

const ManageSettings: React.FunctionComponent<{ companyId: string }> = ({ companyId }) => {
  const history = useHistory();
  const [company, setCompany] = useState<CompanyModel>({} as CompanyModel);
  const [companyLogotype, setCompanyLogotype] = useState<any>();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [imgStatus, setImgStatus] = useState<ProcessStatus>(ProcessStatus.None);
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [linkableCompanies, setLinkableCompanies] = useState<LinkedCompanyModel[]>([]);
  const [parentId, setParentId] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { t } = useTranslation();
  const allCountriesRef = useRef<CountryModel[]>([]);
  const [showAUCountryChangedDialog, setShowAUCountryChangedDialog] = useState<boolean>(false);

  const currentUserEmail = AuthService.getUser().email;
  const epdOwners = company?.memberships?.filter((m) => m.roleName === RoleName.EPDOwner)?.map((m) => m.userEmail);
  const isEpdOwner = epdOwners?.includes(currentUserEmail);

  const companySchema = Yup.object<CompanyModel>({
    name: Yup.string().nullable().required(t('messages.required')),
    email: Yup.string().nullable().email(t('messages.invalidEmail')) as Yup.StringSchema,
    website: (Yup.string() as any).matches(REG_URL, t('messages.invalidUrl')).nullable(),
    phone: (Yup.string() as any).matches(REG_PHONE, t('messages.invalidPhone')).nullable(),
    //address: (Yup.object() as any).shape({ countryId: Yup.string().required(t('messages.required')).nullable() }),
    companySize: (Yup.string() as any).required(t('messages.required')).nullable(),
    abn: (Yup.string() as any)
      .when('organizationCountryId', {
        is: (countryId: string | undefined) =>
          countryId && AuLicenseesCountry.includes(countries.find((x) => x.id === countryId)?.alpha2Code || ''),
        then: Yup.string().required(t('messages.required')),
      })
      .nullable(),
    gst: (Yup.string() as any)
      .when('organizationCountryId', {
        is: (countryId: string | undefined) =>
          countryId && AuLicenseesCountry.includes(countries.find((x) => x.id === countryId)?.alpha2Code || ''),
        then: Yup.string().required(t('messages.required')),
      })
      .nullable(),
    organizationAddressLine: Yup.string()
      .nullable()
      .when('isVerifierCertificationBody', {
        is: (isVerifierCertificationBody) => isVerifierCertificationBody,
        then: Yup.string().trim().required(t('messages.required')),
      }) as Yup.StringSchema,
    organizationAddressLineSecond: Yup.string().nullable() as Yup.StringSchema,
    organizationAddressCity: Yup.string()
      .nullable()
      .when('isVerifierCertificationBody', {
        is: (isVerifierCertificationBody) => isVerifierCertificationBody,
        then: Yup.string().trim().required(t('messages.required')),
      }) as Yup.StringSchema,
    organizationAddressZipCode: Yup.string()
      .nullable()
      .when('isVerifierCertificationBody', {
        is: (isVerifierCertificationBody) => isVerifierCertificationBody,
        then: Yup.string().trim().required(t('messages.required')),
      }) as Yup.StringSchema,
    organizationCountryId: Yup.string().nullable().required(t('messages.required')),
  });

  const fetchCompanyParents = async () => {
    try {
      const linkableCompanies = await CompanyService.getLinkableCompanies(companyId);
      setLinkableCompanies(linkableCompanies);
      //const parentCompany = await CompanyService.getCompany(company?.parentCompany?.id as string);
      //setCompanyChildren(parentCompany.childCompanies as LinkedCompanyModel[]);
    } catch (error) {
      setErrorMessage(PoorMansError(error));
    }
  };

  const isSpecialAuRules = (selectedCountryId: string | undefined) => {
    return AuLicenseesCountry.includes(allCountriesRef.current?.find((x) => x.id === selectedCountryId)?.alpha2Code || '');
  };

  const fetchCompany = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      const result = await CompanyService.getCompany(companyId);
      fetchCountries(result?.address?.countryId);
      setCompany(result);
      // added setCompanyChildren (get from fetchCompanyParents)
      // setCompanyChildren(result.childCompanies as LinkedCompanyModel[]);
      await fetchCompanyParents();
      setParentId(result?.parentCompany?.id);
    } catch (error) {
      setErrorMessage(PoorMansError(error));
    }
    setStatus(ProcessStatus.None);
  };

  const fetchCompanyLogotype = async () => {
    try {
      setImgStatus(ProcessStatus.Fetching);
      const result = await CompanyService.getLogotype(companyId);
      if (result !== null) {
        let TYPED_ARRAY = new Uint8Array(result);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(STRING_CHAR);
        setCompanyLogotype('data:image/png;base64,' + base64String);
        setImgStatus(ProcessStatus.Success);
      } else {
        setCompanyLogotype(null);
        setImgStatus(ProcessStatus.Success);
      }
    } catch (error) {
      setErrorMessage(PoorMansError(error));
      setImgStatus(ProcessStatus.Error);
    }
  };

  useEffect(() => {
    async function fetch() {
      if (!allCountriesRef.current || allCountriesRef.current.length == 0) {
        var countries = await CountriesService.getCountries();
        allCountriesRef.current = countries; //setAllCountries(countries);
      }
    }
    fetch();
  }, []);

  useEffect(() => {
    fetchCompany();
    fetchCompanyLogotype();
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCountryChange = (event: any, formCountryId: string | undefined, setFieldValue: any) => {
    const { value: countryId } = event.target;
    const AUCountryId = allCountriesRef.current?.find((c) => c.alpha2Code === AuLicenseesCountry[0])?.id;
    const changedFromAustralia = formCountryId === AUCountryId;
    const changedToAustralia = AUCountryId === countryId;

    if (isEpdOwner && (changedFromAustralia || changedToAustralia)) {
      event.preventDefault();
      setShowAUCountryChangedDialog(true);
      return;
    }
    setFieldValue('organizationCountryId', countryId);
  };

  const fetchCountries = async (countryId: string | undefined) => {
    const showAllCountry = AuthService.isAdmin() || (AuthService.isLicenseeAdmin() && !isSpecialAuRules(countryId));
    const result = showAllCountry ? allCountriesRef.current : await CountriesService.getLicenseeCountries(companyId);
    //const emptyCountry: CountryModel = { id: undefined, name: '', alpha2Code: '' };
    setCountries(result);
  };

  const updateCompany = async (values: CompanyModel) => {
    try {
      await CompanyService.updateCompany(values);
      await fetchCompany();
      setStatus(ProcessStatus.Success);
    } catch (error) {
      setStatus(ProcessStatus.Error);
      setErrorMessage(PoorMansError(error));
    }
  };

  if (status === ProcessStatus.Fetching) {
    return <InfoText>{t('manageSettings.messages.fetching')}</InfoText>;
  }

  if (!company?.id) {
    return <ErrorText>{t('manageSettings.errors.technical')}</ErrorText>;
  }

  const goToCompany = async (childId: string) => {
    history.replace(EpdLinks.manageCompany(childId));
  };

  const uploadLogo = async (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    let file = target && target.files && target.files[0];

    if (!file || !company) {
      return null;
    }

    if (file.size > MAX_COMPANY_LOGO_SIZE) {
      toast.error(
        t('messages.errorTooLargeLogoImageFile', { size: Math.floor(MAX_COMPANY_LOGO_SIZE / (1024 * 1024)) }) as string,
        {
          position: 'top-center',
        }
      );
      return;
    }

    const fileName = file.name;
    const fileType = file.type;
    const reader = new FileReader();
    reader.onload = async () => {
      const fileBlob = reader.result as ArrayBuffer;
      if (fileBlob) {
        const blob = new Blob([new Uint8Array(fileBlob)], { type: fileType });
        try {
          setImgStatus(ProcessStatus.Fetching);
          await CompanyService.updateLogotype(companyId, {
            fileName: fileName,
            data: blob,
          });
          await fetchCompanyLogotype();
        } catch {
          setImgStatus(ProcessStatus.Error);
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <h3>{t('manageSettings.title')}</h3>
      <InfoBox style={{ marginBottom: '0.2rem', marginLeft: '1rem' }}>
        <img src={editIcon} alt="information" style={{ height: '35px', marginRight: '1rem' }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>{t('manageSettings.info.info1')}</div>
      </InfoBox>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '1rem', maxWidth: '25rem' }}>
        <Label>{'Logo'}</Label>
        <ImageBox>
          {imgStatus === ProcessStatus.Fetching ? (
            <Spinner inline />
          ) : (
            <img alt={`Custom {name}`} src={companyLogotype ?? noImageIcon} />
          )}
          <ImageUpsert>
            <img src={photoIcon} style={{ height: '1.5rem' }} alt={t('manageSettings.tooltips.addLogo')} />
            <input
              type="file"
              title={t('manageSettings.tooltips.addLogo')}
              accept=".bmp,.jpeg,.jpg,.png,.tiff"
              onChange={(e) => uploadLogo(e)}
            />
          </ImageUpsert>
        </ImageBox>
      </div>
      <Formik initialValues={company} validationSchema={companySchema} onSubmit={(values) => updateCompany(values)}>
        {({ values, setFieldValue, isSubmitting }) => (
          <StyledForm mode={FormMode.Inline}>
            <FormColumn>
              <TextInput label={t('address')} placeholder={t('placeholder.typeHere')} name="organizationAddressLine" />
              <TextInput
                label={t('addressSecondLine')}
                placeholder={t('placeholder.typeHere')}
                name="organizationAddressLineSecond"
              />
              <TextInput
                label={t('organizationAddressCity')}
                placeholder={t('placeholder.typeHere')}
                name="organizationAddressCity"
              />
              <TextInput
                label={t('organizationAddressZipCode')}
                placeholder={t('placeholder.typeHere')}
                name="organizationAddressZipCode"
              />
              <SelectInput
                label={t('country')}
                name="organizationCountryId"
                value={values.organizationCountryId}
                onChange={(event: any) => handleCountryChange(event, values.organizationCountryId, setFieldValue)}
              >
                {countries.map((c) => (
                  <option value={c.id} key={c.id}>
                    {c.name}
                  </option>
                ))}
              </SelectInput>{' '}
              {isSpecialAuRules(values.organizationCountryId) && <TextInput label={t('abn')} name="abn" />}
            </FormColumn>
            <FormColumn>
              <TextInput
                label={t('name')}
                name="name"
                placeholder={t('placeholder.typeHere')}
                disabled={!company.hasChangeNamePermissions}
              />
              <FixedLabel style={{ paddingBottom: 0 }}>{t('website')}</FixedLabel>
              <TextInput name="website" placeholder={t('placeholder.website')} />
              <TextInput name="needDataUpdating" hidden />
              <TextInput label={t('phone')} name="phone" />
              <FixedLabel style={{ paddingBottom: 0 }}>{t('email')}</FixedLabel>
              <TextInput name="email" placeholder={t('placeholder.email')} />
              <SelectInput label={t('organizationSize')} name="companySize">
                {Object.values(CompanySize).map((x) => (
                  <option value={x} key={x}>
                    {x}
                  </option>
                ))}
              </SelectInput>{' '}
              {isSpecialAuRules(values.organizationCountryId) && <TextInput label={t('gst')} name="gst" />}
            </FormColumn>
            <Button disabled={isSubmitting} style={{ flexBasis: '100%', margin: '0 1rem' }}>
              {isSubmitting ? t('messages.saving') : t('messages.saveSettings')}
            </Button>
            <div style={{ padding: '1rem' }}>
              {status === ProcessStatus.Error && <ErrorText>{t('manageSettings.errors.notSaved')}</ErrorText>}
              {status === ProcessStatus.Success && <SuccessText>{t('manageSettings.messages.saved')}</SuccessText>}
            </div>
          </StyledForm>
        )}
      </Formik>
      <InformationalDialog
        isOpened={showAUCountryChangedDialog}
        header="Organisation country change is not allowed"
        onHide={() => setShowAUCountryChangedDialog(false)}
      >
        Country change is not allowed as your organisation is specified as a regional licensee. Please contact the support of
        EPD international at support@environdec.com to get the assistance.
      </InformationalDialog>
    </>
  );
};

const LinkedGrid = styled.div`
  display: grid;
  grid-template-columns: fit-content(70%) fit-content(30%);
  grid-column-gap: 2rem;
  justify-content: left;
  align-items: start;
  padding-left: 1rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 29% 1%;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  justify-content: left;
  align-items: start;
  padding-left: 1rem;

  ${(props) => props.theme.media.tablet} {
    grid-template-columns: 35% 20% 40%;
  }
  ${(props) => props.theme.media.desktop} {
    grid-template-columns: 25% 20% 49%;
  }
`;

const Column = styled.div`
  margin-top: 1rem;
  grid-column-start: 1;
  grid-column-end: 3;
`;
const ColumnA = styled.div`
  margin-bottom: 1rem;
  grid-column-start: 1;
  grid-column-end: 2;
`;
const ColumnB = styled.div`
  margin-bottom: 1.1rem;
  grid-column-start: 2;
  grid-column-end: 3;
  align-self: end;
`;

const ColumnTitle = styled.div`
  text-transform: uppercase;
`;
const StyledSelect = styled(Select)`
  margin: 0;
  margin-top: 1rem;
`;

const ChildList = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 1rem;
`;

const ChildListItem = styled.li`
  width: 100%;
  margin-bottom: 0.3rem;
`;

const ChildListItemLabel = styled(Label)`
  display: inline-block;
  width: 67%;
  text-transform: uppercase;

  ${(props) => props.theme.media.desktop} {
    width: 60%;
  }
`;

const SwitchButton = styled(ButtonSmall)`
  margin-left: 1rem;
  height: 100%;
`;

const GoButton = styled(ButtonSmall)`
  height: 100%;
`;

const FixedLabel = styled.label`
  font-style: normal;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  ${(props) => props.theme.fonts.textSmall}
  cursor: pointer;
  &:hover {
    & + * {
      display: block;
      opacity: 1;
    }
  }
`;

const InfoBox = styled.div`
  display: flex;
  padding: 1rem;
  border-left: solid 3px ${(props) => props.theme.colors.orange};
  margin: 0.5rem 0;
  background-color: #f3f3f3;
  margin-bottom: 3rem;

  @media print {
    display: none;
  }
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  padding: 1rem;
  box-sizing: border-box;
`;

const ImageBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: unset;
  background-color: ${(props) => props.theme.colors.lightGray};
  max-height: 270px;
  max-width: 370px;
  justify-content: center;
  object-fit: scale-down;
  img {
    max-height: 270px;
    max-width: 370px;
  }
  background: repeating-conic-gradient(#d0d0d0 0% 25%, transparent 0% 50%) 50% / 10px 10px;
`;

const ImageUpsert = styled.div`
  margin: 0.5rem;
  position: absolute;
  right: -1rem;
  top: -1rem;
  cursor: pointer;
  overflow: hidden;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.colors.lightGray};
  align-items: center;
  justify-content: center;
  display: flex;
  &:hover {
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.darkGray};
  }

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    opacity: 0;
    ::-webkit-file-upload-button {
      cursor: pointer;
      alt: 'Select image';
    }
  }
`;

export default ManageSettings;
