import React, { useState } from 'react';
import RegisterCompany from 'pages/account/RegisterCompany';
import FindCompany from 'components/company/FindCompany';
import styled from 'styled-components';
import { TabModel } from "models/tabModels";
import { Aside, Container, MainView } from "styles/Styles.styled";

const CreateOrFindCompany: React.FunctionComponent = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const tabs: TabModel[] = [
        { name: 'Register Company', component: <RegisterCompany key='register_company'/>},
        { name: 'Find Company', component: <FindCompany key='find_company'/>},
    ];

  return (
    <div>
      <Container>
        <MainView>
          <Tabs>
            {tabs.map(
              (tab, index) =>
                !tabs.find((x) => x.component.key == tab.component.key)?.hidden && (
                  <Tab key={index} active={index === activeTab} onClick={() => setActiveTab(index)}>
                    {tab.name}
                  </Tab>
                )
            )}
          </Tabs>
          {tabs.map((tab, index) => index === activeTab && tab.component)}
        </MainView>
        <Aside style={{ backgroundColor: 'white' }}> </Aside>
      </Container>
    </div>
  );
};

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: solid 3px ${(props) => props.theme.colors.gray};
`;

const Tab = styled.div<{ active?: boolean }>`
  padding: 1rem;
  margin-right: 1px;
  background-color: ${(props) => (props.active ? props.theme.colors.gray : 'white')};
  border: solid 1px ${(props) => props.theme.colors.gray};
  border-top: solid 3px ${(props) => props.theme.colors.regionColorLight};
  cursor: pointer;
  &:hover {
    border-top-color: ${(props) => props.theme.colors.regionColor};
  }
`;

export default CreateOrFindCompany;