import { ReactComponent as AddImageIcon } from 'assets/images/icons/plus.svg';
import { Paginator } from 'primereact/paginator';
import React, { useState } from 'react';
import {
  ActionState,
  EPDCopyGroupStatus,
  EpdCopyGroupWizardModel,
  FileLoadingModel,
  VersionStateType,
} from 'services/EpdClient';
import styled from 'styled-components';

import EpdItem from './EpdItem';

const WizardStep2: React.FunctionComponent<{
  groupData?: EpdCopyGroupWizardModel;
  onChangeEpd: any;
  onAddNewEpdCopy: any;
  refetchGroupData: any;
  onConfirmUpdate: any;
  isReadOnly: boolean;
  canUpload: boolean;
  fileLoading?: FileLoadingModel[];
}> = ({
  groupData,
  onChangeEpd,
  onAddNewEpdCopy,
  refetchGroupData,
  onConfirmUpdate,
  isReadOnly,
  canUpload,
  fileLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const onChangeEpdInternal = async (id: string, versionId: string, name: string, value: any) => {
    await onChangeEpd(id, versionId, name, value);
  };

  const handleChangePage = (event: any) => {
    setCurrentPage(event.page + 1); // Paginator starts counting from 0
  };

  const startIdx = (currentPage - 1) * ITEMS_PER_PAGE;
  const isLastPage = groupData?.epds?.length || 0 <= currentPage * ITEMS_PER_PAGE;

  return (
    <>
      {groupData?.epds?.slice(startIdx, startIdx + ITEMS_PER_PAGE).map((item) => (
        <EpdItem
          key={item.id}
          epd={item}
          isReadOnly={
            isReadOnly ||
            (item.versionState === VersionStateType.Approved && !groupData?.userRoles.isAdmin) ||
            (item.versionState === VersionStateType.SentForPublicationLicenseeAdmin && !groupData.userRoles.isLicenseeAdmin)
          }
          canUpload={canUpload && !(item.versionState === VersionStateType.Approved && !groupData?.userRoles.isAdmin)}
          isMenuEnabled={
            !isReadOnly ||
            (isReadOnly &&
              (groupData.status === EPDCopyGroupStatus.DeclinedPublication ||
                groupData.status === EPDCopyGroupStatus.DeclinedCopyByLicenseeAdmin))
          }
          isConstruction={!!groupData.pcr?.isConstruction}
          onChangeEpd={(name: string, value: any) => onChangeEpdInternal(item.id!, item.versionId!, name, value)}
          refetchGroupData={refetchGroupData}
          onConfirmUpdate={() => onConfirmUpdate(item.id!, item.versionId!)}
          groupStatus={groupData?.status || EPDCopyGroupStatus.Draft}
          fileLoading={fileLoading}
        />
      ))}

      {isLastPage && groupData?.groupState?.addNewCopy === ActionState.Enabled && (
        <AddButtonBox onClick={onAddNewEpdCopy}>
          <AddImageIcon style={{ marginRight: '0.625rem' }} />
          Add EPD copy
        </AddButtonBox>
      )}

      <Paginator
        className="paginator-right"
        style={{ padding: 0, marginTop: '1rem' }}
        alwaysShow={false}
        first={startIdx}
        rows={ITEMS_PER_PAGE}
        totalRecords={groupData?.epds?.length}
        onPageChange={handleChangePage}
        template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        rowsPerPageOptions={[ITEMS_PER_PAGE]}
      />
    </>
  );
};

const AddButtonBox = styled.div`
  ${(props) => props.theme.typography.buttonButtonSmall}
  width: 100%;
  border-radius: 4px;
  padding: 20px;
  border: 1px dashed ${(props) => props.theme.colors.primaryOrange01};
  margin-top: 16px;
  justify-content: start;
  align-items: center;
  display: flex;
  max-height: 70px;
  min-height: 70px;
  background: ${(props) => props.theme.colors.primaryOrange01};
  color: ${(props) => props.theme.colors.primaryOrange};
  cursor: pointer;
`;

export default WizardStep2;
