import { Description, DialogContainer } from 'components/v2/confirm-dialog/styled';
import { QuestionIcon } from 'components/v2/icons';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useContext } from 'react';
import MembershipService from 'services/MembershipService';
import styled from 'styled-components';
import {toaster} from "../v2/toast";

const InviteConfirmationDialog = ({ visible, closable, invitation }: any) => {
  const { setDefaultOrFirstCompany, companyId } = useContext(CompanyContext);

  const acceptHandler = async () => {
    const result = await MembershipService.approveInvitation(invitation?.id);

    if (!companyId) {
      setDefaultOrFirstCompany?.();
    }
  };

  const rejectHandler = async () => {
    try {
      await MembershipService.rejectInvitation(invitation?.id);
    } catch (error: any) {
      toaster({
        severity: 'error',
        summary: 'Error',
        details: error?.response?.data?.detail ||  'Reject operation has not been successful due to an error.',
      });
    }
  };

  return (
    <ConfirmDialog
      header={
        <div style={{ paddingTop: '2.5rem' }}>
          <QuestionIcon />
          <HeaderDialog>Invitation request to join the organisation</HeaderDialog>
        </div>
      }
      headerStyle={{ display: 'inline-flex' }}
      headerClassName="m-0 p-0"
      message={
        <DialogContainer style={{ padding: '0rem 2rem 0rem 2rem' }}>
          <Description>
            <div>
              You have been invited to join <strong>{invitation?.companyName}</strong>
              <p style={{ marginTop: '0rem' }}>organisation as {invitation?.roleName}. Please respond to this invitation.</p>
            </div>
          </Description>
        </DialogContainer>
      }
      acceptLabel="Accept invite"
      rejectLabel="Reject invite"
      accept={acceptHandler}
      reject={rejectHandler}
      visible={visible}
      closable={closable}
      className="custom-dialog"
    />
  );
};

export const HeaderDialog = styled.h3`
  ${(props) => props.theme.fonts.headingDialog}
  margin-top: 30px;
`;

export default InviteConfirmationDialog;
