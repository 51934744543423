// @ts-nocheck TODO: How do we style svgs properly?

import Languages from 'components/header/Languages';
import exit from 'images/icons/svg/exit.svg';
import isEmpty from 'lodash/isEmpty';
import React, { useRef, useState } from 'react';
import { FocusOn } from 'react-focus-on';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import AuthService from 'services/AuthService';
import styled, { useTheme } from 'styled-components';
import { ButtonAnchorLinkSmall } from 'styles/Styles.styled';

import { Modal } from '../../util/Modal';
import { ExpandArrow } from './ExpandArrow';

export const Hamburger: React.FunctionComponent<{
  height: number;
  menuItems: Menu;
  changeTheme: any;
  isAdmin?: boolean;
  onChangeUI?: any;
}> = ({ height, menuItems, changeTheme, isAdmin, onChangeUI }) => {
  const [isOpen, setOpen] = useState(false);
  const modalRef = useRef<Modal>();
  const history = useHistory();
  const theme = useTheme() as Theme;
  const [isAuthed, setIsAuthed] = React.useState<boolean>(false);

  const { t } = useTranslation();
  const close = async () => {
    await modalRef.current.animateOut();
    setTimeout(() => setOpen(false));
  };

  const LanguageItems = ({ title }) => {
    const [isOpen, setOpen] = useState(false);
    return (
      <Group>
        <>
          <span onClick={() => setOpen(!isOpen)}>{title}</span>
          <button onClick={() => setOpen(!isOpen)} tabIndex={0} aria-expanded={isOpen}>
            <ExpandArrow isOpen={isOpen} />
          </button>
        </>
        {isOpen && <Languages />}
      </Group>
    );
  };

  React.useEffect(() => {
    setIsAuthed(AuthService.isAuthenticated());
    history.listen(() => setIsAuthed(AuthService.isAuthenticated()));
  }, [history]);

  return (
    <>
      {isAuthed && (
        <BurgerButton
          size={height}
          onClick={!isOpen ? () => setOpen(true) : undefined}
          aria-expanded={isOpen}
          aria-haspopup
          aria-controls="navigation-menu"
        >
          <svg viewBox="0 0 16 16" width="26" height="26">
            {isOpen ? (
              <path fill="none" stroke="currentColor" strokeLinecap="square" strokeWidth="2" d="M2 2l12 12m-12 0l12-12" />
            ) : (
              <path fill="currentColor" d="M0 2h16v2H0zM0 7h16v2H0zM0 12h16v2H0z" />
            )}
          </svg>
        </BurgerButton>
      )}
      {isOpen && (
        <Modal ref={modalRef}>
          <ItemList positionY={height} id="navigation-menu">
            <FocusOn
              scrollLock={false}
              className="focus-lock"
              onClickOutside={close}
              onEscapeKey={close}
              autoFocus
              returnFocus
            >
              {menuItems &&
                Object.keys(menuItems).map((itemKey) => (
                  <GroupItem
                    key={itemKey}
                    title={t(`header.${itemKey}.title`)}
                    items={menuItems[itemKey].children}
                    parent={itemKey}
                  ></GroupItem>
                ))}
              <HeaderItems onCloseMenu={close} />

              {menuItems && (
                <hr
                  style={{
                    width: '100%',
                    borderColor: theme.colors.regionColorLight,
                    margin: '2rem 0',
                  }}
                />
              )}

              {menuItems !== null && <LanguageItems title={t(`header.languages.title`)}></LanguageItems>}

              {process.env.REACT_APP_DEBUG && isAdmin && (
                <ExitLogo style={{ marginLeft: '-10px' }} onClick={onChangeUI}>
                  <i className="pi pi-arrow-right-arrow-left" style={{ marginLeft: '0.7rem', marginRight: '0.7rem' }} />
                  <div style={{ paddingLeft: '5px' }}>{'Switch to new UI (debug only)'}</div>
                </ExitLogo>
              )}

              <ExitLogo
                style={{ marginLeft: '-10px' }}
                onClick={() => {
                  try {
                    AuthService.logout(history);
                  } catch {
                    history.push(EpdRoutes.login);
                  } finally {
                    setOpen(false);
                  }
                }}
              >
                <img src={exit} alt="Log out" />
                <div style={{ paddingLeft: '5px' }}>{t(`logout.title`)}</div>
              </ExitLogo>
            </FocusOn>
          </ItemList>
        </Modal>
      )}
    </>
  );
};

const ExitLogo = styled.div`
  display: flex;
  margin-top: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  > img {
    margin: 0 0.7rem;
    height: 20px;
    padding-top: 0.1rem;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(86deg) brightness(104%) contrast(102%);
  }
`;

const HeaderItems: React.FunctionComponent = ({ onCloseMenu }: { onCloseMenu: () => Promise<void> }) => {
  return <></>;
};

const GroupItem = ({ title, href, items, parent }) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Group>
      {isEmpty ? (
        <>
          <span onClick={() => setOpen(!isOpen)}>{title}</span>
          <button onClick={() => setOpen(!isOpen)} tabIndex={0} aria-expanded={isOpen}>
            <ExpandArrow isOpen={isOpen} />
          </button>
        </>
      ) : (
        <Link href={href}>{title}</Link>
      )}
      {isOpen && (
        <ul>
          {Object.keys(items).map((key) => (
            <li key={key}>
              <a href={items[key]}>{t(`header.${parent}.children.${key}`)}</a>
            </li>
          ))}
        </ul>
      )}
    </Group>
  );
};

const Group = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 0 auto;
  cursor: pointer;
  > a,
  > span {
    flex: 1 0 auto;
    ${(props) => props.theme.fonts.heading2};
    padding: 0.5rem 0;
    color: white;
    text-decoration: none;
  }

  > button {
    flex: 0 1 auto;
    color: white;
    margin: 0;
    padding: 0.83rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
  }

  ul {
    padding: 0;
    width: 100%;
    ${(props) => props.theme.fonts.text}

    & > li {
      list-style: none;
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.gray};

        :hover,
        :focus {
          outline: none;
          color: white;
        }
      }

      &:last-of-type {
        margin-bottom: 1.5rem;
      }
    }
  }
`;

const ItemList = styled.ul<{ positionY: number }>`
  margin: 0;
  margin-top: ${(props) => props.positionY}px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: white;
  background-color: ${(props) => props.theme.colors.regionColorMedium};
  flex: 1;
  width: min(50vw, 400px);
  transition: margin-top 150ms;
  overflow-y: scroll;

  > div > a {
    padding: 1rem 0.5rem;
  }

  @media (max-width: 500px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

const BurgerButton = styled.button<{ size: number }>`
  color: white;
  background-color: ${(props) => props.theme.colors.regionColorMedium};
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  transition: 150ms;
  min-width: ${(props) => props.size}px;

  &:hover {
    background-color: ${(props) => props.theme.colors.regionColor};
  }

  &:focus {
    outline: none;
  }
`;
