import CompilerSelectControlledComponent from 'components/v2/epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextInputControlledComponent from 'components/v2/epd-wizard-inputs/WizardTextInputControlled';
import { ExpandableHelpBox } from 'components/v2/help-boxes';
import TooltipHelpIcon from 'components/v2/icons/TooltipHelpIcon';
import React, { ReactNode } from 'react';
import { PreverifiedTool, PreverifiedToolVersion, VerificationType } from 'services/EpdClient';
import {
  addEpdFile,
  useAddEPDProcessCertificate,
  useRemoveEPDProcessCertificate,
  useUpdateEpdThirdPartyVerification,
} from 'services/api/mutations';
import {
  ElementWithHelpBox,
  ExpandablePanelHeaderName,
  FieldPanelFullWidth,
  FieldPanelHeader,
  FieldPanelInput,
  HelpBoldText,
} from 'styles/v2/Styles.styled';
import { EPDVerifierModel, Option, ThirdPartyVerificationModel, VerifierType } from 'types/types';
import getVerificationTypeLabel from 'util/getVerificationTypeLabel';

import { YesNoDataSource } from '../../constants';
import ToggleButton from '../toggleable/ToggleButton';
import CertificateSelector from './certificate-selector/CertificateSelector';
import CertificationBodySelector from './certification-body-selector/CertificationBodySelector';
import EpdVerifierSelector from './epd-verifier-selector/EpdVerifierSelector';

const ThirdPartyVerificationPanel: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  thirdPartyVerification: ThirdPartyVerificationModel;
  validationState: any;
}> = ({ epdId, epdVersionId, thirdPartyVerification, validationState }) => {
  const updateMutation = useUpdateEpdThirdPartyVerification(epdVersionId);
  const errors = validationState.errors;

  const addProcessCertificateMutation = useAddEPDProcessCertificate(epdVersionId);
  const deleteProcessCertificateMutation = useRemoveEPDProcessCertificate(epdVersionId);

  const onChangeEpdThirdPartyVerification = async (propertyName: string, val: any) => {
    updateMutation.mutate({ epdId, versionId: epdVersionId, propertyName: propertyName, newValue: val });
  };

  const onAddEpdProcessCertificate = async (propertyName: string, file: File) => {
    await addEpdFile({ epdId, propertyName, file }, addProcessCertificateMutation);
  };

  const onRemoveProcessCertificate = async (_: string, fileId: string) => {
    deleteProcessCertificateMutation.mutate(fileId);
  };

  const handleVerifierChange = async (_: string, value: any) => {
    if (!value) {
      onChangeEpdThirdPartyVerification('epdVerifier', undefined);
      return;
    }
    onChangeEpdThirdPartyVerification('epdVerifier', value);
  };

  const handleCertificateIssuedByChange = async (_: string, value: any) => {
    if (!value) {
      onChangeEpdThirdPartyVerification('processCertificateIssuedBy', undefined);
      return;
    }
    onChangeEpdThirdPartyVerification('processCertificateIssuedBy', value);
  };

  const selectedVerificationType = thirdPartyVerification.verificationType
    ? {
        value: thirdPartyVerification.verificationType,
        label: getVerificationTypeLabel(thirdPartyVerification.verificationType),
      }
    : null;

  const selectedPreverifiedTool = thirdPartyVerification.preverifiedTool
    ? {
        value: thirdPartyVerification.preverifiedTool.id,
        label: thirdPartyVerification.preverifiedTool.name,
      }
    : null;

  const preverifiedToolVersions = selectedPreverifiedTool
    ? thirdPartyVerification.preVerifiedToolDataSource
        ?.find((t) => t.id === selectedPreverifiedTool.value)
        ?.preverifiedToolVersions?.map((toolVersion: PreverifiedToolVersion) => {
          return {
            value: toolVersion.id,
            label: toolVersion.name,
          } as Option;
        })
    : [];

  const selectedPreverifiedToolVersion = thirdPartyVerification.preverifiedToolVersion
    ? {
        value: thirdPartyVerification.preverifiedToolVersion.id,
        label: thirdPartyVerification.preverifiedToolVersion.name,
      }
    : null;

  const renderVerificationTypeInputs = () => {
    if (!thirdPartyVerification.verificationTypeDataSource || !selectedVerificationType?.value) return;
    switch (selectedVerificationType.value) {
      case VerificationType.EPDVerification:
        return renderIndividualVerifierInputs();
      case VerificationType.EPDProcessCertification:
        return renderProcessCertificationInputs();
      case VerificationType.EPDPreverifiedTool:
        return renderPreverifiedToolInputs();
      default:
        throw new Error('Invalid verification type has been passed.');
    }
  };

  const getApprovedOrAccreditedByLabel = (verifier: EPDVerifierModel | undefined) => {
    return verifier?.verifierType === VerifierType.Body
      ? 'Certification body accredited by'
      : 'Individual verifier approved by';
  };

  const getApprovedOrAccreditedByValue = (verifier: EPDVerifierModel | undefined) => {
    return verifier
      ? verifier?.verifierType === VerifierType.Body
        ? verifier?.information
        : 'The International EPD® System'
      : '';
  };

  const headerTemplate = (options: any, header: ReactNode, tooltip?: string, tooltipPosition?: any | undefined) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          {header}
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
        </ExpandablePanelHeaderName>
        <ToggleButton options={options} withMargins />
      </div>
    );
  };

  const renderIndividualVerifierInputs = () => (
    <>
      <FieldPanelInput>
        <EpdVerifierSelector
          epdId={epdId}
          epdVersionId={epdVersionId}
          epdVerifier={thirdPartyVerification.epdVerifier}
          onChangeVerifier={handleVerifierChange}
          errors={errors}
        />
      </FieldPanelInput>
      {thirdPartyVerification.epdVerifier?.verifierType === VerifierType.Body && (
        <FieldPanelInput>
          <WizardTextInputControlledComponent
            label="Accredited certification body address"
            name="bodyAccreditedAddress"
            tooltip="Accredited certification body address"
            value={
              thirdPartyVerification.epdVerifier.companyAddress
                ? [
                    thirdPartyVerification.epdVerifier.companyAddress.country,
                    thirdPartyVerification.epdVerifier.companyAddress.city,
                    thirdPartyVerification.epdVerifier.companyAddress.zipCode,
                    thirdPartyVerification.epdVerifier.companyAddress.addressLine,
                  ]
                    .filter((value: string | null | undefined) => !!value && value.trim() !== '')
                    .join(', ')
                : ''
            }
            readOnly
            disabled
          />
        </FieldPanelInput>
      )}
      <FieldPanelInput>
        <WizardTextInputControlledComponent
          label={getApprovedOrAccreditedByLabel(thirdPartyVerification?.epdVerifier)}
          name="verifierApprovedBy"
          value={getApprovedOrAccreditedByValue(thirdPartyVerification?.epdVerifier)}
          readOnly
          disabled
        />
      </FieldPanelInput>
    </>
  );

  const renderProcessCertificationInputs = () => (
    <>
      <FieldPanelInput>
        <CertificateSelector
          processCertificates={thirdPartyVerification.processCertificates}
          onFileSelected={onAddEpdProcessCertificate}
          onRemoveCertificate={onRemoveProcessCertificate}
          error={errors.processCertificateReports}
          showUploadingIndicator={addProcessCertificateMutation.isPending}
        />
      </FieldPanelInput>
      <FieldPanelInput>
        <CertificationBodySelector
          epdId={epdId}
          epdVersionId={epdVersionId}
          processCertificateIssuedBy={thirdPartyVerification.processCertificateIssuedBy}
          onChangeBody={handleCertificateIssuedByChange}
          error={errors.thirdPartyVerification?.processCertificateIssuedBy}
        />
      </FieldPanelInput>
      <FieldPanelInput>
        <WizardTextInputControlledComponent
          label="Certification body accredited by"
          name="bodyAccreditedBy"
          tooltip="Certification body accredited by"
          value={
            thirdPartyVerification?.processCertificateIssuedBy
              ? thirdPartyVerification.processCertificateIssuedBy.information ?? 'The International EPD® System'
              : ''
          }
          readOnly
          disabled
        />
      </FieldPanelInput>
    </>
  );

  const renderPreverifiedToolVerifierInformation = () => {
    if (!thirdPartyVerification.preverifiedTool && !thirdPartyVerification.preverifiedToolVersion) {
      return null;
    }

    const preverifiedToolVerifierName =
      thirdPartyVerification.preverifiedToolVersion?.verifierName ?? thirdPartyVerification.preverifiedTool?.verifierName;

    if (!!thirdPartyVerification.preverifiedToolVerifierAccreditedBy) {
      return (
        <>
          <FieldPanelInput>
            <WizardTextInputControlledComponent
              label="Tool verifier"
              tooltip="Third-party verifier accountable for the tool verification."
              name="toolVerifier"
              value={preverifiedToolVerifierName}
              disabled
            />
          </FieldPanelInput>
          <FieldPanelInput>
            <WizardTextInputControlledComponent
              label="Tool verifier accredited by"
              name="preverifiedToolVerifierAccreditedBy"
              value={thirdPartyVerification.preverifiedToolVerifierAccreditedBy}
              disabled
            />
          </FieldPanelInput>
        </>
      );
    }

    return (
      <>
        <FieldPanelInput>
          <WizardTextInputControlledComponent
            label="Tool verifier"
            tooltip="Third-party verifier accountable for the tool verification."
            name="toolVerifier"
            value={preverifiedToolVerifierName}
            disabled
          />
        </FieldPanelInput>
        <FieldPanelInput>
          <WizardTextInputControlledComponent
            label="Tool verifier approved by"
            name="preverifiedToolVerifierApprovedBy"
            value={'The International EPD® System'}
            disabled
          />
        </FieldPanelInput>
      </>
    );
  };

  const renderPreverifiedToolInputs = () => (
    <>
      <FieldPanelInput>
        <CompilerSelectControlledComponent
          label="Pre-verified tool"
          tooltip="A pre-verified EPD tool contains data and calculation models to simplify the LCA calculation procedure based on a reference PCR. It is pre-verified to ensure that it produces correct data, given the correct input."
          name="preverifiedTool"
          options={
            thirdPartyVerification.preVerifiedToolDataSource?.map((tool: PreverifiedTool) => {
              return {
                value: tool.id,
                label: tool.name,
              } as Option;
            }) ?? []
          }
          value={selectedPreverifiedTool}
          onChanged={onChangeEpdThirdPartyVerification}
          placeholder="Select"
          isClearable={true}
          error={errors.preverifiedTool}
          required
        />
      </FieldPanelInput>
      <FieldPanelInput>
        <CompilerSelectControlledComponent
          label="Tool version"
          name="preverifiedToolVersion"
          options={preverifiedToolVersions}
          value={selectedPreverifiedToolVersion}
          onChanged={onChangeEpdThirdPartyVerification}
          placeholder="Select"
          isClearable={true}
          error={errors.preverifiedToolVersion}
          required
        />
      </FieldPanelInput>
      {renderPreverifiedToolVerifierInformation()}
      <FieldPanelInput>
        <EpdVerifierSelector
          epdId={epdId}
          epdVersionId={epdVersionId}
          epdVerifier={thirdPartyVerification.epdVerifier}
          onChangeVerifier={handleVerifierChange}
          errors={errors}
        />
      </FieldPanelInput>
      <FieldPanelInput>
        <WizardTextInputControlledComponent
          label={getApprovedOrAccreditedByLabel(thirdPartyVerification?.epdVerifier)}
          name="verifierApprovedBy"
          value={getApprovedOrAccreditedByValue(thirdPartyVerification?.epdVerifier)}
          readOnly
          disabled
        />
      </FieldPanelInput>
    </>
  );

  return (
    <FieldPanelFullWidth>
      <FieldPanelHeader>Third Party Verification</FieldPanelHeader>
      <FieldPanelInput>
        <CompilerSelectControlledComponent
          label="Verification type"
          tooltip="EPDs must be independently verified before registration and publication."
          name="verificationType"
          options={
            thirdPartyVerification.verificationTypeDataSource.map((value: VerificationType) => {
              return {
                value: value,
                label: getVerificationTypeLabel(value),
              } as Option;
            }) ?? []
          }
          value={selectedVerificationType}
          onChanged={onChangeEpdThirdPartyVerification}
          placeholder="Select"
          isClearable={true}
          required
        />
      </FieldPanelInput>
      {renderVerificationTypeInputs()}
      <ElementWithHelpBox>
        <CompilerSelectControlledComponent
          label="Procedure for follow-up of data during EPD validity involves third party verifier"
          name="followUpInvolvesThirdPartyVerifier"
          options={YesNoDataSource}
          value={YesNoDataSource.find((item) => item.value === thirdPartyVerification.followUpInvolvesThirdPartyVerifier)}
          onChanged={onChangeEpdThirdPartyVerification}
          isClearable={false}
          error={errors.thirdPartyVerification?.followUpInvolvesThirdPartyVerifier}
          required
        />
        <ExpandableHelpBox
          headerTemplate={(options) =>
            headerTemplate(options, <HelpBoldText>How procedure for follow-up of data works?</HelpBoldText>)
          }
          toggleable
        >
          <span>
            Note that procedure for follow-up the validity of the EPD is at minimum required once a year with the aim of
            confirming whether the information in the EPD remains valid or if the EPD needs to be updated during its validity
            period. The follow-up can be organised entirely by the EPD owner or together with the original verifier via an
            agreement between the two parties. In both approaches, the EPD owner is responsible for the procedure being
            carried out. If a change that requires an update is identified, the EPD shall be re-verified by a verifier.
          </span>
        </ExpandableHelpBox>
      </ElementWithHelpBox>
    </FieldPanelFullWidth>
  );
};

export default ThirdPartyVerificationPanel;
