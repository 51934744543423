import React from 'react';
import { VerificationType } from 'services/EpdClient';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { EPDBasedOnUnitEnum, VerifierType } from 'types/types';
import getSubtypeLabel from 'util/getSubtypeLabel';
import getVerificationTypeLabel from 'util/getVerificationTypeLabel';
import { formatDate } from 'util/utils';

import { CoverageScope } from '../../constants';
import VersionHistorySummaryGrid, { SummaryGridRow } from '../VersionHistorySummaryGrid';
import { useEpdVersionHistoryData } from '../utils/EpdVersionHistoryDataContext';

const NOT_ASSIGNED = 'N/A';

const VersionHistoryGeneralInformationGrid: React.FC<{}> = () => {
  const {
    epdDefinitionInformation,
    organizationInformation,
    organizationInformationAddress,
    pcrInformation,
    lcaSupportInformation,
    referenceFlow,
    conversionFactor,
    thirdPartyVerification,
    verificationReportInfo,
    additionalInformation,
    lcaSpecification,
    countries,
    dictionaries,
  } = useEpdVersionHistoryData();

  const possibleRows: (SummaryGridRow | false)[] = [
    {
      name: 'EPD name',
      value: epdDefinitionInformation.declarationName,
    },
    {
      name: 'UUID of data set',
      value: epdDefinitionInformation.id,
    },
    {
      name: 'EPD ID',
      value: epdDefinitionInformation.fullIdentificationNumber,
    },
    {
      name: 'EPD version',
      value: String(epdDefinitionInformation.versionNumber).padStart(3, '0'),
    },
    epdDefinitionInformation.versionNumber !== 1 && {
      name: 'Version description',
      value: epdDefinitionInformation.versionDescription ?? NOT_ASSIGNED,
    },
    {
      name: 'Single or multiple products',
      value: (() => {
        if (!epdDefinitionInformation.epdClassification) {
          return NOT_ASSIGNED;
        }
        return (
          dictionaries.find(
            (d) =>
              d.dictionaryCode === EPD_DICTIONARY_CODES.EPD_CLASSIFICATION &&
              d.key === epdDefinitionInformation.epdClassification
          )?.value ?? NOT_ASSIGNED
        );
      })(),
    },
    epdDefinitionInformation.epdClassification === CoverageScope.MULTIPLE_PRODUCTS_BASED_ON_REPRESENTATIVE_PRODUCT_ID && {
      name: 'Justification for why this is representative',
      value: epdDefinitionInformation.representativeJustification ?? NOT_ASSIGNED,
    },
    epdDefinitionInformation.epdClassification === CoverageScope.MULTIPLE_PRODUCTS_BASED_ON_AVG_RESULTS_ID && {
      name: 'Description of how the averages have been determined',
      value: epdDefinitionInformation.determinationOfAverageDescription ?? NOT_ASSIGNED,
    },
    epdDefinitionInformation.epdClassification === CoverageScope.SECTOR_OF_MULTIPLE_PRODUCTS_ID && {
      name: 'A list of the contributing manufacturers that the Sector EPD covers',
      value: epdDefinitionInformation.listOfContributingManufacturers ?? NOT_ASSIGNED,
    },
    epdDefinitionInformation.epdClassification === CoverageScope.SECTOR_OF_MULTIPLE_PRODUCTS_ID && {
      name: 'How the selection of the sites/products has been done and the average has been determined',
      value: epdDefinitionInformation.sitesSelectionDescription ?? NOT_ASSIGNED,
    },
    epdDefinitionInformation.epdClassification === CoverageScope.SECTOR_OF_MULTIPLE_PRODUCTS_ID && {
      name: 'A statement that the EPD covers average values for an entire or partial product category specifying the percentage of representativeness and, hence, the declared product is an average that is not available for purchase on the market and that the results are not representative for any specific manufacturer or its product.',
      value: epdDefinitionInformation.documentCoverageStatement ?? NOT_ASSIGNED,
    },
    {
      name: 'Product to declare',
      value: epdDefinitionInformation.productType || NOT_ASSIGNED,
    },
    {
      name: 'Statement of conformity with ISO',
      value: (() => {
        if (!epdDefinitionInformation.declaredStandards) {
          return NOT_ASSIGNED;
        }
        const standardsValues = epdDefinitionInformation.declaredStandards.split(',').map((x) => Number(x));
        if (standardsValues.length === 0) {
          return NOT_ASSIGNED;
        }
        const standards = standardsValues.map(
          (s) =>
            dictionaries.find((d) => d.dictionaryCode === EPD_DICTIONARY_CODES.CONFORMITY_STANDARDS && d.key === s)?.value ??
            NOT_ASSIGNED
        );
        return `In accordance with ${standards.join(', ')}`;
      })(),
    },
    {
      name: 'Keywords',
      value: epdDefinitionInformation.tags?.map((t) => t.label)?.join(', ') || NOT_ASSIGNED,
    },
    {
      name: 'Use advice for the EPD',
      value: epdDefinitionInformation.useAdvice ?? NOT_ASSIGNED,
    },
    {
      name: 'Subtype for ILCD+EPD',
      value: (epdDefinitionInformation.subtype && getSubtypeLabel(epdDefinitionInformation.subtype)) ?? NOT_ASSIGNED,
    },
    {
      name: 'EPD based on declared or functional unit',
      value: (() => {
        if (!lcaSpecification || !lcaSpecification.epdBasedOnUnit) {
          return NOT_ASSIGNED;
        }
        const index = Object.keys(EPDBasedOnUnitEnum).indexOf(lcaSpecification.epdBasedOnUnit);
        if (index === -1) {
          return 'Unsupported value';
        }
        const dictionaryEntry = dictionaries.find(
          (d) => d.dictionaryCode === EPD_DICTIONARY_CODES.EPD_BASED_ON_UNIT && d.key === index
        );
        if (!dictionaryEntry) {
          return NOT_ASSIGNED;
        }
        return dictionaryEntry.value;
      })(),
    },
    !!lcaSpecification &&
      lcaSpecification.epdBasedOnUnit === EPDBasedOnUnitEnum.FunctionalUnit && {
        name: 'Functional unit description',
        value: lcaSpecification.functionalUnitDescription ?? NOT_ASSIGNED,
      },
    !!lcaSpecification && {
      name:
        lcaSpecification.epdBasedOnUnit === EPDBasedOnUnitEnum.DeclaredUnit
          ? 'Declared unit and reference flow'
          : 'Reference flow',
      value: (() => {
        if (!referenceFlow) {
          return NOT_ASSIGNED;
        }

        const lines = [];
        if (referenceFlow.productFlowName) {
          lines.push(referenceFlow.productFlowName);
        }
        const unitEntry =
          referenceFlow.unitId &&
          dictionaries.find(
            (d) =>
              d.dictionaryCode === EPD_DICTIONARY_CODES.REFERENCE_FLOW_UNIT &&
              d.value.toUpperCase() === referenceFlow.unitId.toUpperCase()
          );
        if (unitEntry) {
          lines.push(`${unitEntry.description}, ${referenceFlow.value}`);
        }

        return lines.join('\n');
      })(),
    },
    {
      name: 'Conversion factor to mass',
      value: (() => {
        if (!conversionFactor) {
          return NOT_ASSIGNED;
        }

        return `${conversionFactor.value}`;
      })(),
    },
    {
      name: 'PCR',
      value: pcrInformation.pcr?.fullName ?? NOT_ASSIGNED,
    },
    !!pcrInformation.cpcr && {
      name: 'C-PCR',
      value: pcrInformation.cpcr.fullName ?? NOT_ASSIGNED,
    },
    !!pcrInformation.cpcr && {
      name: 'Choose product category',
      value: pcrInformation.cpcrProductCategory?.title ?? NOT_ASSIGNED,
    },
    {
      name: 'LCA practitioner',
      value: lcaSupportInformation.epdDevelopers?.map((d) => d.contactName).join(', ') ?? NOT_ASSIGNED,
    },
    {
      name: 'Verification type',
      value: thirdPartyVerification.verificationType
        ? getVerificationTypeLabel(thirdPartyVerification.verificationType)
        : NOT_ASSIGNED,
    },
    thirdPartyVerification.verificationType === VerificationType.EPDPreverifiedTool && {
      name: 'Pre-verified tool',
      value: thirdPartyVerification.preverifiedTool?.name ?? NOT_ASSIGNED,
    },
    thirdPartyVerification.verificationType === VerificationType.EPDPreverifiedTool && {
      name: 'Tool version',
      value: thirdPartyVerification.preverifiedToolVersion?.name ?? NOT_ASSIGNED,
    },
    {
      name: 'EPD verifier',
      value:
        (thirdPartyVerification.verificationType === VerificationType.EPDProcessCertification
          ? thirdPartyVerification.processCertificateIssuedBy?.homeCompanyName
          : thirdPartyVerification.epdVerifier?.displayName) ?? NOT_ASSIGNED,
    },
    thirdPartyVerification.epdVerifier?.verifierType === VerifierType.Body && {
      name: 'Accredited certification body address',
      value: thirdPartyVerification.epdVerifier.companyAddress
        ? [
            thirdPartyVerification.epdVerifier.companyAddress.country,
            thirdPartyVerification.epdVerifier.companyAddress.city,
            thirdPartyVerification.epdVerifier.companyAddress.zipCode,
            thirdPartyVerification.epdVerifier.companyAddress.addressLine,
          ]
            .filter((value: string | null | undefined) => !!value && value.trim() !== '')
            .join(', ')
        : NOT_ASSIGNED,
    },
    (thirdPartyVerification.verificationType === VerificationType.EPDVerification ||
      thirdPartyVerification.verificationType === VerificationType.EPDPreverifiedTool) &&
      !!thirdPartyVerification.epdVerifier && {
        name: thirdPartyVerification.epdVerifier.verifierType === VerifierType.Body ? 'Accredited by' : 'Approved by',
        value:
          thirdPartyVerification.epdVerifier.verifierType === VerifierType.Body
            ? thirdPartyVerification.epdVerifier.information ?? NOT_ASSIGNED
            : 'The International EPD® System',
      },
    thirdPartyVerification.verificationType === VerificationType.EPDProcessCertification &&
      !!thirdPartyVerification.processCertificateIssuedBy && {
        name: 'Accredited by',
        value: thirdPartyVerification.processCertificateIssuedBy.information ?? NOT_ASSIGNED,
      },
    {
      name: 'Procedure for follow-up of data during EPD validity involves third party verifier',
      value: (() => {
        if (!thirdPartyVerification.followUpInvolvesThirdPartyVerifier) {
          return NOT_ASSIGNED;
        }
        return thirdPartyVerification.followUpInvolvesThirdPartyVerifier;
      })(),
    },
    {
      name: 'EPD Owner',
      value: organizationInformation.companyName,
    },
    {
      name: 'Contact person e-mail',
      value: organizationInformation.contactPersonEmail ?? NOT_ASSIGNED,
    },
    {
      name: 'Contact person name',
      value: organizationInformation.contactPersonName ?? NOT_ASSIGNED,
    },
    {
      name: 'Organisation address',
      value: (() => {
        const details = [
          countries.find((c) => c.id === organizationInformationAddress.countryId)?.name,
          organizationInformationAddress.city,
          organizationInformationAddress.zipCode,
          organizationInformationAddress.addressLine,
          organizationInformationAddress.addressLineSecond,
        ]
          .filter((x) => x)
          .join(', ');
        return details || NOT_ASSIGNED;
      })(),
    },
    {
      name: 'Description of the organisation',
      value: organizationInformation.description ?? NOT_ASSIGNED,
    },
    {
      name: 'Additional environmental information',
      value: additionalInformation?.environmental || '',
      isHtmlRichText: true,
    },
    {
      name: 'Conversion factors',
      value: additionalInformation?.conversion || '',
      isHtmlRichText: true,
    },
    {
      name: 'Dangerous substance to indoor air, soil, and water during the use stage',
      value: additionalInformation?.dangerous || '',
      isHtmlRichText: true,
    },
    {
      name: 'Economic and social information',
      value: additionalInformation?.economic || '',
      isHtmlRichText: true,
    },
    {
      name: 'Version date',
      value: (verificationReportInfo.approvalDate && formatDate(verificationReportInfo.approvalDate)) ?? NOT_ASSIGNED,
    },
    {
      name: 'Validity date',
      value: (verificationReportInfo.validityDate && formatDate(verificationReportInfo.validityDate)) ?? NOT_ASSIGNED,
    },
    {
      name: 'Initial version date',
      value:
        (epdDefinitionInformation.registrationDate && formatDate(epdDefinitionInformation.registrationDate)) ?? NOT_ASSIGNED,
    },
  ];

  const rows = possibleRows.filter((r) => r) as SummaryGridRow[];

  return <VersionHistorySummaryGrid rows={rows} />;
};

export default VersionHistoryGeneralInformationGrid;
