import { Checkbox } from 'primereact/checkbox';
import React from 'react';
import { VerificationType } from 'services/EpdClient';
import { useGetEpdThirdPartyVerification } from 'services/api/queries';
import { EPDPDFModel, VerifierType } from 'types/types';
import DebugOnly from 'util/dev-tools/DebugOnly';
import RenderCounter from 'util/dev-tools/RenderCounter';

import { CheckBoxGroupPdf, PDF_H1, PDF_PAGE, PDF_Table } from './Styles.styled';

const ProgrammeInformationPage: React.FunctionComponent<{
  epdVersionId: string;
  e: EPDPDFModel;
}> = ({ epdVersionId, e }) => {
  const thirdPartyVerificationQ = useGetEpdThirdPartyVerification(epdVersionId);

  if (thirdPartyVerificationQ.isLoading || thirdPartyVerificationQ.isFetching) {
    return null;
  }

  const lcaAccountability =
    e.lcaSupportInformation.epdDevelopers
      ?.map((item) => `${item.contactName}, ${item.contactEmail}, ${item.companyName}`)
      .join(' and ') ?? '';

  return (
    <>
      <PDF_PAGE>
        <DebugOnly>
          <RenderCounter componentName="ProgrammeInformationPage" threshold={3} />
        </DebugOnly>
        <PDF_H1>Programme information</PDF_H1>
        <PDF_Table>
          <thead>
            <tr>
              <th style={{ width: '30%' }}></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Programme</td>
              <td>The International EPD® System</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                EPD International AB 0<br /> Box 210 60
                <br /> SE-100 31 Stockholm
                <br /> Sweden
              </td>
            </tr>
            <tr>
              <td>Website</td>
              <td>www.environdec.com</td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td>support@environdec.com</td>
            </tr>
          </tbody>
        </PDF_Table>
        <br />
        <PDF_H1>Product category rules</PDF_H1>
        <PDF_Table>
          <thead>
            <tr>
              <th style={{ width: '30%' }}></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={2}>
                CEN standard EN 15804 and ISO standard ISO 21930 serve as the core Product Category Rules (PCR)
              </td>
            </tr>
            <tr>
              <td>Product Category Rules (PCR)</td>
              <td>{e.pcrInformation?.pcr?.fullName}</td>
            </tr>
            <tr>
              <td>PCR review was conducted by</td>
              <td>
                PCR review was conducted by: The Technical Committee of the International EPD® System. See www.environdec.com
                for a list of members.
                <br />
                <br /> Review chair: Claudia A. Peña, University of Concepción, Chile. The review panel may be contacted via
                the Secretariat www.environdec.com/contact.
              </td>
            </tr>
            <tr>
              <td>Complementary Product Category Rules (c-PCR)</td>
              <td>
                {e.pcrInformation?.cpcr?.fullName}
                <br />
                Version: {e.pcrInformation?.cpcr?.version}
              </td>
            </tr>
            <tr>
              <td>c-PCR review was conducted by</td>
              <td>The Technical Committee of the International EPD@ System</td>
            </tr>
          </tbody>
        </PDF_Table>
        <br />
        <PDF_H1>Verification</PDF_H1>
        <PDF_Table>
          <thead>
            <tr>
              <th style={{ width: '30%' }}></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>LCA accountability</td>
              <td>{lcaAccountability}</td>
            </tr>
            <tr>
              <td>Independent third-party verification of the declaration and data, according to ISO 14025:2006, via</td>
              <td>
                <CheckBoxGroupPdf>
                  <Checkbox
                    inputId="1"
                    value="epdVerification"
                    checked={thirdPartyVerificationQ.data?.verificationType === VerificationType.EPDVerification}
                  />
                  <label>EPD verification through an individual EPD verification</label>
                </CheckBoxGroupPdf>
                <CheckBoxGroupPdf>
                  <Checkbox
                    inputId="2"
                    value="processCertification"
                    checked={thirdPartyVerificationQ.data?.verificationType === VerificationType.EPDProcessCertification}
                  />
                  <label>EPD verification through EPD Process Certification*</label>
                </CheckBoxGroupPdf>
                <CheckBoxGroupPdf>
                  <Checkbox
                    inputId="3"
                    value="preVerifiedTool"
                    checked={thirdPartyVerificationQ.data?.verificationType === VerificationType.EPDPreverifiedTool}
                  />
                  <label>EPD verification through a pre-verified LCA/EPD tool</label>
                </CheckBoxGroupPdf>
              </td>
            </tr>
            {(thirdPartyVerificationQ.data?.verificationType === VerificationType.EPDVerification ||
              thirdPartyVerificationQ.data?.verificationType === VerificationType.EPDPreverifiedTool) &&
              thirdPartyVerificationQ.data.epdVerifier && (
                <>
                  {thirdPartyVerificationQ.data.epdVerifier.verifierType === VerifierType.Individual && (
                    <>
                      <tr>
                        <td>Third-party verifier</td>
                        <td>{thirdPartyVerificationQ.data.epdVerifier.displayName}</td>
                      </tr>
                      <tr>
                        <td>Approved by</td>
                        <td>The International EPD® System</td>
                      </tr>
                    </>
                  )}
                  {thirdPartyVerificationQ.data.epdVerifier.verifierType === VerifierType.Body && (
                    <>
                      <tr>
                        <td>Third-party verifier</td>
                        <td>{thirdPartyVerificationQ.data.epdVerifier.companyName}</td>
                      </tr>
                      <tr>
                        <td>Accredited certification body address</td>
                        <td>
                          {[
                            thirdPartyVerificationQ.data.epdVerifier.companyAddress?.country,
                            thirdPartyVerificationQ.data.epdVerifier.companyAddress?.city,
                            thirdPartyVerificationQ.data.epdVerifier.companyAddress?.zipCode,
                            thirdPartyVerificationQ.data.epdVerifier.companyAddress?.addressLine,
                          ]
                            .filter((value: string | null | undefined) => !!value && value.trim() !== '')
                            .join(', ')}
                        </td>
                      </tr>
                      <tr>
                        <td>Accredited by</td>
                        <td>{thirdPartyVerificationQ.data.epdVerifier.information}</td>
                      </tr>
                    </>
                  )}
                </>
              )}
            {thirdPartyVerificationQ.data?.verificationType === VerificationType.EPDProcessCertification &&
              thirdPartyVerificationQ.data.processCertificateIssuedBy && (
                <>
                  <tr>
                    <td>EPD process certificate issued by</td>
                    <td>{thirdPartyVerificationQ?.data?.processCertificateIssuedBy?.homeCompanyName}</td>
                  </tr>
                  <tr>
                    <td>Accredited by</td>
                    <td>{thirdPartyVerificationQ?.data?.processCertificateIssuedBy?.information}</td>
                  </tr>
                </>
              )}
            <tr>
              <td>Procedure for follow-up of data during EPD validity involves third party verifier</td>
              <td>
                <CheckBoxGroupPdf>
                  <Checkbox
                    inputId="1"
                    value="Yes"
                    checked={thirdPartyVerificationQ.data?.followUpInvolvesThirdPartyVerifier === 'Yes'}
                  />
                  <label>Yes</label>
                </CheckBoxGroupPdf>
                <CheckBoxGroupPdf>
                  <Checkbox
                    inputId="2"
                    value="No"
                    checked={thirdPartyVerificationQ.data?.followUpInvolvesThirdPartyVerifier === 'No'}
                  />
                  <label>No</label>
                </CheckBoxGroupPdf>
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ backgroundColor: 'rgba(240, 240, 240, 1)', color: 'rgba(51, 51, 51, 1)' }}>
                *EPD Process Certification involves an accredited certification body certifying and periodically auditing the
                EPD process and conducting external and independent verification of EPDs that are regularly published. More
                information can be found in theGeneral Programme Instructions on www.envrondec.com. International EPD System
              </td>
            </tr>
          </tbody>
        </PDF_Table>
      </PDF_PAGE>
    </>
  );
};

export default ProgrammeInformationPage;
