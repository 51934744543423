import HelpIcon from 'components/icons/HelpIcon';
import MandatoryIcon from 'components/icons/MandatoryIcon';
import ConfirmModal from 'components/modals/ConfirmModal';
import OverlaySpinner from 'components/spinners/OverlaySpinner';
import { confirmAlert } from 'components/v2/confirm-dialog/ConfirmAlert';
import { ConfirmWithCommentPayload } from 'components/v2/dialogs/ConfirmWithCommentDialog';
import DeclinePublicationDialog from 'components/v2/dialogs/DeclinePublicationDialog';
import DeclineVerificationDialog from 'components/v2/dialogs/DeclineVerificationDialog';
import DepublishAdminDialog from 'components/v2/dialogs/DepublishAdminDialog';
import { ProcessStatus } from 'constants/constants';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { isEmpty } from 'lodash';
import { EpdStateButton } from 'pages/wizard/EpdStateButtons';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EpdLinks } from 'routes/EpdRoutes';
import { ActionState, EPDModel } from 'services/EpdClient';
import EpdStateService from 'services/EpdStateService';
import styled from 'styled-components';
import { ErrorMessage, H2 } from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';

type TProps = {
  epd: EPDModel;
  companyId: string;
  updateEpd: (state: {} | null) => void;
  epdVersions: EPDModel[];
  agreementConsent: boolean;
};

type VisibleDialogType =
  | 'cancelUpdate'
  | 'deleteDraft'
  | 'declineVerification'
  | 'declinePublication'
  | 'declinePublicationLicensee'
  | 'depublishByAdmin';

const ToolButtons = ({ epd, companyId, updateEpd, epdVersions, agreementConsent }: TProps) => {
  const { changeCompany } = useContext(CompanyContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [visibleDialog, setVisibleDialog] = useState<VisibleDialogType | null>(null);
  const [isDeregisterModalOpen, setIsDeregisterModalOpen] = useState<boolean>(false);
  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [status, setStatus] = useState<ProcessStatus>(ProcessStatus.None);

  const publishedVersion = epdVersions?.find((version) => version.versionDate === epd.publishedVersionDate);

  useEffect(() => {
    var showToastRegisterEditorial = localStorage.getItem('showToastRegisterEditorial');
    if (showToastRegisterEditorial === 'true') {
      toast.success(toastTemplate, { autoClose: false, style: { minWidth: '30rem' } });
      localStorage.setItem('showToastRegisterEditorial', 'false');
    }
  }, []);

  const changeDeregisterModalState = () => {
    setIsDeregisterModalOpen(!isDeregisterModalOpen);
  };

  const hideDialog = () => {
    setVisibleDialog(null);
  };

  const toastTemplate = (): ReactNode => {
    return (
      <div className="">
        <b>{'Thank you for registering your EPD with the International EPD System!'}</b>
        <p style={{ marginBottom: 0 }}>
          {
            'We will attend to it as soon as possible. We aim to publish the EPD within 1-3 days. If you have any questions feel free to contact us in the Secretariat at support@environdec.com.'
          }
        </p>
        <p style={{ marginBottom: 0 }}>
          {
            'If any information is missing or if any irregularities are recognized in the submitted material the support team will contact you.'
          }
        </p>
      </div>
    );
  };

  const onSendForPublicationEditorial = async (epdId: string) => {
    try {
      await EpdStateService.sendForPublicationEditorial(epdId, notes);
      localStorage.setItem('showToastRegisterEditorial', 'true');
      history.go(0);
    } catch (error) {
      setErrorMessage(PoorMansError(error));
    }
  };

  const onSendForPublication = async (epdId: string) => {
    try {
      await EpdStateService.sendForPublication(epdId);
      toast.success(toastTemplate, { autoClose: false, style: { minWidth: '30rem' } });
    } catch (error) {
      setErrorMessage(PoorMansError(error));
    }
  };

  const handleDeleteEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await EpdStateService.delete(epd.id);
      setStatus(ProcessStatus.Success);
      toast.success(
        t('messages.epdDeleted', {
          epdNumber: publishedVersion?.fullIdentificationNumber ?? epd?.shortIdentificationNumber,
        }) as string,
        {
          position: 'top-center',
        }
      );
      hideDialog();
      changeCompany?.(companyId);
      history.push(EpdLinks.dashboard());
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };

  const handleDeregister = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await EpdStateService.deregister(epd.id);
      setStatus(ProcessStatus.Success);
      changeDeregisterModalState();
      history.go(0);
      toast.success(
        t('messages.epdDeregister', {
          epdNumber: publishedVersion?.fullIdentificationNumber ?? epd?.shortIdentificationNumber,
        }) as string,
        {
          position: 'top-center',
        }
      );
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };

  const handleCancelUpdateEpd = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      await EpdStateService.cancelUpdate(epd.id);
      setStatus(ProcessStatus.Success);
      toast.success(t('messages.epdCancelled') as string, {
        position: 'top-center',
      });
      hideDialog();
      changeCompany?.(companyId);
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error), {
        position: 'top-center',
      });
    }
  };

  const handleCancelRegistrationClicked = () => {
    confirmAlert({
      description: (
        <span style={{ whiteSpace: 'pre-wrap' }}>
          {t('confirmModal.cancelRegistrationEPD', {
            name: `${epd.fullIdentificationNumber} ${epd.title}`,
          })}
        </span>
      ),
      acceptLabel: t('Submit'),
      onConfirm: handleCancelRegistrationConfirmed,
    });
  };

  const handleCancelRegistrationConfirmed = async () => {
    try {
      setStatus(ProcessStatus.Fetching);
      const result = await EpdStateService.cancelRegistration(epd.id);
      updateEpd(result);
      setStatus(ProcessStatus.Success);
      toast.success(
        t('messages.canceledRegistrationEpd', {
          name: `${epd.fullIdentificationNumber} ${epd.title}`,
        }) as string
      );
      hideDialog();
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error));
    }
  };

  const handleDeclineVerificationEpd = async ({ commentText }: ConfirmWithCommentPayload) => {
    try {
      setStatus(ProcessStatus.Fetching);
      await EpdStateService.declineVerification(epd.id, commentText);
      setStatus(ProcessStatus.Success);
      toast.success(
        t('messages.declinedVerification', {
          epdNumber: publishedVersion?.fullIdentificationNumber ?? epd?.shortIdentificationNumber,
        }) as string
      );
      hideDialog();
      changeCompany?.(companyId);
      history.push(EpdLinks.dashboard());
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error));
    }
  };

  const handleDeclinePublicationEpd = async (
    { commentText }: ConfirmWithCommentPayload,
    actor: 'bySuperAdmin' | 'byLicensee'
  ) => {
    try {
      setStatus(ProcessStatus.Fetching);
      if (actor === 'bySuperAdmin') {
        const result = await EpdStateService.declinePublication(epd.id, commentText);
        updateEpd(result);
      } else {
        const result = await EpdStateService.licenseeAdminVerificationDecline(epd.id, commentText);
        updateEpd(result);
      }
      setStatus(ProcessStatus.Success);
      toast.success(
        t('messages.declinedPublication', {
          epdNumber: publishedVersion?.fullIdentificationNumber ?? epd?.shortIdentificationNumber,
        }) as string
      );
      hideDialog();
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error));
    }
  };

  const handleDepublishEpdByAdmin = async ({ commentText }: ConfirmWithCommentPayload) => {
    try {
      setStatus(ProcessStatus.Fetching);
      const result = await EpdStateService.depublish(epd.id, commentText);
      updateEpd(result);
      setStatus(ProcessStatus.Success);
      toast.success(t('messages.epdDepublished', { epdNumber: publishedVersion?.fullIdentificationNumber }) as string);
      hideDialog();
    } catch (error) {
      setStatus(ProcessStatus.Error);
      toast.error(PoorMansError(error));
    }
  };

  const handlePublish = async () => {
    EpdStateService.publish(epd.versionId, epd?.id).then(() => updateEpd({}));
  };

  const now = new Date();

  const disableStart = new Date(now.getFullYear(), 11, 30, 0, 0, 0); // December 30, 00:00 local time
  const disableEnd = new Date(now.getFullYear(), 11, 31, 23, 59, 59); // December 31, 23:59 local time

  const publishingEpdIsDisabledDueToYearEnd = now >= disableStart && now <= disableEnd;

  const sendEpdForPublicationState =
    epd.epdState?.sendForPublication === ActionState.Enabled && (!agreementConsent || publishingEpdIsDisabledDueToYearEnd)
      ? ActionState.Disabled
      : epd.epdState?.sendForPublication;

  return (
    <>
      <ConfirmDialog />
      {status === ProcessStatus.Fetching && <OverlaySpinner />}
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'end' }}>
        <H2
          style={{
            margin: '1rem auto 1rem 1rem',
            minWidth: '20rem',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {epd?.name} -{' '}
          {epd.epdState && (
            <span style={{ fontWeight: 'normal' }}>{t(`epdWizard.epdState.status.${epd.epdState.statusDisplayName}`)}</span>
          )}
        </H2>
        <div>
          <ButtonGroup>
            <EpdStateButton
              title={t('epdWizard.epdState.action.cancelUpdate')}
              actionState={epd.epdState?.cancelUpdate}
              epdId={epd.id}
              callFunction={() => setVisibleDialog('cancelUpdate')}
              buttonStyle={{ background: 'grey' }}
            />
            {visibleDialog === 'cancelUpdate' && (
              <ConfirmModal
                description={t('confirmModal.cancelUpdateEPD')}
                confirmButtonText={t('Cancel update')}
                cancelButtonText={t('Close')}
                onClickConfirm={handleCancelUpdateEpd}
                onClickCancel={hideDialog}
              />
            )}
            <EpdStateButton
              title={t('epdWizard.epdState.action.delete')}
              actionState={epd.epdState?.deleteDraft}
              epdId={epd.id}
              callFunction={() => setVisibleDialog('deleteDraft')}
              buttonStyle={{ background: 'grey' }}
            />
            {visibleDialog === 'deleteDraft' && (
              <ConfirmModal
                description={t('confirmModal.deleteEPD', { name: epd.name })}
                confirmButtonText={t('Delete')}
                cancelButtonText={t('Close')}
                onClickConfirm={handleDeleteEpd}
                onClickCancel={hideDialog}
              />
            )}
            <EpdStateButton
              title={t('epdWizard.epdState.action.declineEpd')}
              actionState={epd.epdState?.declineVerification}
              epdId={epd.id}
              callFunction={() => setVisibleDialog('declineVerification')}
              buttonStyle={{ background: 'grey' }}
            />
            <DeclineVerificationDialog
              isOpened={visibleDialog === 'declineVerification'}
              epd={epd}
              onClose={(payload) => {
                hideDialog();
                if (payload) {
                  handleDeclineVerificationEpd(payload);
                }
              }}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.declineEpd')}
              actionState={epd.epdState?.declinePublication}
              epdId={epd.id}
              callFunction={() => setVisibleDialog('declinePublication')}
              buttonStyle={{ background: 'grey' }}
            />
            <DeclinePublicationDialog
              isOpened={visibleDialog === 'declinePublication'}
              epd={epd}
              onClose={(payload) => {
                hideDialog();
                if (payload) {
                  handleDeclinePublicationEpd(payload, 'bySuperAdmin');
                }
              }}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.startVerification')}
              actionState={epd.epdState?.startVerification}
              epdId={epd.id}
              callFunction={EpdStateService.startVerification}
              toastMessage={t('messages.startVerification')}
              setState={updateEpd}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.cancelVerification')}
              actionState={epd.epdState?.cancelVerification}
              epdId={epd.id}
              callFunction={EpdStateService.cancelVerification}
              toastMessage={t('messages.cancelVerification')}
              setState={updateEpd}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.cancelPublication')}
              actionState={epd.epdState?.cancelRegistration}
              epdId={epd.id}
              callFunction={handleCancelRegistrationClicked}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.sendToPublish')}
              actionState={sendEpdForPublicationState}
              epdId={epd.id}
              callFunction={onSendForPublication}
              setState={updateEpd}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.deregisterEpd')}
              actionState={epd.epdState?.deregister}
              epdId={epd.id}
              callFunction={changeDeregisterModalState}
              buttonStyle={{ background: 'grey' }}
            />
            {isDeregisterModalOpen && (
              <ConfirmModal
                description={t('confirmModal.deregisterEPD')}
                confirmButtonText={t('deregister')}
                cancelButtonText={t('cancel')}
                onClickConfirm={handleDeregister}
                onClickCancel={() => changeDeregisterModalState()}
              />
            )}
            <EpdStateButton
              title={t('epdWizard.epdState.action.cancelDeregistration')}
              actionState={epd.epdState?.cancelDeregistration}
              epdId={epd.id}
              callFunction={EpdStateService.cancelDeregisteration}
              toastMessage={t('messages.cancelDeregistration')}
              setState={updateEpd}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.updateEpd')}
              actionState={epd.epdState?.unlock}
              epdId={epd.id}
              callFunction={EpdStateService.unlock}
              setState={updateEpd}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.retractVerification')}
              actionState={epd.epdState?.retractVerification}
              epdId={epd.id}
              callFunction={EpdStateService.cancelApproveVerification}
              toastMessage={t('messages.retractVerification')}
              setState={updateEpd}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.approveEpd')}
              actionState={epd.epdState?.approveVerification}
              epdId={epd.id}
              callFunction={EpdStateService.approve}
              toastMessage={t('messages.approvedVerification', {
                epdNumber: publishedVersion?.fullIdentificationNumber ?? epd?.shortIdentificationNumber,
              })}
              setState={updateEpd}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.publishEpd')}
              actionState={epd.epdState?.publish}
              epdId={epd.id}
              toastMessage={t('messages.epdPublished', {
                epdNumber: epd?.fullIdentificationNumber ?? epd?.shortIdentificationNumber,
              })}
              callFunction={handlePublish}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.depublishEpd')}
              actionState={epd.epdState?.depublishByAdmin}
              epdId={epd.id}
              callFunction={() => setVisibleDialog('depublishByAdmin')}
              buttonStyle={{ background: 'grey' }}
            />
            <DepublishAdminDialog
              isOpened={visibleDialog === 'depublishByAdmin'}
              epd={epd}
              onClose={(payload) => {
                hideDialog();
                if (payload) {
                  handleDepublishEpdByAdmin(payload);
                }
              }}
            />
            <EpdStateButton
              title={t('epdWizard.epdState.action.depublishEpd')}
              actionState={epd.epdState?.depublishByCustomer}
              epdId={epd.id}
              callFunction={EpdStateService.depublish}
              toastMessage={t('messages.epdDepublished', { epdNumber: publishedVersion?.fullIdentificationNumber })}
              setState={updateEpd}
              buttonStyle={{ background: 'grey' }}
            />
            {epd.epdState?.sendForPublicationEditorial !== ActionState.Hidden && (
              <ButtonGroup>
                <EditorialUpdateLabel>
                  {t('epdWizard.epdState.action.registerEditorial')}
                  <HelpIcon content={t('epdWizard.tooltips.editorialChanges')} />
                  {isEmpty(notes) && <MandatoryIcon />}
                </EditorialUpdateLabel>
                <TextArea
                  placeholder={t('epdWizard.placeholder.registerEditorial')}
                  onChange={(e) => setNotes(e.target.value)}
                />
                <EpdStateButton
                  buttonStyle={{ marginTop: '0' }}
                  title={t('epdWizard.epdState.action.sendToPublish')}
                  actionState={isEmpty(notes) ? ActionState.Disabled : epd.epdState?.sendForPublicationEditorial}
                  epdId={epd.id}
                  callFunction={onSendForPublicationEditorial}
                  toastMessage={t('messages.registerEditorialUpdate')}
                />
              </ButtonGroup>
            )}{' '}
            {epd.epdState?.licenseeAdminVerification === ActionState.Enabled && (
              <ButtonGroup>
                <EpdStateButton
                  title={t('epdWizard.epdState.action.licenseeAdminVerificationApprove')}
                  actionState={epd.epdState?.licenseeAdminVerification}
                  epdId={epd.id}
                  setState={updateEpd}
                  toastMessage={t('messages.approveEpdChanges')}
                  callFunction={EpdStateService.licenseeAdminVerificationApprove}
                />
                <EpdStateButton
                  title={t('epdWizard.epdState.action.licenseeAdminVerificationDecline')}
                  actionState={epd.epdState?.licenseeAdminVerification}
                  epdId={epd.id}
                  callFunction={() => setVisibleDialog('declinePublicationLicensee')}
                  buttonStyle={{ background: 'grey' }}
                />
                <DeclinePublicationDialog
                  isOpened={visibleDialog === 'declinePublicationLicensee'}
                  epd={epd}
                  onClose={(payload) => {
                    hideDialog();
                    if (payload) {
                      handleDeclinePublicationEpd(payload, 'byLicensee');
                    }
                  }}
                />
              </ButtonGroup>
            )}
            {errorMessage && <ErrorMessage style={{ marginTop: '1rem' }}> {errorMessage}</ErrorMessage>}
          </ButtonGroup>
        </div>
      </div>
    </>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
`;

const EditorialUpdateLabel = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: left;
  ${(props) => props.theme.fonts.text};
  font-size: 0.96rem;
`;

const TextArea = styled.textarea`
  cursor: pointer;
  box-sizing: border-box;
  margin-left: 1rem;
  padding: 0.5rem;
  border: solid 1px gray;
  min-width: 28rem;
  background-color: #f4f4f4;
  height: 3rem;
  :focus {
    outline: none;
  }
`;

export default ToolButtons;
