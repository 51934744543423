import { GenericAbortSignal } from 'axios';
import {
  ActionState,
  FileModel,
  FileModelPlus,
  GeographicalScopeModel,
  PCRModel,
  PreverifiedTool,
  PreverifiedToolVersion,
  ProductClassificationModel,
  ProductMaterialProperyModel,
  Subtype,
  VerificationType,
} from 'services/EpdClient';

export type BaseQueryParams = {
  signal: GenericAbortSignal;
};

export type Option = {
  label: string;
  value: string;
};

export type UserOptionModel = Option & {
  userName: string;
};

export type OptionNameCode = {
  name: string;
  code: string;
};

export type LCAModules<T> = {
  a1?: T;
  a2?: T;
  a3?: T;
  a1A2A3?: T;
  a4?: T;
  a5?: T;
  b1?: T;
  b2?: T;
  b3?: T;
  b4?: T;
  b5?: T;
  b6?: T;
  b7?: T;
  c1?: T;
  c2?: T;
  c3?: T;
  c4?: T;
  d?: T;
  variation?: T;
};

export type LcaResultsViewModel = {
  lcaResults: LcaResultModel[];
};

export type LcaResultModel = LCAModules<string> & {
  id?: string | null;
  epdVersionId: string | null;
  indicatorGroupCode?: string;
  ord: number;
  indicatorId?: string;
  indicatorName?: string;
  impactCategory?: string;
  unit?: string;
};

export type LcaCustomResultModel = IVersionedEntity & {
  resultType: string;
  bestCaseProduct: string;
  worstCaseProduct: string;
  scenarioId?: string;
  scenarioName?: string;
  scenarioDescription: string;
  gridAcronyms: string;
  gridDisclaimers: string;
};

export type LcaCustomResultGridDataModel = LCAModules<string> & {
  id?: string | null;
  lcaCustomResultId: string;
  ord: number;
  indicatorName?: string;
  impactCategory?: string;
  unit?: string;
};

export interface LCAResultAdditionalVoluntaryAcronymsModel {
  acronyms: string | null;
}

export type CpcrPerformanceTableModel = {
  ord: number;
  tableCode: string;
  tableTitle: string;
  isFixed: boolean;
  tableDescription?: string;
};

export type CpcrPerformanceInformationModel = {
  tables: CpcrPerformanceTableModel[];
  columns: any[];
  data: CpcrPerformanceTableDataModel[];
};

export type CpcrPerformanceTableDataModel = {
  id?: string | null;
  tableCode: string;
  ord: number;
  a1?: string | null;
  a1Placeholder?: string | null;
  a2?: string | null;
  a2Placeholder?: string | null;
  a3?: string | null;
  a3Placeholder?: string | null;
  a4?: string | null;
  a5?: string | null;
  a6?: string | null;
  a7?: string | null;
  a8?: string | null;
  a9?: string | null;
  a10?: string | null;
  a11?: string | null;
  a12?: string | null;
  a13?: string | null;
  a14?: string | null;
  a15?: string | null;
  rowClassName?: string | null;
};

export type ProductComponent = {
  id?: string;
  epdVersionId: string;
  declareContentType: EPDContentDeclarationProductType;
  ord: number;
  name?: string;
  weight?: number;
  finalWeightPercent?: number;
  biogenicWeightPercent?: number;
  biogenicCarbonWeight?: number;
  biogenicCO2Weight?: number;
};

export type ProductPackagingMaterial = {
  id?: string;
  epdVersionId: string;
  declareContentType: EPDContentDeclarationProductType;
  ord: number;
  name?: string;
  weight?: number;
  weightPercent?: number;
  biogenicCarbonWeight?: number;
  biogenicCO2Weight?: number;
};

export type ProductPanelContent = {
  id?: string;
  epdVersionId: string;
  declareContentType: EPDContentDeclarationProductType;
  ord: number;
  name?: string;
  weight?: number;
  weightPercent?: number;
  biogenicCarbonWeight?: number;
  biogenicCO2Weight?: number;
};

export type ProductDangerMaterial = {
  id?: string;
  epdVersionId: string;
  declareContentType?: EPDContentDeclarationProductType;
  ord: number;
  dangerMaterialLookupId?: string;
  name?: string;
  ecNo?: string;
  casNo?: string;
  weightPercent?: number | null;
};

export type DangerMaterialLookup = {
  id: string;
  name: string;
};

export type ElectricityDataModel = {
  epdVersionId?: string;
  typeOfElectricityMix?: number;
  climateImpact?: number;
  energySourceModel?: EnergySourceModel[];
};

export type EnergySourceModel = {
  id?: string;
  source?: string;
  value?: number | undefined;
};

export type DeclarationOfCO2AssumptionModel = {
  assumptions?: string;
  isSelected: boolean;
};

export type DeclarationOfCO2AssumptionModelWithParams = {
  epdVersionId: string;
  nameId: string;
  assumptions?: string;
  isSelected: boolean;
};

export type DeclarationOfCO2Model = {
  epdVersionId?: string;
  nameId: string;
  name?: string;
  assumptions?: string;
  isSelected: boolean;
};

export type CO2UptakeAssociationModelWithParams = {
  epdVersionId: string;
  id?: string;
  association?: string;
};

export type CO2UptakeAssociationModel = {
  id?: string;
  association?: string;
};

export type ScrapInputModel = {
  id?: string;
  epdVersionId?: string;
  name?: string;
  value?: number;
  ord: number;
};

export type ShareOfTotalScrapInputModel = {
  id?: string;
  epdVersionId?: string;
  sharePercent?: number;
};

export type ScenarioModuleReadModel = {
  defaultScenarioModuleExplanatoryName?: string;
  defaultScenarioModuleBriefDescription?: string;
  defaultScenarioModuleDescription?: string;
  scenarioModuleItems: ScenarioModuleItemModel[];
};

export type ScenarioItemModel = IVersionedEntity & {
  name?: string;
  description?: string;
  value?: string;
  isDefaultScenario: boolean;
  enabledRSL?: boolean;
};

export type SystemBoundaryModel = IVersionedEntity & {
  shareOfSpecificData?: number;
  variationProducts?: number;
  variationSites?: number;
  description?: number;
  hasExcludedModules?: boolean;
  excludedModulesJustification?: string;
  processFlowDiagramsDescription?: string;
  processFlowDiagramsImages: FileModelPlus[];
};

export type SystemBoundaryGridModel = {
  epdVersionId: string;
  declaredModules: LCAModules<boolean | undefined | null> | null;
  declaredModulesGeography: LCAModules<string> | null;
};

export type UpdateDeclaredModulesModel = {
  epdVersionId: string;
  fieldName: string;
  value: boolean | null;
};

export type UpdateDeclaredModulesGeographyModel = {
  epdVersionId: string;
  fieldName: string;
  value: string | null;
};

export type ScenarioModuleItemModel = {
  id?: string | null;
  scenarioId: string;
  module: string;
  ord: number;
  description?: string | undefined | null;
  value?: string | undefined | null;
  unitId?: string | undefined | null;
  placeholder?: string | undefined | null;
  unitRequired?: boolean | undefined | null;
  mandatory?: boolean | undefined | null;
};

export type ScenarioModuleRequest = {
  epdVersionId: string;
  scenarioId: string;
  module: string;
};

export type ScenarioModulesRequest = {
  epdVersionId: string;
  scenarioId: string;
  modules: string[];
};

export type ScenarioModuleDefaultDescriptionModel = ScenarioModuleRequest & {
  defaultScenarioModuleExplanatoryName: string;
  defaultScenarioModuleBriefDescription: string;
  defaultDescription: string;
};

export type TransportationPerformanceModel = {
  id?: string;
  epdVersionId?: string;
  transportationAverageCarLoad?: string;
  transportationPerformance?: string;
  transportationServiceLifeDistance?: string;
};

export type DictionaryValue = {
  id: string;
  dictionaryCode: string;
  key: number;
  value: string;
  code: string;
  description: string;
};

export type DictionaryCatalogItem = {
  id?: string;
  name: string;
  code: string;
  description: string;
};

export type DictionaryValues = DictionaryValue[];
export type DictionaryCatalog = DictionaryCatalogItem[];
export type LCASpecificationModel = {
  id: string;
  epdVersionId?: string;
  shouldDeclarationsBeFetched: boolean;
  shouldTransportationPerformanceBeFetched: boolean;
  epdBasedOnUnit?: EPDBasedOnUnitEnum;
  functionalUnitDescription?: string;
  scrapInputsContributionLevel?: ScrapInputsContributionLevelEnum;
  lcaSoftware?: string;
  lcaSoftwareVersion?: string;
  lcaBasedDescription?: string;
  characterisationMethods?: string;
  technologyDescription?: string;
  softwareName?: string;
  softwareVersion?: string;
};

export type InfrastructureAndCapitalGoodsModel = {
  includedInProcesses?: string;
  contributionLevel?: string;
  description?: string;
};

export type ReferenceFlowModel = {
  epdVersionId?: string;
  productFlowName: string;
  unitId: string;
  value: number;
  errors: any;
};

export type ConversionFactorModel = {
  epdVersionId?: string;
  unitId: string;
  value: number;
  errors: any;
};

export type EPDDataSourceModel = {
  id: string;
  epdVersionId: string;
  dataSourceId?: string;
  dataSourceVersionId?: string;
  isMain: boolean;
};

export type DataSourceOptionModel = {
  id: string;
  name?: string;
  dataSourceVersions: Option[];
};

export type ReferencePackageVersion15804 = {
  id: string;
  name: string;
};

export type LCASoftwareOptionModel = {
  id: string;
  name?: string;
  versionRequired?: boolean;
  lcaSoftwareVersions: Option[];
};

export enum EPDProductType {
  Goods = 'Goods',
  Services = 'Services',
}

export enum EPDBasedOnUnitEnum {
  DeclaredUnit = 'DeclaredUnit',
  FunctionalUnit = 'FunctionalUnit',
}

export enum ScrapInputsContributionLevelEnum {
  MorePermissiblePercent = 'MorePermissiblePercent',
  LessPermissiblePercent = 'LessPermissiblePercent',
}

export type TagModel = {
  id: string;
  name: string;
};

export interface VerificationViewModel {
  verifierInfo: EPDVerifierModel;
  verificationReports: FileModel[];
  verificationType: VerificationType;
  approvalDate: Date;
  epdValidFrom: Date;
  validityDate: Date;
}

interface ValidationErrors {
  [key: string]: ValidationErrors | string;
}

export interface CompilerCompositeStatusModel {
  isValid: boolean;
  errors: ValidationErrors;
  fieldsState: Record<string, ActionState>;
  actionsStatus: CompilerActionsStatusModel;
}

export interface CompilerActionsStatusModel {
  isMandatoryComplete: boolean;
  isVerifierInvolved: boolean;
  deleteDraft: ActionState;
  cancelUpdate: ActionState;
  startVerification: ActionState;
  cancelVerification: ActionState;
  sendForPublication: ActionState;
  cancelRegistration: ActionState;
  deregister: ActionState;
  unlock: ActionState;
  cancelDeregistration: ActionState;
  approveVerification: ActionState;
  declineVerification: ActionState;
  retractVerification: ActionState;
  publish: ActionState;
  previewPdf: ActionState;
  downloadPdf: ActionState;
  declinePublication: ActionState;
  depublishByAdmin: ActionState;
  depublishByCustomer: ActionState;
  sendForPublicationEditorial: ActionState;
  editorialUpdating: ActionState;
  licenseeAdminVerification: ActionState;
  statusDisplayName: string;
  statusMessageCode: string;
}

export interface IVersionedEntity {
  id: string;
  epdVersionId: string;
}

export type EPDDefinitionModel = IVersionedEntity & {
  useAdvice: string;
  subtype?: Subtype;
  tags?: Option[];
  versionNumber: number;
  registrationNumber: string;
  fullIdentificationNumber: string;
  registrationDate?: string;
  publicVersionDate?: string;
  publishedVersionDate?: string;
  declarationName: string;
  serviceIncludesProductInSystemBoundary?: string;
  declarationIrrelevanceExplanation?: string;
  productType: EPDProductType;
  productName?: string;
  collections?: Option[];
  epdClassification: number | null;
  declaredStandards?: string;
  referencePackageVersion15804?: string;
  characterisationMethods?: string;
  representativeJustification?: string;
  determinationOfAverageDescription?: string;
  listOfContributingManufacturers?: string;
  sitesSelectionDescription?: string;
  documentCoverageStatement?: string;
  isDefaultISOConformityApplied: boolean;
  serviceAgreementStatus: boolean;
  friendlyUrl: string;
  epdVersions: VersionHistoryModel[];
  versionDescription?: string;
};

export type VersionHistoryModel = {
  id: string;
  versionDate: string | null;
  fullIdentificationNumber: string;
};

export type EPDDeclareContentProductModel = {
  id?: string;
  epdVersionId?: string;
  productDescription?: string;
  type: EPDContentDeclarationProductType;
  hasDangerMaterials?: boolean;
};

export interface UpdateEPDContentDeclarationProductModel {
  type: EPDContentDeclarationProductType;
  versionId: string;
  propertyName: string;
  newValue: any;
}

export type EPDOrganizationInformationModel = IVersionedEntity & {
  name?: string;
  companyName: string;
  contactPersonEmail?: string;
  contactPersonName?: string;
  description?: string;
  organizationImages?: FileModelPlus[];
};

export type EPDProductInformationModel = {
  id?: string;
  epdVersionId: string;
  productName?: string;
  productIdentification?: string;
  productDescription?: string;
  productInformationExternal?: string;
  productionSites?: FoundationAddressModel[];
  productGeographicalScopes?: GeographicalScopeModel[];
  productClassifications?: ProductClassificationModel[];
  productMaterialProperties?: ProductMaterialProperyModel[];
  geographicalScopeDescription?: string;
  gtin?: string;
  uncpcId?: string;
  unitValue?: string;
  unitType?: number | null;
  productDetailsImages?: FileModelPlus[];
  technicalPurposeOfProduct?: string;
  manufacturingOrServiceProvisionDescription?: string;
  isTheProjectInThePlanningOrDesignPhase?: boolean;
};

export type UpdateEPDProductInformationPropertyModel = {
  productId: string;
  versionId: string;
  propertyName: string;
  newValue: string;
};

export type CreateEPDProductionSiteAddressModel = {
  productId?: string;
  versionId?: string;
};

export type FoundationAddressModel = {
  id: string;
  countryId: string;
  addressLine: string;
  addressLineSecond?: string;
  city: string;
  zipCode: string;
};

export type EPDOrganizationInformationAddressModel = IVersionedEntity & FoundationAddressModel;

export type EPDCoverPageModel = {
  id: string;
  versionId: string;
  productImages?: FileModelPlus[];
  companyLogoImages?: FileModelPlus[];
  productName: string;
  declaredStandards: string;
};

export type EPDAdditionalInformationModel = IVersionedEntity & {
  references?: string;
  environmental?: string;
  conversion?: string;
  dangerous?: string;
  economic?: string;
};

export type ProductCategoryModel = {
  id?: string;
  title?: string | undefined;
  productCategories?: ProductCategoryModel[] | undefined;
};

export type PCRInformationModel = {
  pcr: PCRModel | undefined;
  cpcr: PCRModel | undefined;
  cpcrProductCategory?: ProductCategoryModel | undefined;
};

export type EPDPDFOrganizationInformationAddress = EPDOrganizationInformationAddressModel & {
  countryName: string;
};

export type EPDPDFModel = unknown & {
  generalInformation: any;
  coverPageInformation: any;
  organizationInformation: EPDOrganizationInformationModel & {
    logo: any;
    address: EPDPDFOrganizationInformationAddress;
  };
  products: EPDProductInformationModel[] | undefined;
  companyInformation: any;
  additionalInformation: any;
  pcrInformation: PCRInformationModel;
  countries: any;
  infrastructureAndCapitalGoods: InfrastructureAndCapitalGoodsModel;
  lcaSpecification: LCASpecificationModel;
  contentDeclarationProducts: EPDDeclareContentProductModel[];
  scenarios: ScenarioItemModel[];
  lcaSupportInformation: LcaSupportModel;
};

export enum VerifierType {
  Missing = 'Missing',
  Body = 'Accredited Certification Body',
  Individual = 'Approved Individual Verifier',
}

export enum EPDContentDeclarationProductType {
  Average = 'AverageProduct',
  BestProduct = 'BestProduct',
  WorstProduct = 'WorstProduct',
}

export type AddressModel = {
  line1?: string | undefined;
  line2?: string | undefined;
  line3?: string | undefined;
  line4?: string | undefined;
  city?: string | undefined;
  country?: string | undefined;
  countryId?: string | undefined;
};

export type VerifierCompanyAddressModel = {
  addressLine: string | null;
  city: string | null;
  zipCode: string | null;  
  country: string | null;
};

export type EPDVerifierModel = {
  id: string;
  verifierType?: VerifierType;
  contactUserId: string;
  contactName: string;
  companyId: string;
  companyName: string;
  homeCompanyName?: string;
  address?: AddressModel | undefined;
  companyAddress?: VerifierCompanyAddressModel | undefined;
  website?: string | undefined;
  information?: string | undefined;
  email?: string | undefined;
  phoneNumber?: string | undefined;
  displayName?: string | undefined;
  logotypeFileId?: string | undefined;
  isExternalMembership?: string | undefined;
};

export type EPDDeveloperModel = {
  contactUserId?: string;
  contactName?: string | undefined;
  contactEmail?: string | undefined;
  companyId?: string;
  companyName?: string | undefined;
  address?: AddressModel | undefined;
  website?: string | undefined;
  logotypeFileId?: string | undefined;
  requestSentAt?: Date | undefined;
};

export type LcaSupportModel = {
  epdDevelopers: EPDDeveloperModel[];
};

export type ThirdPartyVerificationModel = {
  epdVerifier?: EPDVerifierModel | undefined;
  preverifiedTool: PreverifiedTool | null;
  preverifiedToolVersion: PreverifiedToolVersion | undefined;
  preverifiedToolVerifierAccreditedBy: string | undefined;
  verificationType: VerificationType | undefined;
  verificationTypeDataSource: VerificationType[];
  processCertificateIssuedBy?: EPDVerifierModel | undefined;
  processCertificates: FileModel[];
  followUpInvolvesThirdPartyVerifier: string | undefined;
  preVerifiedToolDataSource: PreverifiedTool[] | undefined;
};

export type EPDVerifierCompanyModel = {
  id?: string;
  name?: string | undefined;
  information?: string | undefined;
  verifierType?: VerifierType;
  email?: string | undefined;
  phoneNumber?: string | undefined;
  logotypeFileId?: string | undefined;
  successOperationText?: string | undefined;
  errorText?: string | undefined;
  requestSentAt?: Date | undefined;
};

export type ConnectDeveloperModel = {
  epdId: string;
  membershipId: string;
};

export type RequestCollaborationModel = {
  epdVersionId: string;
  roleId?: string;
  companyId?: string;
  verificationType?: VerificationType;
};

export type AddEPDFileModel = {
  epdId?: string;
  epdProductInformationId?: string;
  propertyName?: string | undefined;
  file?: File | undefined;
};

export type AddEPDPropertyModel = {
  epdId: string;
  versionId: string;
  propertyName: string;
  newValue: any;
};

export type DataQualityAssessmentItemModel = {
  id?: string | null;
  epdVersionId: string;
  ord: number;
  processName?: string;
  sourceType?: string;
  source?: string;
  referenceYearStartDate?: string;
  referenceYearEndDate?: string;
  dataCategory?: string;
  shareOfPrimaryData?: string;
};

export type DataQualityAssessmentReadModel = {
  dataQualityAssessmentSummary?: string;
  dataQualityAssessmentItems: DataQualityAssessmentItemModel[];
};

export type DataQualityAssessmentSummaryModel = {
  epdVersionId: string;
  dataQualityAssessmentSummary: string;
};

export enum QRCodeTypeEnum {
  WebSizeColoured = 'WebSizeColoured',
  WebSizeGrayscale = 'WebSizeGrayscale',
  PrintSizeColoured = 'PrintSizeColoured',
  PrintSizeGrayscale = 'PrintSizeGrayscale',
}
