import TextInput from 'components/form/TextInput';
import { Formik } from 'formik';
import viewIcon from 'images/icons/svg/view.svg';
import React from 'react';
import CompanyService from 'services/CompanyService';
import { CompanyModel } from 'services/EpdClient';
import RequestsService from 'services/RequestService';
import styled from 'styled-components';
import { Button, ErrorText, H2, StyledForm, Text, Container} from 'styles/Styles.styled';
import { PoorMansError } from 'util/utils';
import * as Yup from 'yup';

import { ProcessStatus } from '../../constants/constants';

interface FindCompaniesModel {
  name: string;
  vat: string;
}

interface Props {}

const MAX_HITS = 10;

const FindCompany: React.FunctionComponent<Props> = () => {
  const [companies, setCompanies] = React.useState<CompanyModel[]>([]);
  const [status, setStatus] = React.useState<ProcessStatus>(ProcessStatus.None);
  const [companiesRequested, setCompaniesRequested] = React.useState<any>([]);
  const [isRequestingId, setIsRequestingId] = React.useState<string | undefined>('');
  const [serverError, setServerError] = React.useState<string>();
  const findInitialValues: FindCompaniesModel = {
    name: '',
    vat: '',
  };

  const findSchema = Yup.object({
    name: Yup.string(),
    vat: Yup.string(),
  });

  const findCompanies = async (values: FindCompaniesModel) => {
    try {
      setCompanies([]);
      setServerError('');
      setStatus(ProcessStatus.Fetching);
      const result = await CompanyService.findCompanies(values.name);
      setCompanies(result);
      setStatus(ProcessStatus.Success);
    } catch (ex) {
      setServerError(PoorMansError(ex));
      setStatus(ProcessStatus.Error);
    }
  };

  const doRequestAccess = async (company: CompanyModel) => {
    setIsRequestingId(company.id);
    setServerError('');

    try {
      // The call to createRequest will not return any result if it's successful
      await RequestsService.createRequest({ companyId: company.id });
      setCompaniesRequested(companiesRequested.concat(company.id));
    } catch (ex) {
      setServerError(PoorMansError(ex));
    }

    setIsRequestingId('');
  };

  return (
    <>
      <Container>
      <H2>Find organisation</H2>
      <Text>
        Please search for your organisation and request access. The account manager of your organisation needs to approve
        your request before you gain access to your organisation and linked EPDs.
      </Text>
      <Formik initialValues={findInitialValues} validationSchema={findSchema} onSubmit={(values) => findCompanies(values)}>
        {({ isSubmitting }) => (
          <StyledForm>
            <TextInput label="Name" name="name" />
            <Button disabled={isSubmitting}>Find Organisation</Button>
          </StyledForm>
        )}
      </Formik>
      {serverError && <ErrorText>{serverError}</ErrorText>}
      {status === ProcessStatus.Success && companies.length > 0 && (
        <FoundCompanies>
          <h3>We found {companies?.length === 1 ? 'this company' : 'these companies'}</h3>

          <div
            style={{
              textAlign: 'center',
              visibility: isRequestingId !== '' ? 'visible' : 'hidden',
            }}
          >
            Requesting...
          </div>

          {companies?.slice(0, MAX_HITS).map((company: CompanyModel) => (
            <CompanyRow key={company.id}>
              <div>{company.name}</div>
              {!companiesRequested.includes(company.id || '') ? (
                company.id === isRequestingId ? (
                  <div>Requesting...</div>
                ) : (
                  <RequestAccess onClick={() => doRequestAccess(company)}>
                    <ViewIcon src={viewIcon} alt="Request access" />
                    Request access
                  </RequestAccess>
                )
              ) : (
                <div>Access requested</div>
              )}
            </CompanyRow>
          ))}
          {companies.length > MAX_HITS && <ErrorMessage>Too many results. Try to limit your search.</ErrorMessage>}
        </FoundCompanies>
      )}
      {status === ProcessStatus.Success && companies.length === 0 && (
        <ErrorMessage>We found no matching organisations.</ErrorMessage>
      )}
      </Container>
    </>
  );
};

const ErrorMessage = styled.div`
  font-weight: bold;
  margin-top: 1rem;
`;

const FoundCompanies = styled.div`
  background-color: white;
  padding: 1rem;
  margin: 3rem -3rem 3rem -3rem;
`;

const CompanyRow = styled.div`
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
`;

const ViewIcon = styled.img`
  visibility: hidden;
  height: 20px;
  vertical-align: bottom;
  margin-right: 4px;
`;

const RequestAccess = styled.div`
  flex: 1 0 auto;
  text-align: right;
  color: ${(props) => props.theme.colors.regionColorMedium};
  cursor: pointer;
  font-style: italic;
  &:hover {
    text-decoration: underline;
    img {
      visibility: visible;
    }
  }
`;
export default FindCompany;
