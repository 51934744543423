import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import React from 'react';
import styled from 'styled-components';
import { defaultThemeColors } from 'styles/theme';
import { Option } from 'types/types';
import { isWithin24Hours } from 'util/utils';

interface LcaCompanyCardProps {
  data: any;
  selectedPractitioners?: Option[] | undefined;
  companyPractitioners?: Option[] | undefined;
  onSelectVerifier: any;
  onRequestCollaboration: any;
  onAddPractitioner: any;
  expanded: boolean;
  buttonsAreDisabled: boolean | undefined;
}

const LcaCompanyCard: React.FunctionComponent<LcaCompanyCardProps> = ({
  data,
  selectedPractitioners,
  companyPractitioners,
  onSelectVerifier,
  onRequestCollaboration,
  onAddPractitioner,
  expanded,
  buttonsAreDisabled,
}) => {
  const { id, name, members, requestSentAt, successOperationText, errorText } = data;

  const collaborationRequestDate = !!requestSentAt ? new Date(requestSentAt) : undefined;

  const requestCollaborationButtonDisabledText =
    successOperationText ??
    (isWithin24Hours(collaborationRequestDate)
      ? 'The collaboration request was sent, you can submit a new request in 24 hours.'
      : null);

  const headerTemplate = (options: any) => {
    const className = `${options.className} align-items-center justify-content-space-between w-full`;
    return (
      <div style={{ paddingRight: 10 }} className={className} onClick={options.onTogglerClick}>
        <strong>{name}</strong>
        {expanded && (
          <StyledRequestCollaborationButton
            label="Request collaboration"
            onClick={(e) => {
              e.stopPropagation();
              onRequestCollaboration(id);
            }}
            disabled={buttonsAreDisabled || requestCollaborationButtonDisabledText}
          />
        )}
      </div>
    );
  };

  return (
    <StyledLcaCompanyCardPanel
      headerTemplate={(options) => headerTemplate(options)}
      toggleable
      collapsed={!expanded}
      onToggle={onSelectVerifier}
    >
      <div onClick={onSelectVerifier}>
        <StyledPractitionersContainer>
          {members?.map((p: any) => (
            <StyledPractitionerContainer key={p.membershipId}>
              {p.email}
              <StyledAddPractitionerButton
                label="Add practitioner"
                onClick={(e) => {
                  e.stopPropagation();
                  onAddPractitioner(p.membershipId);
                }}
                disabled={
                  buttonsAreDisabled ||
                  companyPractitioners?.some((companyPractitioner: Option) => companyPractitioner.value === p.userId) ||
                  members?.some((member: any) =>
                    selectedPractitioners?.some((practitioner: Option) => practitioner.value === member.userId)
                  )
                }
              />
            </StyledPractitionerContainer>
          ))}
        </StyledPractitionersContainer>
        {requestCollaborationButtonDisabledText && (
          <StyledSuccessText>{requestCollaborationButtonDisabledText}</StyledSuccessText>
        )}
        {errorText && <StyledErrorText>{errorText}</StyledErrorText>}
      </div>
    </StyledLcaCompanyCardPanel>
  );
};

const StyledLcaCompanyCardPanel = styled(Panel)`
  border: 1px solid;
  border-color: ${defaultThemeColors.elementsInputBorder};
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 49px;

  .p-panel-header {
    border: 0;
    background-color: ${defaultThemeColors.primaryWhite};
    margin-top: 3px;
  }

  .p-panel-content {
    border: 0;
    background-color: ${defaultThemeColors.primaryWhite};
    ${(props) => props.theme.typography.bodyInput};
    padding-top: 0px;
    padding-bottom: 16px;
  }

  p {
    margin-top: 0.5rem;
    color: ${defaultThemeColors.black};
  }
`;

const StyledPractitionersContainer = styled.div`
  margin-top: 15px;
`;

const StyledPractitionerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const StyledRequestCollaborationButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.primaryOrange};
  border-color: ${(props) => props.theme.colors.primaryOrange};
  min-width: 195px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 1rem;

  &&:hover {
    background-color: ${(props) => props.theme.colors.lightOrange};
    border-color: ${(props) => props.theme.colors.lightOrange};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.elementsButtonDisabled};
    border-color: ${(props) => props.theme.colors.elementsButtonDisabled};
  }

  height: 1.5rem;
  min-height: 22px;
  ${(props) => props.theme.typography.buttonButtonSmall};

  margin-right: 10px;
`;

const StyledAddPractitionerButton = styled(Button)`
  ${(props) => props.theme.typography.buttonButtonSmall};
  height: 1.5rem;
  min-height: 22px;
  background-color: transparent !important;
  color: ${(props) => props.theme.colors.primaryOrange};
  border-radius: 4px;
  border: none;
  padding: 0px;

  &&:hover {
    color: ${(props) => props.theme.colors.elementsButtonHover};
  }

  &:disabled {
    color: ${(props) => props.theme.colors.elementsButtonDisabled};
  }
`;

const StyledSuccessText = styled.div`
  margin-top: 15px;
  color: green;
`;

const StyledErrorText = styled.div`
  margin-top: 15px;
  color: red;
`;

export default LcaCompanyCard;
