import { RadioButtonChangeEvent } from 'primereact/radiobutton';
import React from 'react';
import styled from 'styled-components';
import { CheckBoxPanel, RadioButtonStyled } from 'styles/v2/Styles.styled';

const QRCodeItem: React.FunctionComponent<{ label: string; setValue: any; value: any; isChecked: any }> = ({
  label,
  setValue,
  value,
  isChecked,
}) => {
  return (
    <CheckBoxPanel style={{ height: '6vh' }}>
      <label onClick={() => setValue(value)} style={{ display: 'flex', alignItems: 'center' }}>
        <StyledRadioButtonWrapper>
          <RadioButtonStyled
            inputId={'QRCode_type'}
            value={value}
            name="editMode"
            checked={isChecked}
            onChange={(e: RadioButtonChangeEvent) => setValue(e.value)}
          />
        </StyledRadioButtonWrapper>
        <StyledSpan style={{ textTransform: 'none', marginLeft: '0.5rem' }}>{label}</StyledSpan>
      </label>
    </CheckBoxPanel>
  );
};

const StyledSpan = styled.span`
  color: var(--Primary-Black, #151515);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 0.5rem;
`;

const StyledRadioButtonWrapper = styled.div`
  .p-radiobutton.p-highlight .p-radiobutton-box {
    background: #ffffff;
    border-color: #e75113;
    display: flex;
  }

  .p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
    border-color: #e75113;
  }

  .p-radiobutton-icon {
    background-color: #e75113;
  }

  display: flex;
`;

export default QRCodeItem;
