import { ISO_14025_ID } from 'components/v2/compiler/constants';
import { useEffect, useState } from 'react';
import EpdService from 'services/EpdService';
import { DictionaryValue } from 'types/types';

const useConformityStandards = (pcr?: { isConstruction?: boolean }) => {
  const [conformityStandards, setConformityStandards] = useState<DictionaryValue[]>([]);

  useEffect(() => {
    const fetchConformityStandards = async () => {
      try {
        const result = await EpdService.getConformityStandards();
        setConformityStandards(result);
      } catch (error) {
        console.error('Failed to fetch conformity standards:', error);
      }
    };

    fetchConformityStandards();
  }, []);

  return conformityStandards.map((x) => ({
    value: x.key.toString(),
    label: x.value,
    isDisabled: x.key.toString() !== ISO_14025_ID && !pcr?.isConstruction,
  }));
};

export default useConformityStandards;
