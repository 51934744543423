import WizardTextAreaComponent from 'components/v2/epd-wizard-inputs/WizardTextArea';
import { useState } from 'react';
import { useCreateEpdDataQualityAssessmentSummary, useUpdateEpdDataQualityAssessmentSummary } from 'services/api/mutations';
import { useGetEpdDataQualityAssessment } from 'services/api/queries';
import { DataQualityAssessmentSummaryModel } from 'types/types';

import DataQualityAssessmentAndReferenceYearsGrid from '../../grids/data-quality-assessment-and-ref-years/DataQualityAssessmentAndReferenceYearsGrid';
import MaximizedDialog from '../expandable/MaximizedDialog';

type TProps = {
  epdVersionId: string;
  errors?: any;
};

const DataQualityAssessmentAndReferenceYearsPanel = ({ epdVersionId, errors }: TProps) => {
  const [gridIsMaximized, setGridIsMaximized] = useState(false);

  const dataQualityAssessmentAndReferenceYears = useGetEpdDataQualityAssessment(epdVersionId).data;

  const createDataQualityAssessmentSummaryMutation = useCreateEpdDataQualityAssessmentSummary(epdVersionId);
  const updateDataQualityAssessmentSummaryMutation = useUpdateEpdDataQualityAssessmentSummary(epdVersionId);

  const handleDataQualityAssessmentSummaryChanged = (_: any, val: any) => {
    if (dataQualityAssessmentAndReferenceYears?.dataQualityAssessmentSummary === null) {
      createDataQualityAssessmentSummaryMutation.mutate({
        epdVersionId,
        dataQualityAssessmentSummary: val,
      });
      return;
    }

    updateDataQualityAssessmentSummaryMutation.mutate({
      epdVersionId,
      dataQualityAssessmentSummary: val,
    } as DataQualityAssessmentSummaryModel);
  };

  return (
    <>
      <MaximizedDialog
        title={'Data quality assessment and reference years'}
        visible={gridIsMaximized}
        onHide={() => setGridIsMaximized(false)}
      >
        <DataQualityAssessmentAndReferenceYearsGrid
          epdVersionId={epdVersionId}
          rows={dataQualityAssessmentAndReferenceYears?.dataQualityAssessmentItems}
          errors={errors}
        />
      </MaximizedDialog>

      <WizardTextAreaComponent
        label="Description of data quality assessment and reference years"
        tooltip="In EPDs of goods, as a minimum, declare the years(s) the data of the manufacturing processes in module A3 represent. In EPDs of services, as a minimum, declare the years(s) the data of the service processes in module A5, B or C1 represent."
        placeholder="Type here"
        name="dataQualityAssessmentSummary"
        value={dataQualityAssessmentAndReferenceYears?.dataQualityAssessmentSummary}
        onChanged={handleDataQualityAssessmentSummaryChanged}
      />
      <DataQualityAssessmentAndReferenceYearsGrid
        epdVersionId={epdVersionId}
        rows={dataQualityAssessmentAndReferenceYears?.dataQualityAssessmentItems}
        onMaximize={() => setGridIsMaximized(true)}
        errors={errors}
      />
    </>
  );
};

export default DataQualityAssessmentAndReferenceYearsPanel;
