import { CompanyContext } from 'contexts/CompanyContextProvider';
import {
  CollectionIcon,
  CompanySelectorIcon,
  EpdLibraryIcon,
  HomeIcon,
  PcrLibraryIcon,
  ProfileIcon,
  RegistersIcon,
  ReportsIcon,
  VerifiersIcon,
} from 'images/icons/svg';
import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import EpdRoutes, { EpdLinks } from 'routes/EpdRoutes';
import AuthService from 'services/AuthService';
import CompanyService from 'services/CompanyService';
import styled, { css, useTheme } from 'styled-components';
import { Theme } from 'styles/theme';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { Hamburger } from '../menu/Hamburger';
import CompanyHeader from './CompanyHeader';

const headerSizeThreshold = -10;
const headerSize = (isLarge: boolean) => (isLarge ? 90 : 60);

type Item = {
  children: {
    [key: string]: string;
  };
  icon?: string;
  tooltip?: string;
};

type Menu = {
  [key: string]: Item;
};

type ToolBoxItem = {
  name: string;
  icon: string;
  tooltip?: string;
  route: string | object;
  disabled?: boolean;
  hidden?: boolean;
  target?: string;
  rel?: string;
};

type ToolBox = ToolBoxItem[];

const Header: React.FunctionComponent<{
  changeTheme: (theme: string) => void;
  onChangeUI: any;
}> = ({ changeTheme, onChangeUI }) => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const [isOnTop, setIsOnTop] = React.useState<boolean>(true);
  const [companyName, setCompanyName] = React.useState<string | undefined>('');
  const [hasUserPermissionEditCompanySettings, setHasUserPermissionEditCompanySettings] = React.useState<boolean>(false);
  const { companyId, company, licensee } = useContext(CompanyContext);

  useEffect(() => {
    const fetchCompany = async () => {
      if (AuthService.isLoggedin()) {
        const accesstoEditCompany = await CompanyService.getAccessToEdit(companyId || '');
        setCompanyName(company?.name ?? '');
        setHasUserPermissionEditCompanySettings(accesstoEditCompany);
      }
    };

    if (company) {
      fetchCompany();
    } else {
      setCompanyName('');
    }
  }, [companyId, company]); // eslint-disable-line react-hooks/exhaustive-deps

  const adminHeaderItems: Menu = {
    registers: {
      children: {
        companies: EpdRoutes.admin.companies.companies,
        invoices: EpdRoutes.admin.invoices.invoices,
        users: EpdRoutes.admin.users.users,
        pcrs: EpdRoutes.admin.pcrs.pcrs,
        epds: EpdRoutes.admin.epds.epds,
        licensees: EpdRoutes.admin.licensees.licensees,
        ilcd: EpdRoutes.admin.integration.ilcdEpd,
        tools: EpdRoutes.admin.tools.tools,
        dataSources: EpdRoutes.admin.dataSources.dataSources,
        fortnox: EpdRoutes.admin.integration.fortnoxIntegrationPage,
        fortnoxInvoice: EpdRoutes.admin.integration.FortnoxInvoicesPage,
      },
      icon: RegistersIcon,
    },
    reports: {
      children: {
        kpis: EpdRoutes.admin.kpis.summary,
        verifiers: EpdRoutes.admin.verifiers.summary,
        epds: EpdRoutes.admin.epdsreport.summary,
      },
      icon: ReportsIcon,
    },
  };

  const licenseeAdminHeaderItems: Menu = {
    registers: {
      children: {
        users: EpdRoutes.admin.users.users,
        epds: EpdRoutes.admin.epds.epds,
      },
      icon: RegistersIcon,
    },
  };

  const pcrAdminHeaderItems: Menu = {
    registers: {
      children: {
        pcrs: EpdRoutes.admin.pcrs.pcrs,
      },
      icon: RegistersIcon,
    },
  };

  const toolboxHeaderItems: ToolBox = [
    {
      name: t('header.dashboard'),
      icon: HomeIcon,
      tooltip: t('header.tooltips.dashboard'),
      route: EpdLinks.dashboard(),
    },
    {
      name: t('header.collections'),
      icon: CollectionIcon,
      tooltip: t('header.tooltips.collections'),
      route: EpdLinks.collections(),
    },
    {
      name: t('header.companySelector'),
      icon: CompanySelectorIcon,
      tooltip: t('header.tooltips.selectCompany'),
      route: EpdLinks.selectCompany(companyId || ''),
    },
    {
      name: t('header.profile'),
      icon: ProfileIcon,
      tooltip: t('header.tooltips.profile'),
      route: EpdRoutes.account.forgotPassword,
      disabled: true,
      hidden: true,
    },
  ];

  const toolboxHeaderExternalItems: ToolBox = [
    {
      name: t('header.epdLibrary'),
      icon: EpdLibraryIcon,
      tooltip: t('header.tooltips.epdLibrary'),
      route: { pathname: 'https://environdec.com/library' },
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: t('header.pcrLibrary'),
      icon: PcrLibraryIcon,
      tooltip: t('header.tooltips.pcrLibrary'),
      route: { pathname: t('header.pcrLibraryLink') },
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      name: t('header.verifiers'),
      icon: VerifiersIcon,
      tooltip: t('header.tooltips.verifiers'),
      route: { pathname: theme.verifiersUrl },
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  ];

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const showSmall = currPos.y > headerSizeThreshold;
      if (showSmall !== isOnTop) setIsOnTop(showSmall);
    },
    [isOnTop]
  );

  let menuItems: any = null;
  if (AuthService.isAdmin()) {
    menuItems = adminHeaderItems;
  } else if (AuthService.isPcrAdmin()) {
    menuItems = pcrAdminHeaderItems;
  }

  menuItems = AuthService.isLicenseeAdmin() ? _.merge(menuItems, licenseeAdminHeaderItems) : menuItems;

  return (
    <>
      <MainHeader isOnTop={isOnTop}>
        <HeaderSection>
          <HeaderLogo>
            <HeaderAction to={EpdLinks.dashboard()}>
              <img src={theme.logo} alt="EPD International" />
            </HeaderAction>
          </HeaderLogo>

          {AuthService.isAuthenticated() && toolboxHeaderItems !== null && (
            <>
              {toolboxHeaderItems.map(
                (item) =>
                  !item.hidden && (
                    <HeaderItem key={item.name}>
                      <Link
                        to={item.route}
                        {...(item.disabled && { style: { cursor: 'not-allowed', pointerEvents: 'none' } })}
                        target={item.target}
                        rel={item.rel}
                        title={item.tooltip}
                      >
                        <ToolButton isOnTop={isOnTop} isCurrent={location.pathname === item.route}>
                          <img src={item.icon} alt={item.tooltip} />
                          <span>{item.name}</span>
                        </ToolButton>
                      </Link>
                    </HeaderItem>
                  )
              )}
              <Separator />
            </>
          )}

          {toolboxHeaderExternalItems.map((item, index) => (
            <HeaderItem key={item.name}>
              <Link
                to={item.route}
                {...(item.disabled && { style: { pointerEvents: 'none' } })}
                target={item.target}
                rel={item.rel}
                title={item.tooltip}
              >
                <ToolButton isOnTop={isOnTop}>
                  <img src={item.icon} alt={item.tooltip} />
                  <span>{item.name}</span>
                </ToolButton>
              </Link>
            </HeaderItem>
          ))}

          {menuItems !== null &&
            Object.keys(menuItems).map((itemKey, index) => (
              <HeaderItems key={index}>
                {index === 0 && <Separator />}
                <HeaderItem>
                  <span>
                    <ToolButton isOnTop={isOnTop}>
                      <img src={menuItems[itemKey].icon} alt={menuItems[itemKey].tooltip} />
                      <span>{t(`header.${itemKey}.title`)}</span>
                    </ToolButton>
                    <SubMenu>
                      {Object.keys(menuItems[itemKey].children).map((childKey) => (
                        <MenuItem key={childKey}>
                          <Link to={menuItems[itemKey].children[childKey]}>
                            {t(`header.${itemKey}.children.${childKey}`)}
                          </Link>
                        </MenuItem>
                      ))}
                    </SubMenu>
                  </span>
                </HeaderItem>
              </HeaderItems>
            ))}
        </HeaderSection>

        <HeaderSection>
          {AuthService.isAuthenticated() && (
            <CompanyHeader
              companyName={companyName || ''}
              parentCompanyName={company?.parentCompany?.name || ''}
              companyId={companyId}
              settingsEnabled={hasUserPermissionEditCompanySettings}
            />
          )}

          {AuthService.isPcrUser() ? (
            <HeaderActionPcr isOnTop={isOnTop} onClick={() => history.go(0)}>
              <div>PCR Portal</div>
            </HeaderActionPcr>
          ) : null}

          <BurgerMenu>
            <Hamburger
              height={headerSize(isOnTop)}
              menuItems={menuItems}
              changeTheme={changeTheme}
              isAdmin={AuthService.isAdmin()}
              onChangeUI={onChangeUI}
            />
          </BurgerMenu>
        </HeaderSection>
      </MainHeader>
    </>
  );
};

const MainHeader = styled.header<{ isOnTop: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  height: ${(props) => (props.isOnTop ? '90px' : '60px')};
  transition: height 0.15s;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
  background-color: white;
  z-index: 10;

  @media print {
    display: none;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: center;
`;
const HeaderLogo = styled.div`
  align-self: center;
  > img {
    margin: 0 1rem;
    height: 40px;
  }
`;

const HeaderAction = styled(Link)`
  margin-left: 2rem;
  margin-right: 1rem;
  > img {
    margin: 0 1rem;
    height: 40px;
  }
`;

const HeaderActionPcr = styled.div<{ isOnTop: boolean }>`
  padding: ${(props) => (props.isOnTop ? '2rem' : '1rem 2rem')};
  background-color: ${(props) => props.theme.colors.regionColorMedium};
  text-decoration: none;

  ${(props) => props.theme.media.desktop} {
    display: flex;
    padding: 1.7rem 2rem;
  }

  :hover {
    background-color: ${(props) => props.theme.colors.regionColorLight};
  }

  ${(props) => props.theme.fonts.heading2};
  > * {
    color: white;
    text-decoration: none;
    align-self: center;
  }
`;

const headerNarrowDesktopMediaQuery = '@media (max-width: 1430px)';

const SubMenu = styled.ul`
  display: none;
  padding: 1rem;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: calc(min(50vw, 250px));
  background-color: ${(props) => props.theme.colors.regionColorMedium};
  color: white;
  transition: opacity 150ms;
  list-style: none;
  flex-direction: column;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.15);

  &::focus-within {
    display: flex;
  }

  ::before {
    content: ' ';
    background-color: ${(props) => props.theme.colors.regionColorMedium};
    position: absolute;
    top: -0.7rem;
    width: 1.5rem;
    height: 1.5rem;
    transform: translateX(1rem) rotate(45deg);
  }
`;

const BurgerMenu = styled.div`
  display: flex;
`;

const Separator = styled.div`
  width: 1px;
  border-left: 1px;
  border-left-color: ${(props) => props.theme.colors.darkGray};
  border-left-style: dashed;
  height: 30px;
  align-self: center;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const HeaderItem = styled.li`
  ${(props) => props.theme.fonts.heading3}
  display:flex;
  position: relative;

  > a,
  > span {
    color: black;
    text-decoration: none;
    padding: 0 1rem;
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;

    ${headerNarrowDesktopMediaQuery} {
      font-size: 1rem;
      padding: 0 0.75rem;
    }

    :hover,
    :focus {
      color: ${(props) => props.theme.colors.regionColorMedium};
      outline: none;
    }
    > img {
      height: 25px;
    }

    > svg {
      color: inherit;
    }
  }

  &:hover ${SubMenu}, &:focus ${SubMenu} {
    display: flex;
    opacity: 1;
  }
`;

const ToolButton = styled.div<{ isOnTop: boolean; isCurrent?: boolean }>`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  img {
    height: 25px;
  }
  span {
    text-align: center;
    display: none;
    ${(props) => props.theme.media.desktop} {
      display: ${(props) => (props.isOnTop ? 'block' : 'none')};
    }
  }
  ${(props) =>
    props.isCurrent &&
    css`
      && {
        span {
          color: ${(props) => props.theme.colors.regionColorMedium};
        }
        img {
          filter: ${(props) => props.theme.colors.toolBoxImageFilter};
        }
      }
    `}

  a,
  li {
    max-width: 4rem;
  }
  &:hover {
    img {
      filter: ${(props) => props.theme.colors.toolBoxImageFilter};
    }
  }
`;

const HeaderItems = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
  ${(props) => props.theme.media.desktop} {
    display: flex;
  }
`;

const MenuItem = styled.li`
  ${(props) => props.theme.fonts.text};
  display: flex;
  z-index: 1;
  padding: 0.25rem;

  a {
    flex: 1;
    text-decoration: none;
    color: ${(props) => props.theme.colors.lightGray};

    :hover,
    :focus {
      color: white;
      outline: none;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`;

export default Header;
