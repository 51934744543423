import axios from 'axios';
import {
  CPCModel,
  ConnectVerifierModel,
  CountryModel,
  FileModel,
  GeographicalScopeModel,
  LanguageModel,
  MaterialPropertyModel,
  ProductClassificationModel,
  ProductMaterialProperyModel,
  SendForPublicationEditorialModel,
  UpdateEPDPropertyModel,
  UploadDocumentTypesModel,
} from 'services/EpdClient';
import {
  AddEPDPropertyModel,
  BaseQueryParams,
  CO2UptakeAssociationModel,
  CompilerCompositeStatusModel,
  ConnectDeveloperModel,
  ConversionFactorModel,
  CpcrPerformanceInformationModel,
  CpcrPerformanceTableDataModel,
  CreateEPDProductionSiteAddressModel,
  DangerMaterialLookup,
  DataQualityAssessmentItemModel,
  DataQualityAssessmentReadModel,
  DataQualityAssessmentSummaryModel,
  DataSourceOptionModel,
  DeclarationOfCO2AssumptionModel,
  DeclarationOfCO2Model,
  DictionaryCatalog,
  DictionaryCatalogItem,
  DictionaryValue,
  DictionaryValues,
  EPDAdditionalInformationModel,
  EPDContentDeclarationProductType,
  EPDCoverPageModel,
  EPDDataSourceModel,
  EPDDeclareContentProductModel,
  EPDDefinitionModel,
  EPDOrganizationInformationAddressModel,
  EPDOrganizationInformationModel,
  EPDProductInformationModel,
  EPDVerifierModel,
  ElectricityDataModel,
  FoundationAddressModel,
  IVersionedEntity,
  InfrastructureAndCapitalGoodsModel,
  LCASoftwareOptionModel,
  LCASpecificationModel,
  LcaSupportModel,
  Option,
  PCRInformationModel,
  ProductComponent,
  ProductDangerMaterial,
  ProductPackagingMaterial,
  ProductPanelContent,
  QRCodeTypeEnum,
  ReferenceFlowModel,
  ReferencePackageVersion15804,
  RequestCollaborationModel,
  ScenarioItemModel,
  ScenarioModuleDefaultDescriptionModel,
  ScenarioModuleItemModel,
  ScenarioModuleReadModel,
  ScenarioModuleRequest,
  ScrapInputModel,
  ShareOfTotalScrapInputModel,
  SystemBoundaryGridModel,
  SystemBoundaryModel,
  TagModel,
  ThirdPartyVerificationModel,
  TransportationPerformanceModel,
  UpdateDeclaredModulesGeographyModel,
  UpdateDeclaredModulesModel,
  UpdateEPDContentDeclarationProductModel,
  UpdateEPDProductInformationPropertyModel,
  UserOptionModel,
  VerificationViewModel,
} from 'types/types';

import { BASE_URL } from './constants';

export const axiosInstance = axios.create({ baseURL: BASE_URL, withCredentials: true });

export const getDictionaryValues = async (dictionaryCode?: string) => {
  const url = dictionaryCode
    ? `/api/v1/Compiler/Dictionaries/Values?dictionaryCode=${dictionaryCode}`
    : `/api/v1/Compiler/Dictionaries/Values`;

  return (await axiosInstance.get<DictionaryValues>(url)).data;
};

export const getDictionaryCatalog = async () => {
  return (await axiosInstance.get<DictionaryCatalog>(`/api/v1/Compiler/Dictionaries`)).data;
};

export const getCompanyCollectionsLookup = async (companyId: string) => {
  return (await axiosInstance.get<Option[]>(`/api/v1/Companies/${companyId}/CollectionsLookup`)).data;
};

export const getCompanyUsersLookup = async (companyId: string) => {
  return (await axiosInstance.get<UserOptionModel[]>(`/api/v1/Companies/${companyId}/UsersLookup`)).data;
};

export const getLanguages = async () => {
  return (await axiosInstance.get<LanguageModel[]>(`/api/v1/Languages`)).data;
};

export const getDocumentTypes = async () => {
  return (await axiosInstance.get<UploadDocumentTypesModel[]>(`/api/v1/UploadDocumentTypes`)).data;
};

export const getEpdLatestVersionId = async (epdId: string, params?: BaseQueryParams) => {
  return (await axiosInstance.get<string>(`/api/v1/EPD/${epdId}/LatestVersion`, params)).data;
};

export const getEpdFormat = async (epdId: string) => {
  return (await axiosInstance.get<string>(`/api/v1/EPD/${epdId}/Format`)).data;
};

export const getEpdOrganizationInformation = async (epdId: string) => {
  return (await axiosInstance.get<EPDOrganizationInformationModel>(`/api/v1/EPD/${epdId}/OrganizationInformation`)).data;
};

export const getEpdOrganizationInformationAddress = async (epdVersionId: string) => {
  return (
    await axiosInstance.get<EPDOrganizationInformationAddressModel>(
      `/api/v1/compiler/EPDVersion/${epdVersionId}/OrganizationInformationAddress`
    )
  ).data;
};

export const getCompilerCompositeStatus = async (epdId: string, params?: BaseQueryParams) => {
  return (
    await axiosInstance.get<CompilerCompositeStatusModel>(
      `/api/v1/compiler/EPDVersion/EPD/${epdId}/StatusInformation`,
      params
    )
  ).data;
};

export const getEpdVerificationReportInfo = async (epdVersionId: string) => {
  return (await axiosInstance.get<VerificationViewModel>(`/api/v1/compiler/EPDVersion/${epdVersionId}/verification`)).data;
};

export const getEpdCoverPageInformation = async (epdVersionId: string) => {
  return (await axiosInstance.get<EPDCoverPageModel>(`/api/v1/compiler/EPDVersion/${epdVersionId}/CoverPageInformation`))
    .data;
};

export const getEpdAdditionalInformation = async (epdVersionId: string) => {
  return (
    await axiosInstance.get<EPDAdditionalInformationModel | null>(
      `/api/v1/compiler/EPDVersion/${epdVersionId}/AdditionalInformation`
    )
  ).data;
};

export const getEpdAdditionalDocuments = async (epdVersionId: string, params?: BaseQueryParams) => {
  return (await axiosInstance.get<FileModel[]>(`/api/v1/compiler/EPDVersion/${epdVersionId}/AdditionalDocuments`, params))
    .data;
};

export const getEpdPCRTypeInformation = async (epdVersionId: string, params?: BaseQueryParams) => {
  return (await axiosInstance.get<PCRInformationModel>(`/api/v1/compiler/EPDVersion/${epdVersionId}/PCRInformation`, params))
    .data;
};

export const getEpdLcaSupport = async (epdVersionId: string, params?: BaseQueryParams) => {
  return (await axiosInstance.get<LcaSupportModel>(`/api/v1/compiler/EPDVersion/${epdVersionId}/LCASupport`, params)).data;
};

export const getEpdThirdPartyVerification = async (epdVersionId: string, params?: BaseQueryParams) => {
  return (
    await axiosInstance.get<ThirdPartyVerificationModel>(
      `/api/v1/compiler/EPDVersion/${epdVersionId}/ThirdPartyVerification`,
      params
    )
  ).data;
};

export const addEPDFile = async (data: FormData) => {
  await axiosInstance.post(`/api/v1/EPD/AddEPDFile`, data);
};

export const addEPDProductInformationFile = async (epdVersionId: string, data: FormData) => {
  await axiosInstance.post(
    `/api/v1/compiler/EPDVersion/${epdVersionId}/ProductInformation/AddEPDProductInformationFile`,
    data
  );
};

export const removeEPDFile = async (epdVersionId: string, id: string) => {
  await axiosInstance.delete(`/api/v1/EPD/${epdVersionId}/RemoveEPDFile/${id}`);
};

export const getTags = async () => {
  return (await axiosInstance.get<TagModel[]>(`/api/v1/Tags`)).data;
};

export const updateEpdProperty = async (data: UpdateEPDPropertyModel) => {
  await axiosInstance.put(`/api/v1/EPD/UpdateEPDProperty`, data);
};

export const getCpcCodes = async (data: any) => {
  return (await axiosInstance.get<CPCModel[]>(`api/v1/CPC`, data)).data;
};

//Product information
export const getEpdProductInformation = async (versionId: string) => {
  return (
    await axiosInstance.get<EPDProductInformationModel[]>(`/api/v1/compiler/EPDVersion/${versionId}/ProductInformation`)
  ).data;
};

export const updateEpdProductInformation = async (data: UpdateEPDProductInformationPropertyModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/ProductInformation`, data);
};

export const createEpdProductInformation = async (data: EPDProductInformationModel) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/ProductInformation`, data);
};

export const createProductionSiteAddress = async (data: CreateEPDProductionSiteAddressModel) => {
  await axiosInstance.post(`/api/v1/Address/ProductionSiteAddress`, data);
};

export const createEPDProductClassification = async (data: ProductClassificationModel) => {
  await axiosInstance.post(
    `/api/v1/compiler/EPDVersion/ProductInformation/${data.productId}/EPDProductClassification`,
    data
  );
};

export const deleteProductInformation = async (id: string) => {
  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/ProductInformation/${id}`);
};

export const copyProductInformation = async (data: IVersionedEntity) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/ProductInformation/${data.id}/Copy`, data);
};

export const deleteProductClassification = async (id: string) => {
  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/ProductInformation/EPDProductClassification/${id}`);
};

export const updateProductClassification = async (data: ProductClassificationModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/ProductInformation/ProductClassification`, data);
};

export const deleteAddress = async (id: string) => {
  await axiosInstance.delete(`/api/v1/Address/${id}`);
};

export const updateAddress = async (data: FoundationAddressModel) => {
  await axiosInstance.put(`/api/v1/Address/${data.id}`, data);
};

export const createEPDProductMaterialProperty = async (productId: string) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/ProductInformation/${productId}/EPDProductMaterialProperty`);
};

export const deleteEPDProductMaterialProperty = async (id: string) => {
  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/ProductInformation/EPDProductMaterialProperty/${id}`);
};

export const updateEPDProductMaterialProperty = async (data: ProductMaterialProperyModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/ProductInformation/EPDProductMaterialProperty`, data);
};

export const getEpdContentDeclarationProducts = async (versionId: string) => {
  return (
    await axiosInstance.get<EPDDeclareContentProductModel[]>(`/api/v1/compiler/EPDVersion/${versionId}/ContentDeclaration`)
  ).data;
};

export const updateEpdContentDeclarationProduct = async (data: UpdateEPDContentDeclarationProductModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/ContentDeclaration`, data);
};

// Product components
export const createEpdProductComponent = async (data: ProductComponent) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/ProductComponents`, data);
};

export const updateEpdProductComponent = async (data: ProductComponent) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/ProductComponents`, data);
};

export const deleteEpdProductComponent = async (id: string) => {
  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/ProductComponents/${id}`);
};

export const deleteEpdProductComponents = async (ids: string[]) => {
  if (!ids?.length) {
    return;
  }
  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/ProductComponents`, { data: ids });
};

export const getEpdProductComponents = async (versionId: string, type: EPDContentDeclarationProductType) => {
  const params = {
    versionId: versionId,
    productType: type,
  };
  return (await axiosInstance.get<ProductComponent[]>(`/api/v1/compiler/EPDVersion/ProductComponents`, { params })).data;
};

// Packaging materials
export const createEpdProductPackagingMaterial = async (data: ProductPackagingMaterial) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/ProductPackagingMaterials`, data);
};

export const updateEpdProductPackagingMaterial = async (data: ProductPackagingMaterial) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/ProductPackagingMaterials`, data);
};

export const deleteEpdProductPackagingMaterial = async (id: string) => {
  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/ProductPackagingMaterials/${id}`);
};

export const deleteEpdProductPackagingMaterials = async (ids: string[]) => {
  if (!ids?.length) {
    return;
  }

  await axiosInstance.delete('/api/v1/compiler/EPDVersion/ProductPackagingMaterials', { data: ids });
};

export const getEpdProductPackagingMaterials = async (versionId: string, type: EPDContentDeclarationProductType | null) => {
  const params = {
    versionId: versionId,
    contentType: type,
  };
  return (
    await axiosInstance.get<ProductPackagingMaterial[]>(`/api/v1/compiler/EPDVersion/ProductPackagingMaterials`, { params })
  ).data;
};

// Panel content
export const createEpdProductPanelContent = async (data: ProductPanelContent) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/ProductPanelContent`, data);
};

export const updateEpdProductPanelContent = async (data: ProductPanelContent) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/ProductPanelContent`, data);
};

export const deleteEpdProductPanelContent = async (ids: string[]) => {
  if (!ids?.length) {
    return;
  }
  await axiosInstance.delete('/api/v1/compiler/EPDVersion/ProductPanelContent', { data: ids });
};

export const getEpdProductPanelContent = async (versionId: string, type: EPDContentDeclarationProductType) => {
  const params = {
    versionId: versionId,
    productType: type,
  };
  return (await axiosInstance.get<ProductPanelContent[]>(`/api/v1/compiler/EPDVersion/ProductPanelContent`, { params }))
    .data;
};

// Danger materials
export const createEpdProductDangerMaterial = async (data: ProductDangerMaterial) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/ProductDangerMaterials`, data);
};

export const updateEpdProductDangerMaterial = async (data: ProductDangerMaterial) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/ProductDangerMaterials`, data);
};

export const deleteEpdProductDangerMaterials = async (ids: string[]) => {
  if (!ids?.length) {
    return;
  }
  await axiosInstance.delete('/api/v1/compiler/EPDVersion/ProductDangerMaterials', { data: ids });
};

export const getEpdProductDangerMaterials = async (id: string, type: EPDContentDeclarationProductType) => {
  const params = {
    id: id,
    type: type,
  };

  return (await axiosInstance.get<ProductDangerMaterial[]>(`/api/v1/compiler/EPDVersion/ProductDangerMaterials`, { params }))
    .data;
};

export const getDangerMaterialsLookup = async () => {
  return (await axiosInstance.get<DangerMaterialLookup[]>(`/api/v1/compiler/DangerMaterialsLookup`)).data;
};

// Declarations of CO2
export const getEpdCO2UptakeAssociation = async (epdVersionId: string) => {
  return (
    await axiosInstance.get<CO2UptakeAssociationModel>(
      `/api/v1/compiler/EPDVersion/${epdVersionId}/DeclarationOfCO2/CO2UptakeAssociations`
    )
  ).data;
};

export const getLCASpecification = async (epdVersionId: string) => {
  return (
    await axiosInstance.get<LCASpecificationModel | null>(`/api/v1/compiler/EPDVersion/${epdVersionId}/LCASpecification`)
  ).data;
};

export const getInfrastructureAndCapitalGoods = async (epdVersionId: string) => {
  return (
    await axiosInstance.get<InfrastructureAndCapitalGoodsModel | null>(
      `/api/v1/compiler/EPDVersion/${epdVersionId}/InfrastructureAndCapitalGoods`
    )
  ).data;
};

export const upsertLCASpecification = async (epdVersionId: string, data: LCASpecificationModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${epdVersionId}/LCASpecification`, data);
};

export const upsertInfrastructureAndCapitalGoods = async (
  epdVersionId: string,
  data: InfrastructureAndCapitalGoodsModel
) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${epdVersionId}/InfrastructureAndCapitalGoods`, data);
};

export const getReferenceFlow = async (epdVersionId: string) => {
  return (await axiosInstance.get<ReferenceFlowModel>(`/api/v1/compiler/EPDVersion/${epdVersionId}/ReferenceFlow`)).data;
};

export const upsertReferenceFlow = async (epdVersionId: string, data: ReferenceFlowModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${epdVersionId}/ReferenceFlow`, data);
};

export const getConversionFactor = async (epdVersionId: string) => {
  return (await axiosInstance.get<ConversionFactorModel>(`/api/v1/compiler/EPDVersion/${epdVersionId}/ConversionFactor`))
    .data;
};

export const upsertConversionFactor = async (epdVersionId: string, data: ConversionFactorModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${epdVersionId}/ConversionFactor`, data);
};

export const getEpdDataSourceOptions = async () => {
  return (await axiosInstance.get<DataSourceOptionModel[]>(`/api/v1/DataSources/Options`)).data;
};

export const getLCASoftwareOptions = async () => {
  return (await axiosInstance.get<LCASoftwareOptionModel[]>(`/api/v1/LCASoftware/Options`)).data;
};

export const getEpdDataSources = async (epdVersionId: string) => {
  return (await axiosInstance.get<EPDDataSourceModel[]>(`/api/v1/compiler/EPDVersion/${epdVersionId}/EPDDataSources`)).data;
};

export const createEpdDataSource = async (epdVersionId: string, data: EPDDataSourceModel) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${epdVersionId}/EPDDataSources`, data);
};

export const updateEpdDataSource = async (epdVersionId: string, data: EPDDataSourceModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${epdVersionId}/EPDDataSources`, data);
};

export const deleteEpdDataSource = async (id: string, epdVersionId: string) => {
  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/${epdVersionId}/EPDDataSources/${id}`);
};

export const getReferencePackageVersions15804 = async (params?: BaseQueryParams) => {
  return (
    await axiosInstance.get<{ versions: ReferencePackageVersion15804[] }>('/api/v1/ReferencePackageVersions15804', params)
  ).data;
};

export const getEpdDeclarationsOfCO2 = async (epdVersionId: string) => {
  return (await axiosInstance.get<DeclarationOfCO2Model[]>(`/api/v1/compiler/EPDVersion/${epdVersionId}/DeclarationOfCO2`))
    .data;
};

export const createEpdDeclarationOfCO2Assumption = async (
  epdVersionId: string,
  nameId: string,
  data: DeclarationOfCO2AssumptionModel
) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${epdVersionId}/DeclarationOfCO2?nameId=${nameId}`, data);
};

export const updateEpdDeclarationOfCO2Assumption = async (
  epdVersionId: string,
  nameId: string,
  data: DeclarationOfCO2AssumptionModel
) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${epdVersionId}/DeclarationOfCO2?nameId=${nameId}`, data);
};

export const createEpdCO2UptakeAssociation = async (epdVersionId: string, data: CO2UptakeAssociationModel) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${epdVersionId}/DeclarationOfCO2/CreateCO2UptakeAssociation`, data);
};

export const updateEpdCO2UptakeAssociation = async (epdVersionId: string, data: CO2UptakeAssociationModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${epdVersionId}/DeclarationOfCO2/UpdateCO2UptakeAssociation`, data);
};

// Contribution of Scrap Inputs
export const getEpdScrapInputs = async (epdVersionId: string) => {
  return (
    await axiosInstance.get<ScrapInputModel[]>(`/api/v1/compiler/EPDVersion/ContributionOfScrapInputs/${epdVersionId}`)
  ).data;
};

export const createEpdScrapInput = async (data: ScrapInputModel) => {
  await axiosInstance.post('/api/v1/compiler/EPDVersion/ContributionOfScrapInputs', data);
};

export const updateEpdScrapInput = async (data: ScrapInputModel) => {
  await axiosInstance.put('/api/v1/compiler/EPDVersion/ContributionOfScrapInputs', data);
};

export const deleteEpdScrapInputs = async (ids: string[]) => {
  if (!ids?.length) {
    return;
  }
  await axiosInstance.delete('/api/v1/compiler/EPDVersion/ContributionOfScrapInputs', { data: ids });
};

export const getEpdShareOfTotalScrapInput = async (epdVersionId: string) => {
  return (
    await axiosInstance.get<ShareOfTotalScrapInputModel>(
      `/api/v1/compiler/EPDVersion/ContributionOfScrapInputs/ShareOfTotalScrapInput/${epdVersionId}`
    )
  ).data;
};

export const createEpdShareOfTotalScrapInput = async (data: ShareOfTotalScrapInputModel) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/ContributionOfScrapInputs/ShareOfTotalScrapInput`, data);
};

export const updateEpdShareOfTotalScrapInput = async (data: ShareOfTotalScrapInputModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/ContributionOfScrapInputs/ShareOfTotalScrapInput`, data);
};

export const updateEpdPropertyWithMutation = async (data: AddEPDPropertyModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${data.versionId}/UpdateEPDProperty`, data);
};

// Electricity
export const getElectricity = async (id: string, params?: BaseQueryParams) => {
  return (await axiosInstance.get<ElectricityDataModel>(`/api/v1/compiler/Electricity/${id}`, params)).data;
};

export const getEPDDefinitionModel = async (epdVersionId: string, params?: BaseQueryParams) => {
  return (await axiosInstance.get<EPDDefinitionModel>(`/api/v1/compiler/EPDVersion/${epdVersionId}/EPDDefinition`, params))
    .data;
};

export const updateElectricity = async (data: ElectricityDataModel) => {
  await axiosInstance.put('/api/v1/compiler/Electricity/', data);
};

// Scenarios
export const getEpdScenarios = async (epdVersionId: string) => {
  return (await axiosInstance.get<ScenarioItemModel[]>(`/api/v1/compiler/EPDVersion/${epdVersionId}/Scenarios`)).data;
};

export const getEpdScenarioUnits = async () => {
  return (await axiosInstance.get<Option[]>(`/api/v1/compiler/EPDVersion/Scenarios/Units`)).data;
};

export const createEpdScenario = async (data: ScenarioItemModel) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/Scenarios`, data);
};

export const updateEpdScenario = async (data: ScenarioItemModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/Scenarios/`, data);
};

export const deleteEpdScenario = async (id: string) => {
  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/Scenarios/${id}`);
};

export const copyEpdScenario = async (data: IVersionedEntity) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/Scenarios/${data.id}/Copy`, data);
};

// Scenario module
export const getEpdScenarioModule = async (request: ScenarioModuleRequest) => {
  return (
    await axiosInstance.get<ScenarioModuleReadModel>(
      `/api/v1/compiler/EPDVersion/${request.epdVersionId}/Scenario/${request.scenarioId}/Module/${request.module}`
    )
  ).data;
};

export const createEpdScenarioModule = async (epdVersionId: string, data: ScenarioModuleItemModel) => {
  await axiosInstance.post(
    `/api/v1/compiler/EPDVersion/${epdVersionId}/Scenario/${data.scenarioId}/Module/${data.module}`,
    data
  );
};

export const updateEpdScenarioModule = async (epdVersionId: string, data: ScenarioModuleItemModel) => {
  await axiosInstance.put(
    `/api/v1/compiler/EPDVersion/${epdVersionId}/Scenario/${data.scenarioId}/Module/${data.module}`,
    data
  );
};

export const deleteEpdScenarioModules = async (epdVersionId: string, ids: string[]) => {
  if (!ids?.length) {
    return;
  }
  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/${epdVersionId}/Scenario/Module`, { data: ids });
};

export const createEpdDefaultScenarioModuleDescription = async (data: ScenarioModuleDefaultDescriptionModel) => {
  await axiosInstance.post(
    `/api/v1/compiler/EPDVersion/${data.epdVersionId}/Scenario/${data.scenarioId}/Module/${data.module}/ScenarioDescription`,
    data
  );
};

export const updateEpdDefaultScenarioModuleDescription = async (data: ScenarioModuleDefaultDescriptionModel) => {
  await axiosInstance.put(
    `/api/v1/compiler/EPDVersion/${data.epdVersionId}/Scenario/${data.scenarioId}/Module/${data.module}/ScenarioDescription`,
    data
  );
};

// Geo scopes lookup data
export const getGeographicalScopeLookup = async () => {
  return (await axiosInstance.get<GeographicalScopeModel[]>(`/api/v1/GeographicalScopes`)).data;
};

export const getCountriesLookup = async () => {
  return (await axiosInstance.get<CountryModel[]>(`/api/v1/Countries`)).data;
};

export const getMaterialPropertiesLookup = async () => {
  return (await axiosInstance.get<MaterialPropertyModel[]>(`/api/v1/MaterialProperties`)).data;
};

// System boundary declared modules
export const getEpdSystemBoundaryGrid = async (id: string) => {
  return (await axiosInstance.get<SystemBoundaryGridModel>(`/api/v1/compiler/EPDVersion/${id}/DeclaredModules`)).data;
};

export const getEpdSystemBoundary = async (id: string) => {
  return (await axiosInstance.get<SystemBoundaryModel>(`/api/v1/compiler/EPDVersion/${id}/SystemBoundary`)).data;
};

export const updateEpdSystemBoundaryGridModuleGeography = async (data: UpdateDeclaredModulesGeographyModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/DeclaredModuleGeography`, data);
};

export const updateEpdSystemBoundaryGridModuleDeclared = async (data: UpdateDeclaredModulesModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/DeclaredModule`, data);
};

// Transportation performance
export const getEpdTransportationPerformance = async (id: string) => {
  return (
    await axiosInstance.get<TransportationPerformanceModel>(`/api/v1/compiler/EPDVersion/${id}/LCATransportationPerformance`)
  ).data;
};

export const updateEpdTransportationPerformance = async (data: TransportationPerformanceModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/LCATransportationPerformance`, data);
};

export const createTag = async (data: any) => {
  return (await axiosInstance.post(`/api/v1/Tags`, data)).data;
};

export const getEpdPdfDocumentPreview = async (epdVersionId: string, params?: BaseQueryParams) => {
  const response = await axiosInstance.get(`/api/v1/compiler/EPDDocument/version/${epdVersionId}/Preview`, {
    responseType: 'blob',
    ...params,
  });

  const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
  const pdfUrl = URL.createObjectURL(pdfBlob);
  return pdfUrl;
};

// LCA support
export const connectDeveloper = async (data: ConnectDeveloperModel) => {
  await axiosInstance.put(`/api/v1/EPD/${data.epdId}/ConnectDeveloper/${data.membershipId}`);
};

// Third party verification
export const requestCollaboration = async (data: RequestCollaborationModel) => {
  await axiosInstance.post(
    `/api/v1/compiler/EPDVersion/${data.epdVersionId}/ThirdPartyVerification/RequestCollaboration`,
    data
  );
};

export const getCompanyVerifiers = async (companyId: string) => {
  return (
    await axiosInstance.get<EPDVerifierModel[]>(`/api/v1/Memberships/Verifiers?CompanyId=${encodeURIComponent(companyId)}`)
  ).data;
};

export const connectVerifier = async (data: ConnectVerifierModel) => {
  await axiosInstance.put(`/api/v1/EPD/${data.epdId}/ConnectVerifier`, data);
};

export const connectCertificateIssuedByBody = async (data: any) => {
  await axiosInstance.put(
    `/api/v1/compiler/EPDVersion/${data.epdVersionId}/ThirdPartyVerification/${data.epdId}/ConnectCertificateIssuedByBody/${data.bodyId}`
  );
};

// Universal dictionary
export const createDictionary = async (data: DictionaryCatalogItem) => {
  await axiosInstance.post(`/api/v1/compiler/dictionaries`, data);
};

export const updateDictionary = async (data: DictionaryCatalogItem) => {
  await axiosInstance.put(`/api/v1/compiler/dictionaries/${data.id}`, data);
};

export const deleteDictionary = async (data: DictionaryCatalogItem) => {
  await axiosInstance.delete(`/api/v1/compiler/dictionaries/${data.id}`);
};

export const createDictionaryValue = async (data: DictionaryValue) => {
  await axiosInstance.post(`/api/v1/compiler/dictionaries/values`, data);
};

export const updateDictionaryValue = async (data: DictionaryValue) => {
  await axiosInstance.put(`/api/v1/compiler/dictionaries/values/${data.id}`, data);
};

export const deleteDictionaryValue = async (id: string) => {
  await axiosInstance.delete(`/api/v1/compiler/dictionaries/values/${id}`);
};

export const cancelUpdateEpd = async (id: string) => {
  await axiosInstance.delete(`/api/v1/EPD/CancelUpdate/${id}`);
};

export const startVerification = async (id: string) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/StartVerification`);
};

export const cancelVerification = async (id: string) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/CancelVerification`);
};

export const sendEpdForPublication = async (id: string) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/SendForPublication`);
};

export const deregisterEpd = async (id: string) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/Deregister`);
};

export const cancelEpdDeregistration = async (id: string) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/CancelDeregistration`);
};

export const unlockEpd = async (id: string) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/Unlock`);
};

export const licenseeAdminVerificationApprove = async (id: string) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/LicenseeAdminVerificationApprove`);
};

export const approveVerification = async (id: string, approveModel: FormData) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/Approve`, approveModel);
};

export const cancelApproveVerification = async (id: string) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/CancelApproveVerification`);
};

export const publishEpd = async (id: string, epdVersionId: string) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/Publish?epdVersionId=${epdVersionId}`);
};

export const depublishEpd = async (id: string, depublicationReason: string | null) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/Depublish`, { depublicationReason });
};

export const sendEpdForPublicationEditorial = async (data: SendForPublicationEditorialModel) => {
  await axiosInstance.put(`/api/v1/EPD/${data.epdId}/SendForPublicationEditorial`, data);
};

export const editorialUpdating = async (id: string) => {
  await axiosInstance.put(`/api/v1/EPD/${id}/EditorialUpdating`);
};

export const createCpcrSpecificTableRow = async (data: CpcrPerformanceTableDataModel, epdVersionId: string) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${epdVersionId}/CPCRPerformance`, data);
};

export const getCpcrSpecificData = async (id: string) => {
  return (await axiosInstance.get<CpcrPerformanceInformationModel>(`/api/v1/compiler/EPDVersion/${id}/CPCRPerformance`))
    .data;
};

export const updateCpcrSpecificTableRow = async (data: any, epdVersionId: string) =>
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${epdVersionId}/CPCRPerformance`, data);

export const updateCpcrSpecificTableColumn = async (data: any) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/CPCRPerformance/Column/${data.id}`, data);
};

export const deleteCpcrSpecificDataRow = async (id: string) => {
  if (!id) {
    return;
  }

  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/CPCRPerformance/${id}`);
};

export const deleteCpcrSpecificDataRows = async (ids: string[]) => {
  if (!ids?.length) {
    return;
  }

  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/CPCRPerformance`, { data: ids });
};

// Scenario module
export const getEpdDataQualityAssessmentAndReferenceYears = async (epdVersionId: string) => {
  return (
    await axiosInstance.get<DataQualityAssessmentReadModel>(
      `/api/v1/compiler/EPDVersion/${epdVersionId}/DataQualityAssessmentAndReferenceYears`
    )
  ).data;
};

export const createEpdDataQualityAssessmentItem = async (data: DataQualityAssessmentItemModel) => {
  await axiosInstance.post(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/DataQualityAssessmentAndReferenceYears`, data);
};

export const updateEpdDataQualityAssessmentItem = async (data: DataQualityAssessmentItemModel) => {
  await axiosInstance.put(`/api/v1/compiler/EPDVersion/${data.epdVersionId}/DataQualityAssessmentAndReferenceYears`, data);
};

export const deleteEpdDataQualityAssessmentItems = async (epdVersionId: string, ids: string[]) => {
  if (!ids?.length) {
    return;
  }
  await axiosInstance.delete(`/api/v1/compiler/EPDVersion/${epdVersionId}/DataQualityAssessmentAndReferenceYears`, {
    data: ids,
  });
};

export const createEpdDataQualityAssessmentSummary = async (data: DataQualityAssessmentSummaryModel) => {
  await axiosInstance.post(
    `/api/v1/compiler/EPDVersion/${data.epdVersionId}/DataQualityAssessmentAndReferenceYears/DataQualityAssessmentSummary`,
    data
  );
};

export const updateEpdDataQualityAssessmentSummary = async (data: DataQualityAssessmentSummaryModel) => {
  await axiosInstance.put(
    `/api/v1/compiler/EPDVersion/${data.epdVersionId}/DataQualityAssessmentAndReferenceYears/DataQualityAssessmentSummary`,
    data
  );
};

//QR code
export const getQRCode = async (friendlyUrl: string, selectedQRCodeType: QRCodeTypeEnum) => {
  const response = await axiosInstance.get(
    `/api/v1/EPDLibrary/EPD/${friendlyUrl}/EPDQR?selectedQRCodeType=${selectedQRCodeType}`,
    {
      responseType: 'blob',
    }
  );
  return response.data;
};
